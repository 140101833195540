import { useCallback, useRef, useState, useEffect } from 'react';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import { Grid } from '@mui/material';

const GoogleMapComponent = ({
  mapCenter,
  markers,
  fitMapView,
  setIsMapLoaded,
  action,
  actionTypes,
  height,
  currentLocation,
  markerRef,
}: any) => {
  const mapRef = useRef<google.maps.Map>();
  const [mapOpacity, setMapOpacity] = useState<number>(1);
  const [libraries] = useState<any>(['places']);
  const isMobile = window.innerWidth < 600;
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY?.toString() || '',
    libraries,
  });

  const loadMap = useCallback((map: google.maps.Map) => {
    mapRef.current = map;
  }, []);

  useEffect(() => {
    if (mapRef?.current && window.google) {
      const map = mapRef?.current;
      if (map && markers.length > 0) {
        setMapOpacity(0);
        const bounds = new window.google.maps.LatLngBounds();
        markers.forEach((marker: any, index: number) => {
          bounds.extend(markerRef.current[index].getPosition());
        });

        map.fitBounds(bounds, 200);
        let currentZoom = map.getZoom();
        if (!currentZoom) {
          return;
        }
        if (currentZoom > 15) {
          map.setZoom(15);
        }
        setTimeout(() => {
          setMapOpacity(1);
          map.setOptions({ styles: null });
        }, 1000);
      }
    }
  }, [markers]);

  useEffect(() => {
    if (isLoaded) {
      if (setIsMapLoaded){
      setIsMapLoaded(true);
      }
      fitMapView();
      if (action === actionTypes.CURRENT_LOCATION) {
        setMapOpacity(0);
        currentLocation();
      }
    }
  }, [isLoaded]);

  return isLoaded && window.google ? (
    isMobile ? (
      <GoogleMap
      mapContainerStyle={{
        height: height ? height : '100%',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 0,
        overflow: 'hidden',
      }}
      id={'google-map-location'}
      zoom={7}
      center={mapCenter}
      options={{
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
      }}
      onLoad={loadMap}
    >
      {markers}
      <div
        className="location-icon-panel"
      >
        <span className="icon"></span>
      </div>
    </GoogleMap>
    ) : (
      <Grid
      sx={{
        height: '801px',
        width: '100%',
        position: 'absolute',
        top: mapOpacity === 0 ? 79 : 0,
        left: 0,
        zIndex: 0,
        backgroundColor: '#E5E3DF',
      }}
    >
      <GoogleMap
        mapContainerStyle={{
          height: height ? height : '100%',
          width: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 0,
          overflow: 'hidden',
        }}
        id={'google-map-location'}
        zoom={7}
        center={mapCenter}
        options={{
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
        }}
        onLoad={loadMap}
      >
        {markers}
        <div className="location-icon-panel">
          <span className="icon"></span>
        </div>
      </GoogleMap>
    </Grid>
      )
  ) : (
    <>
    </>
  );
};

export default GoogleMapComponent;