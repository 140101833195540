import { restaurantActionsTypes } from '../../types/restaurant';
import { ResponseRestaurant } from '../../../types/olo-api';
import { displayToast } from '../../../helpers/toast';

export function getResturantInfoRequest(id: number) {
  return {
    type: restaurantActionsTypes.GET_RESTAURANT_INFO_REQUEST,
    restaurantID: id,
  };
}

export function resetRestaurantRequest() {
  return {
    type: restaurantActionsTypes.RESTAURANT_RESET_REQUEST,
  };
}

export function updateRestaurantSessionRequest(sessionTime: any) {
  return {
    type: restaurantActionsTypes.SET_RESTAURANT_SESSION_REQUEST,
    payload: sessionTime
  };
}

export function updateMenu(isMenu: any) {
  return {
    type: restaurantActionsTypes.SET_MENU_MOBILE,
    payload: isMenu,
  };
}

export function saveDeliveryAddress(saveDeliveryAddress: any) {
  return {
    type: restaurantActionsTypes.SAVE_DELIVERY_ADDRESS,
    payload: saveDeliveryAddress,
  };
}

export function getResturantInfoRequestSuccess(data: ResponseRestaurant) {
  return {
    type: restaurantActionsTypes.GET_RESTAURANT_INFO_SUCCESS,
    payload: data,
  };
}
export function getResturantInfoRequestFailure(error: any) {
  displayToast(
    'ERROR',
    error?.response?.data?.message
      ? error.response.data.message
      : 'ERROR! Please Try again later',
  );
  return {
    type: restaurantActionsTypes.GET_RESTAURANT_INFO_FAILURE,
    error: error,
  };
}

export function setResturantInfoRequest(
  restaurant: ResponseRestaurant | null,
  orderType: string,
) {
  return {
    type: restaurantActionsTypes.SET_RESTAURANT_INFO_REQUEST,
    restaurant,
    orderType,
  };
}

export function setResturantInfoRequestSuccess(
  data: ResponseRestaurant,
  orderType: string,
) {
  return {
    type: restaurantActionsTypes.SET_RESTAURANT_INFO_SUCCESS,
    payload: data,
    orderType,
  };
}

export function setResturantInfoRequestFailure(error: any) {
  displayToast(
    'ERROR',
    error?.response?.data?.message
      ? error.response.data.message
      : 'ERROR! Please Try again later',
  );
  return {
    type: restaurantActionsTypes.SET_RESTAURANT_INFO_FAILURE,
    error: error,
  };
}

export function setRestaurantInfoOrderType(orderType: string) {
  return {
    type: restaurantActionsTypes.SET_RESTAURANT_INFO_ORDER_TYPE,
    orderType,
  };
}


export function saveLinkAddress(saveLinkAddress: any) {

  return {
    type: restaurantActionsTypes.SAVE_LINK_ADDRESS,
    payload: saveLinkAddress,
  }
}

export function saveHandOffMode(HandOffMode: any) {

  return {
    type: restaurantActionsTypes.SAVE_HANDOFF_MODE,
    payload: HandOffMode,
  }
}

export function saveFavouriteLocation(favoriteLocation: any) {

  return {
    type: restaurantActionsTypes.FAVORITE_LOCATION,
    payload: favoriteLocation,
  }
}

export function getSelectedRestaurantForMobile(
  data: any,
) {
  return {
    type: restaurantActionsTypes.GET_SELECTED_RESTAURANT_FOR_MOBILE,
    payload: data,
  };
}