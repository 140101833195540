import {
  RequestBasketSubmit,
  ResponseRestaurantCalendars,
  RequestUpdateBasketTimeWanted,
  RequestDeliveryAddress,
} from '../types/olo-api';
import {
  BillingMethodEnum,
  UserTypeEnum,
  SaveOnFileEnum,
  CountryEnum,
  DeliveryModeEnum,
} from '../types/olo-api/olo-api.enums';
import { CalendarTypeEnum, HoursListing } from './hoursListing';
import moment from 'moment';
import { generateCCSFToken } from '../services/basket';
import { isLoginUser } from './auth';
import { requestDelUserDelAddress } from '../services/user';
import { store } from '../redux/store';
import {
  updateBasketTimeWanted,
  updateDuplicateAddress,
} from '../redux/actions/basket/checkout';

const cardTypes: any = {
  amex: 'Amex',
  visa: 'Visa',
  discover: 'Discover',
  mastercard: 'Mastercard',
};

export function removePreviousAddresses(
  basketAddresses: any,
  deliveryAddress: any,
  basket: any,
  removeAll: any,
) {
  if (basketAddresses?.duplicated?.length > 0 && isLoginUser()) {
    let filterSavedAddress: number[] = [];

    if (removeAll === false) {
      filterSavedAddress = basketAddresses?.duplicated?.filter(
        (addressId: any) =>
          basket?.deliveryaddress?.id !== addressId &&
          deliveryAddress?.id !== addressId,
      );
    } else {
      filterSavedAddress = basketAddresses?.duplicated;
    }
    if (filterSavedAddress?.length > 0 && filterSavedAddress[0] !== null) {
      filterSavedAddress.forEach((id: any) => {
        requestDelUserDelAddress(id);
      });
      const remainingAddress = basketAddresses?.duplicated.filter(
        (addressId: any) => !filterSavedAddress.includes(addressId),
      );
      store.dispatch(updateDuplicateAddress(remainingAddress));
    }
  }
}
export function generateSubmitBasketPayload(
  formData: any,
  billingSchemes: any,
  deliverymode: string,
  authtoken: string,
  basket: any,
  basketAccessToken: any,
): RequestBasketSubmit {
  const billingSchemeStats = getBillingSchemesStats(billingSchemes);
  let paymentPayload: any = {};
  if (
    billingSchemeStats.selectedCreditCard === 1 ||
    billingSchemeStats.selectedGiftCard > 0
  ) {
    let billingaccounts: any = [];
    let tip = (basket && basket.tip) || 0;
    billingSchemes.forEach((account: any) => {
      if (account.selected) {
        let obj = {
          ...account,
        };
        if (account.billingaccountid) {
          obj.billingmethod = 'billingaccount';
        }

        if (tip > 0) {
          if (obj.amount >= tip) {
            obj.tipportion = tip;
            tip = 0;
          } else {
            obj.tipportion = obj.amount;
            tip = +(tip - obj.amount).toFixed(2);
          }
        }
        delete obj.selected;
        delete obj.localId;
        delete obj.balance;
        delete obj.alwaysVisible;
        billingaccounts.push(obj);
      }
    });
    paymentPayload = {
      billingaccounts: billingaccounts,
    };
  }

  let payload: RequestBasketSubmit = {
    id: basket.id,
    accessToken: basketAccessToken,
    usertype: UserTypeEnum.guest,
    guestoptin: formData.emailNotification,
    ...paymentPayload,
  };

  if (
    deliverymode === DeliveryModeEnum.curbside ||
    deliverymode === DeliveryModeEnum.pickup ||
    deliverymode === DeliveryModeEnum.dinein ||
    deliverymode === DeliveryModeEnum.dispatch
  ) {
    payload = {
      ...payload,
      firstname: formData.firstName,
      lastname: formData.lastName,
      emailaddress: formData.email,
      contactnumber: formData.phone,
      receivinguser: {
        firstname: formData.firstName,
        lastname: formData.lastName,
        emailaddress: formData.email,
        contactnumber: formData.phone,
      },
    };
  }

  if (isLoginUser()) {
    payload.usertype = UserTypeEnum.user;
    delete payload.guestoptin;
  }

  return payload;
}

export function formatCustomFields(customFields: any, formData: any) {
  let formatArray: any = [];

  customFields.forEach((field: any) => {
    let obj = {};

    if (field.label === 'Table Number' && formData.tableNumber !== '') {
      obj = {
        id: field.id,
        value: formData.tableNumber,
      };
    } else if (field.label === 'Model' && formData.vehicleModal !== '') {
      obj = {
        id: field.id,
        value: formData.vehicleModal,
      };
    } else if (field.label === 'Make' && formData.vehicleMake !== '') {
      obj = {
        id: field.id,
        value: formData.vehicleMake,
      };
    } else if (field.label === 'Color' && formData.vehicleColor !== '') {
      obj = {
        id: field.id,
        value: formData.vehicleColor,
      };
    }
    if (Object.keys(obj).length) {
      formatArray.push(obj);
    }
  });

  return formatArray;
}

const isTimeSame = (fTime: string, sTime: string): boolean => {
  return fTime.split(' ')[1] === sTime.split(' ')[1];
};

export function GetRestaurantHoursRange(
  hours: ResponseRestaurantCalendars,
  type: CalendarTypeEnum,
): HoursListing[] {
  const selectedStoreHours = hours?.calendar?.find((x) => x.type === type);
  let newHoursArray: HoursListing[] = [];
  if (selectedStoreHours) {
    selectedStoreHours &&
      selectedStoreHours.ranges.forEach((item, index) => {
        newHoursArray.push({
          label: item.weekday.substring(0, 1),
          start: item.start,
          end: item.end,
          isOpenAllDay: isTimeSame(item.start, item.end),
        });
      });
  }
  return newHoursArray;
}

const calculateMinutesDiff = (minutes: number): number => {
  if (minutes % 15 === 0) {
    return 0;
  } else {
    let difference = Math.trunc(minutes / 15);
    difference = (difference + 1) * 15 - minutes;
    return difference;
  }
};

export const addToDate = (date: any, count: any, unit: any) =>
  moment(date || currentDateTime).add(count, unit);

export const getDateDiff = (date1: any, date2: any, unit: any) =>
  moment(date1).diff(date2, unit);

const currentDateTime = new Date();

export const formatDateTime = (
  date: any,
  outFormat: any,
  inputFormat?: any,
) => {
  if (inputFormat) {
    return moment(date ?? currentDateTime, inputFormat).format(outFormat);
  }
  return moment(date ?? currentDateTime).format(outFormat);
};

export function getNext7Days(startDay: any) {
  const daysArray = [];
  for (let i = 0; i < 7; i++) {
    const nextDate = addToDate(startDay, i, 'days');
    let dayName = formatDateTime(nextDate, 'dddd');
    if (nextDate.isSame(currentDateTime, 'day')) {
      dayName = 'Today';
    } else if (nextDate.isSame(addToDate(currentDateTime, 1, 'day'), 'day')) {
      dayName = 'Tomorrow';
    }
    const dateNumber = formatDateTime(nextDate, 'DD');
    const monthName = formatDateTime(nextDate, 'MMMM');
    const dayObject = {
      day: dayName,
      date: dateNumber,
      month: monthName,
      dateTime: nextDate,
    };

    daysArray.push(dayObject);
  }

  return daysArray;
}

export const makeDaysAndInitialSelection = (basket: any) => {
  const { timewanted, earliestreadytime } = basket || {};
  const earliestStartDate = moment(earliestreadytime, 'YYYYMMDD HH:mm').utc(
    true,
  );
  const daysFromEarliestDate = getNext7Days(earliestStartDate);
  let dayIndex = 0;
  if (timewanted) {
    dayIndex = daysFromEarliestDate?.findIndex(
      (item) =>
        formatDateTime(item?.dateTime, 'DD') ===
        formatDateTime(timewanted, 'DD', 'YYYYMMDD HH:mm'),
    );
  }
  const selectedDayItem = daysFromEarliestDate[dayIndex];
  return {
    selectedDayItem,
    dayIndex,
  };
};

export const strToDate = (date: any, format: any) => {
  if (format) {
    return moment(date, format);
  }
  return moment(date);
};

export const isBetweenTime = (
  dateTime: any,
  start: any,
  end: any,
  granularity?: any,
  inclusivity?: any,
) => moment(dateTime).isBetween(start, end, granularity, inclusivity);

export const isAsapAvailable = (restaurantHours: any) => {
  if (!restaurantHours?.length) {
    return false;
  }
  const YYYYMMDD_HH_mm = 'YYYYMMDD HH:mm';
  const { start, end } = restaurantHours[0];

  let openAt = strToDate(start, YYYYMMDD_HH_mm);
  let closeAt = strToDate(end, YYYYMMDD_HH_mm);
  let currentDate = strToDate(currentDateTime, YYYYMMDD_HH_mm);
  return isBetweenTime(currentDate, openAt, closeAt);
};

export const finalizedTimeSlots = ({
  restaurantHours = [],
  orderType = '',
  selectedDayIndex = 0,
  basket = {},
  selectedDate = '',
}: any) => {
  let slots,
    dayIndex = selectedDayIndex,
    selectedTime;
  const { start, end } = restaurantHours?.[0] || {};
  //@ts-ignore
  const { leadtimeestimateminutes, timewanted, earliestreadytime, timemode } =
    basket || {};
  const YYYYMMDD_HH_mm = 'YYYYMMDD HH:mm';

  //add 15 minutes to start of restaurant hour.
  let earliestTimeSlot = moment(start, YYYYMMDD_HH_mm).format(YYYYMMDD_HH_mm);

  //For first day start slots with the earliest ready time.
  const isEarliestDay = dayIndex === 0;
  // if (isEarliestDay) {
  //   earliestTimeSlot = earliestreadytime;
  // }
  //earliest time has passed and now it is creating issue
  slots = generateNextAvailableTimeSlots(
    earliestTimeSlot,
    end,
    leadtimeestimateminutes,
    orderType,
    timewanted,
    selectedDate,
  );
  if (slots?.length > 0 && !timewanted && isEarliestDay) {
    if (timemode === 'asap' && isAsapAvailable(restaurantHours)) {
      // slots?.unshift('ASAP');
    } else {
      dayIndex = 0;
      selectedTime = slots?.[0];
      const payload = createTimeWantedPayload(selectedTime);
      basket && store.dispatch(updateBasketTimeWanted(basket?.id, payload));
    }
  }
  return {
    slots,
    dayIndex,
    selectedTime,
  };
};

export function generateNextAvailableTimeSlots(
  openingTime: string,
  closingTime: string,
  leadTime: number,
  orderType: string,
  timeWanted: string,
  selectedDate: string,
) {
  let timeSlots = [];
  let currentTime = moment();

  let startTime;
  let openAt = moment(openingTime, 'YYYYMMDD HH:mm');
  let closeAt = moment(closingTime, 'YYYYMMDD HH:mm');

  //Restaurant is closed
  if (currentTime.isAfter(closeAt)) {
    return [];
  } else if (currentTime.isBetween(openAt, closeAt)) {
    //restaurant is open
    startTime = currentTime;
  } else {
    //restaurant is still to open
    startTime = openAt;
  }
  //add lead time if minutes are less than 24Hrs i.e, 1440 otherwise start day will be next
  if (leadTime < 24 * 60) {
    startTime.add(leadTime, 'minutes');
  }

  let minutes = startTime.minutes();
  minutes = calculateMinutesDiff(minutes);
  startTime = startTime.add(minutes, 'minutes');

  let count = 0;
  const maxAllowed = 100;
  // Slots generation between open and close & time
  while (closeAt.diff(startTime, 'seconds') > 0 && count <= maxAllowed) {
    timeSlots.push(moment(startTime).format('YYYYMMDD HH:mm'));
    startTime && startTime.add(15, 'm');
    count++;
  }
  // In case if leadtime changed by the OLO, adding that selected time at the slots start
  if (timeWanted && selectedDate && orderType === 'dispatch') {
    const sameDay = moment(timeWanted, 'YYYYMMDD HH:mm').isSame(
      selectedDate,
      'day',
    );
    if (sameDay && !timeSlots.includes(timeWanted)) {
      timeSlots.unshift(timeWanted);
    }
  }
  return timeSlots;
}

export function createTimeWantedPayload(time: string) {
  const date = moment(time, 'YYYYMMDD HH:mm');
  const payload: RequestUpdateBasketTimeWanted = {
    ismanualfire: false,
    year: date.year(),
    month: date.month() + 1,
    day: date.date(),
    hour: date.hour(),
    minute: date.minute(),
  };
  return payload;
}

export function getUniqueId() {
  return Date.now().toString(36) + Math.random().toString(36).substr(2);
}

export function getBillingSchemesStats(billingSchemes: any) {
  let billingSchemeStats: any = {
    creditCard: 0,
    giftCard: 0,
    selectedCreditCard: 0,
    selectedGiftCard: 0,
    savedCards: 0,
  };

  billingSchemes.forEach((account: any) => {
    billingSchemeStats = {
      creditCard:
        account.billingmethod === 'creditcard'
          ? billingSchemeStats.creditCard + 1
          : billingSchemeStats.creditCard,
      giftCard:
        account.billingmethod === 'storedvalue'
          ? billingSchemeStats.giftCard + 1
          : billingSchemeStats.giftCard,
      selectedCreditCard:
        account.billingmethod === 'creditcard' && account.selected
          ? billingSchemeStats.selectedCreditCard + 1
          : billingSchemeStats.selectedCreditCard,
      selectedGiftCard:
        account.billingmethod === 'storedvalue' && account.selected
          ? billingSchemeStats.selectedGiftCard + 1
          : billingSchemeStats.selectedGiftCard,
      savedCards:
        account.billingmethod === 'creditcard' && account.savedCard
          ? billingSchemeStats.savedCards + 1
          : billingSchemeStats.savedCards,
    };
  });

  return billingSchemeStats;
}

export function getCreditCardObj(cardDetails: any, billingSchemes: any) {
  const billingSchemeStats = getBillingSchemesStats(billingSchemes);
  let selected = billingSchemeStats.selectedCreditCard < 1;

  let cardObj: any = [
    {
      localId: getUniqueId(),
      selected: true,
      billingmethod: 'creditcard',
      amount: 0,
      tipportion: 0.0,
      expiryyear: cardDetails.exp_year,
      expirymonth: cardDetails.exp_month,
      zip: cardDetails.postal_code,
      saveonfile: cardDetails.savecard,
      alwaysVisible: false,
    },
  ];

  return cardObj;
}

export function getGiftCardObj(
  balanceResponse: any,
  billingSchemeId: any,
  body: any,
  billingSchemes: any,
) {
  const billingSchemeStats = getBillingSchemesStats(billingSchemes);

  let selected = billingSchemeStats.selectedGiftCard < 4;

  let cardObj: any = [
    {
      localId: getUniqueId(),
      selected: selected,
      billingmethod: 'storedvalue',
      balance: balanceResponse.balance,
      amount: 0,
      tipportion: 0.0,
      saveOnFile: true,
      billingschemeid: billingSchemeId,
      billingfields: [
        {
          name: 'number',
          value: body.cardnumber,
        },
      ],
      alwaysVisible: false,
    },
  ];

  if (body.pin && body.pin !== '') {
    cardObj[0].billingfields.push({
      name: 'pin',
      value: body.pin,
    });
  }

  return cardObj;
}

export function updatePaymentCardsAmount(billingSchemes: any, basket: any) {
  const billingSchemeStats = getBillingSchemesStats(billingSchemes);
  let total = basket && basket.total ? basket.total : 0;

  billingSchemes.sort((a: any, b: any) =>
    b.billingmethod > a.billingmethod
      ? 1
      : a.billingmethod > b.billingmethod
      ? -1
      : 0,
  );

  billingSchemes.sort((a: any, b: any) =>
    a.balance > b.balance ? 1 : b.balance > a.balance ? -1 : 0,
  );

  billingSchemes = billingSchemes.map((account: any) => {
    if (account.selected) {
      if (account.billingmethod === 'storedvalue') {
        let giftCardAmount: any =
          account.balance >= total ? total : account.balance;
        total = (total - giftCardAmount).toFixed(2);
        account.amount = parseFloat(giftCardAmount);
      } else if (account.billingmethod === 'creditcard') {
        account.amount = parseFloat(total);
      }
    } else {
      account.amount = 0;
    }
    return account;
  });
  return billingSchemes;
}

export function remainingAmount(basket: any, billingSchemes: any) {
  if (basket && billingSchemes) {
    let amountSelected = billingSchemes.reduce((sum: any, account: any) => {
      if (account.selected) {
        sum = sum + account.amount;
      }
      return sum;
    }, 0);

    amountSelected = amountSelected.toFixed(2);
    amountSelected = parseFloat(amountSelected);

    let remainingAmount = (basket?.total - amountSelected).toFixed(2);

    if (remainingAmount !== 'NAN') {
      return remainingAmount;
    } else {
      return 0;
    }
  } else {
    return 0;
  }
}
