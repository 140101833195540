import TagManager from 'react-gtm-module';

const fireUpsellEvent = (productDetails: any) => {
  const tagManagerArgs: any = {
    dataLayer: {
      event: 'eec.add',
      ecommerce: {
        add: {
          actionField: {
            list: 'PRODUCT PAGE',
          },
        },
        products: productDetails,
      },
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};

export default fireUpsellEvent;