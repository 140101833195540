import React from 'react';
import { toast } from 'react-toastify';
import icon from '../assets/imgs/success-toast-tick.svg';
import phoneIcon from '../assets/imgs/phone-icon.svg';
import infoIcon from '../assets/imgs/infoIcon.svg';


interface settingsConfig {
  position?: string;
  autoClose?: number;
  hideProgressBar?: boolean;
  closeOnClick?: boolean;
  pauseOnHover?: boolean;
  draggable?: boolean;
  progress?: number | string;
  theme?: string;
}

enum toastType {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  INFO = 'INFO',
  PHONEINFO = 'PHONEINFO',
}

type toast = keyof typeof toastType;

const defaultSettings: any = {
  position: 'bottom-left',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'light',
};
const successToastSettings = {
  position: 'bottom-left',
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  icon: React.createElement('img', { src: icon, alt: 'Icon' }),
  theme: 'dark',
  closeButton: true,
};
const infoToastSettings = {
  position: 'bottom-left',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  icon: React.createElement('img', { src: infoIcon, alt: 'Icon' }),
  theme: 'dark',
  closeButton: false,
};
export const displayToast = (
  type: toast,
  message: string,
  settings?: settingsConfig,
) => {
  const toastSettings: any = {
    ...defaultSettings,
    ...settings,
  };
  const toastSuccessSettings: any = {
    ...successToastSettings,
    ...settings,
  };
  const infoPhoneToastSettings: any = {
    ...infoToastSettings,
    ...settings,
  };
  switch (type) {
    case toastType.SUCCESS:
      toast.success(message, toastSuccessSettings);
      break;
    case toastType.ERROR:
      toast.error(message, toastSettings);
      break;
    case toastType.INFO:
      toast.info(message, toastSettings);
      break;
    case toastType.PHONEINFO:
      toast.info(message, infoPhoneToastSettings);
      break;
    default:
      break;
  }
};
