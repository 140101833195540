export enum deliveryAddressTypes {
  SET_DELIVERY_ADDRESS = 'SET_DELIVERY_ADDRESS',
  SET_DELIVERY_ADDRESS_SUCCESS = 'SET_DELIVERY_ADDRESS_SUCCESS',
  SET_DELIVERY_ADDRESS_FAILURE = 'SET_DELIVERY_ADDRESS_FAILURE',
  
  VERIFY_DELIVERY_ADDRESS_REQUEST = 'VERIFY_DELIVERY_ADDRESS_REQUEST',
  VERIFY_DELIVERY_ADDRESS_REQUEST_SUCCESS = 'VERIFY_DELIVERY_ADDRESS_REQUEST_SUCCESS',
  VERIFY_DELIVERY_ADDRESS_REQUEST_FAILURE = 'VERIFY_DELIVERY_ADDRESS_REQUEST_FAILURE',
}


