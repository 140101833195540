import { store } from '../../redux/store';
import axiosInstance from '../axiosInceptor';

export const requestCreateFave = (body: RequestNewFave) => {
  try {
    const url = process.env.REACT_APP_OLO_API || '';
    const authtoken = store.getState().authReducer.authToken.authtoken;
    return axiosInstance
      .post(url + `/users/${authtoken}/faves`, body)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};
