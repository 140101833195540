import axios from 'axios';
import { store } from '../../redux/store';
import { generateDeviceId } from '../../helpers/common';
import _ from 'lodash';
const axiosInstance = axios.create();
let count = 0;

const withoutTokenEndpoints = [
  'api/auth/customers/sign_in',
  'api/auth/customers.json',
  'api/auth/users/forgot_password',
  'api/auth/users/change_password',
  'api/auth/users/connect_with_facebook',
  'api2/dashboard/eclub_guests',
  'api2/dashboard/locations',
  'api2/dashboard/eclub_guests'
];
axiosInstance.interceptors.request.use(
  (config: any) => {
    try {

      const url = config?.url || '';

      let isPunchhApi = url?.toString().includes('punchh_api');
      if (isPunchhApi && config.headers) {
        let endpoint: any = url?.toString().split('punchh_api/');
        endpoint = endpoint[1];
        const filteredEndPoints = withoutTokenEndpoints.filter((item) => endpoint.startsWith(item))
        if (_.isEmpty(filteredEndPoints)) {
          config.headers['Authorization'] = `Bearer ${store.getState().providerReducer.providerToken.access_token
            }`;
        }
        const deviceId: string = store.getState().authReducer.deviceId
          ? store.getState().authReducer.deviceId
          : generateDeviceId();
        if (deviceId) {
          config.headers['punchh-app-device-id'] = deviceId;
        }
      }
      // config.headers['User-Agent'] = 'Rubios-web/1.0';
      return config;
    } catch (e: any) {
      throw e;
    }
  },
  function (error: any) {
    return Promise.reject(error);
  },
);

export default axiosInstance;
