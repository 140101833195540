export enum authActionsTypes {
  GET_AUTHTOKEN_REQUEST = 'GET_AUTHTOKEN_REQUEST',
  GET_AUTHTOKEN_SUCCESS = 'GET_AUTHTOKEN_SUCCESS',
  GET_AUTHTOKEN_FAILURE = 'GET_AUTHTOKEN_FAILURE',

  REMOVE_AUTH_TOKEN_IFRAME_REDIRECT = 'REMOVE_AUTH_TOKEN_IFRAME_REDIRECT',
  ADD_AUTH_TOKEN_IFRAME_REDIRECT = 'ADD_AUTH_TOKEN_IFRAME_REDIRECT',

  UPDATE_DEVICE_UNIQUE_ID = 'UPDATE_DEVICE_UNIQUE_ID',
}
