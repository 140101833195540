// StateContext.js
import React, { createContext, useContext, useReducer } from 'react';

const StateContext = createContext();

const initialState = {
  sharedState: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_SHARED_STATE':
      return { ...state, sharedState: action.payload };
    case 'UPDATE_SHARED_STATE_FALSE':
      return { ...state, sharedState: action.payload };
    default:
      return state;
  }
};

export const StateProvider = ({ children }) => {
  const [state, updateSharedState] = useReducer(reducer, initialState);

  return (
    <StateContext.Provider value={{ state, updateSharedState }}>
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => {
  const context = useContext(StateContext);

  if (!context) {
    throw new Error('useStateContext must be used within a StateProvider');
  }

  return context;
};
