export enum accountHistoryTypes {
  GET_ACCOUNT_HISTORY = 'GET_ACCOUNT_HISTORY',
  GET_ACCOUNT_HISTORY_SUCCESS = 'GET_ACCOUNT_HISTORY_SUCCESS',
  GET_ACCOUNT_HISTORY_FAILURE = 'GET_ACCOUNT_HISTORY_FAILURE',

  GET_ACCOUNT_HISTORY_MOBILE = 'GET_ACCOUNT_MOBILE_HISTORY',
  GET_ACCOUNT_HISTORY_MOBILE_SUCCESS = 'GET_ACCOUNT_HISTORY_MOBILE_SUCCESS',
  GET_ACCOUNT_HISTORY_MOBILE_FAILURE = 'GET_ACCOUNT_HISTORY_MOBILE_FAILURE',
  GET_POINT_HISTORY_MOBILE_SUCCESS = 'GET_POINT_HISTORY_MOBILE_SUCCESS'
}
