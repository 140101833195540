import {
  Box,
  List,
  ListItem,
  ListItemText,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { userLogout } from '../../redux/actions/user';
import AccountLinks from '../account-links';
import './right-menu-bar.css';

const useStyles = makeStyles((theme: Theme) => ({
  dimPanel: {
    position: 'fixed',
    background: 'rgba(0, 0, 0, 0.5)',
    right: 0,
    width: '100%',
    height: '100vh',
    zIndex: 1101,
    [theme.breakpoints.down('xl')]: {
      display: 'block !important',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    textTransform: 'uppercase',
    padding: '5px 0px',
    '& span': {
      fontSize: '13px !important',
      fontFamily: "'GritSans-Bold' !important",
    },
  },
}));

const RightMenuBar = (props: any) => {
  const { closeDrawer, removeCart } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const logout = () => {
    dispatch(userLogout());
    closeDrawer(false);
    navigate('/login');
  };
  return (
    <>
      <div
        className={classes.dimPanel}
        onClick={() => closeDrawer(false)}
      ></div>
      <Box
        className="list-sec"
        sx={{
          boxShadow: 2,
          bgcolor: 'background.paper',
          height: '100%',
          position: 'fixed',
          overflow: 'auto',
          paddingBottom: '80px',
          width: {
            xs: '100%',
            sm: removeCart ? '150px' : '527px',
            md: removeCart ? '527px' : '527px',
          },
          zIndex: '1102',
          boxSizing: 'border-box',

        }}
      >
        <AccountLinks
          right={true}
          closeDrawer={(value: boolean) => {
            closeDrawer(value);
          }}
        />
      </Box>
    </>
  );
};

export default RightMenuBar;
