export const UPSELLS_TYPES = {
  SALSA: 'SALSA',
  DESSERT: 'DESSERT',
  SIDE: 'SIDE',
  DRINK: 'DRINK',
};

export const getUpsellsProducts = (upsells: any[]) => {
  const sides = upsells?.filter((item: { type: string; }) => item.type === 'SIDE')[0];
  const drinks = upsells?.filter((item: { type: string; }) => item.type === 'DRINK')[0];
  const desserts = upsells?.filter((item: { type: string; }) => item.type === 'DESSERT')[0];

  return {
    upsellsSides: sides?.products || [],
    upsellsDrinks: drinks?.products || [],
    upsellsDesserts: desserts?.products || [],
  };
};

export const getUpsellsStatsFromOrder = (productsArr: any[], upsells: any) => {
  let includeSides = false;
  let includeDrinks = false;
  let includesDesserts = false;

  const { upsellsSides, upsellsDesserts, upsellsDrinks } = getUpsellsProducts(upsells);
  productsArr?.forEach((item: { productId: any; }) => {
    upsellsSides?.forEach((side: { id: any; }) => {
      if (item.productId === side.id) {
        includeSides = true;
      }
    });
    upsellsDesserts?.forEach((dessert: { id: any; }) => {
      if (item.productId === dessert.id) {
        includesDesserts = true;
      }
    });
    upsellsDrinks?.forEach((drink: { id: any; }) => {
      if (item.productId === drink.id) {
        includeDrinks = true;
      }
    });
  });
  return {
    sides: includeSides,
    drinks: includeDrinks,
    desserts: includesDesserts,
  };
};

