import AppRoutes from './routes';

function App(props: any) {
  return (
    <div id="wapper">
      <main>
        <AppRoutes />
      </main>
    </div>
  );
}
export default App;
