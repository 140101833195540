import axios from 'axios';
import { store } from '../../redux/store';
import {
  RequestCreateBasket,
  RequestNewBasketProduct,
  RequestBasketProductBatch,
  RequestBasketAddUpsell,
  RequestSetDeliveryMode,
  RequestDeliveryAddress,
} from '../../types/olo-api';
import axiosInstance from '../axiosInceptor';

export const getBasket = (basketid: string) => {
  try {
    const url = process.env.REACT_APP_OLO_API || '';
    return axiosInstance
      .get(url + `/baskets/${basketid}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};

export const getDummyBasket = (body: RequestCreateBasket) => {
  try {
    const url = process.env.REACT_APP_OLO_API || '';
    return axiosInstance
      .post(url + `/baskets/create`, body)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};

export const setBasketDeliveryMode = (
  basketid: string,
  body: RequestSetDeliveryMode,
) => {
  try {
    const url = process.env.REACT_APP_OLO_API || '';
    return axiosInstance
      .put(url + `/baskets/${basketid}/deliverymode`, body)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};

export const setBasketDeliveryAddress = async (
  basketid: string,
  body: RequestDeliveryAddress,
) => {
  try {
    const url = process.env.REACT_APP_OLO_API || '';
    return await axiosInstance
      .put(url + `/baskets/${basketid}/dispatchaddress`, body)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};

export const setBasketCustomFields = (basketid: string, customFields: any) => {
  try {
    const url = process.env.REACT_APP_OLO_API || '';
    const requests: any = [];
    customFields.forEach((element: any) => {
      requests.push(
        axiosInstance.put(url + `/baskets/${basketid}/customfields`, element),
      );
    });
    return axios.all(requests).catch((error) => {
      throw error;
    });
  } catch (error) {
    throw error;
  }
};

export const addSingleProduct = (
  basketid: string,
  body: RequestNewBasketProduct,
) => {
  try {
    const url = process.env.REACT_APP_OLO_API || '';
    return axiosInstance
      .post(url + `/baskets/${basketid}/products`, body)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};

export const addMultipleProducts = (
  basketid: string,
  body: RequestBasketProductBatch,
) => {
  try {
    const url = process.env.REACT_APP_OLO_API || '';
    return axiosInstance
      .post(url + `/baskets/${basketid}/products/batch`, body)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};

export const updateMultipleProducts = (
  basketid: string,
  body: RequestBasketProductBatch,
) => {
  try {
    const url = process.env.REACT_APP_OLO_API || '';
    return axiosInstance
      .put(url + `/baskets/${basketid}/products/batch`, body)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};

export const removeProduct = (basketid: string, basketProductId: number) => {
  try {
    const url = process.env.REACT_APP_OLO_API || '';
    return axiosInstance
      .delete(url + `/baskets/${basketid}/products/${basketProductId}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};

export const updateProduct = (
  basketid: string,
  basketProductId: number,
  body: RequestNewBasketProduct,
) => {
  try {
    const url = process.env.REACT_APP_OLO_API || '';
    return axiosInstance
      .put(url + `/baskets/${basketid}/products/${basketProductId}`, body)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};

export const requestCreateBasket = (body: RequestCreateBasketFromOrder) => {
  try {
    const url = process.env.REACT_APP_OLO_API || '';
    return axiosInstance
      .post(url + `/baskets/createfromorder`, body)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};

export const requestCreateBasketForFav = (
  body: RequestCreateBasketFromFave,
) => {
  try {
    const auth_token = store.getState().authReducer.authToken.authtoken;
    const url = process.env.REACT_APP_OLO_API || '';
    return axiosInstance
      .post(url + `/baskets/createfromfave?authtoken=` + auth_token, body)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};

export const getUpSells = (vendorid: string) => {
  try {
    const url = process.env.REACT_APP_OLO_API || '';
    return axiosInstance
      .get(url + `/upsells/${vendorid}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};

export const addUpSells = (basketid: string, body: RequestBasketAddUpsell) => {
  try {
    const url = process.env.REACT_APP_OLO_API || '';
    return axiosInstance
      .post(url + `/baskets/${basketid}/upsell`, body)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};

export const createGuestUser = () => {
  try {
    const url = process.env.REACT_APP_OLO_API || '';
    const payload = {
      password: 'asdf1234',
      optin: true,
    };
    return axiosInstance
      .post(
        url + `/orders/a98726df-1cd1-ec11-a9c9-aad2a287f600/createuser`,
        payload,
      )
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};

export const generateCCSFToken = (basketid: string, body: any) => {
  try {
    const url = process.env.REACT_APP_OLO_API || '';
    return axiosInstance
      .post(url + `/baskets/${basketid}/checkout`, body)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};

export const basketTransfer = (basketId: string, vendorId: any) => {
  try {
    const url = process.env.REACT_APP_OLO_API || '';
    const body = {
      vendorid: vendorId,
    };
    return axiosInstance
      .post(url + `/baskets/${basketId}/transfer`, body)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};

export const addRefferalBasket = (basketId: string, token: string) => {
  try {
    const url = process.env.REACT_APP_OLO_API || '';
    const body = {
      referrals: [
        {
          source: 'rwg_token',
          token,
        },
      ],
    };
    return axiosInstance
      .put(url + `/baskets/${basketId}/referrals`, body)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};

export const getCharityRequest = (basketid: number, body: any): any => {
  try {
    const url = process.env.REACT_APP_OLO_API || '';
    return axiosInstance
      .put(url + `/baskets/${basketid}/donations`, body)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};
