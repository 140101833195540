
import { ResponseModifiers } from '../../../../types/olo-api';
import { displayToast } from '../../../../helpers/toast';
import { NewOptionActionTypes } from '../../../types/product/new-option';

export function itemSelected(id: number, quantity: number) {
  return {
    type: NewOptionActionTypes.ITEM_SELECTED,
    id,
    quantity,
  };
}

export function itemsMerged(selectedIds: { [key: number]: number }) {
  return {
    type: NewOptionActionTypes.ITEMS_MERGED,
    selectedIds,
  };
}

export function itemsSelected(selectedIds: { [key: number]: number }) {
  return {
    type: NewOptionActionTypes.ITEMS_SELECTED,
    selectedIds,
  };
}

export function activeGroupChanged(activeGroupId: number) {
  return {
    type: NewOptionActionTypes.GROUP_ACTIVATED,
    activeGroupId,
  };
}

export function getProductNewOptionRequest(id: number, selectedIds?: number[]) {
  return {
    type: NewOptionActionTypes.PRODUCT_NEW_OPTION_REQUEST,
    id,
    selectedIds,
  };
}

export function getProductNewOptionRequestSuccess(data: ResponseModifiers) {
  return {
    type: NewOptionActionTypes.PRODUCT_NEW_OPTION_SUCCESS,
    payload: data,
  };
}

export function getProductNewOptionRequestFailure(error: any) {
  displayToast(
    'ERROR',
    error?.response?.data?.message
      ? error.response.data.message
      : 'ERROR! Please Try again later',
  );
  return {
    type: NewOptionActionTypes.PRODUCT_NEW_OPTION_FAILURE,
    error: error,
  };
}
