import { takeEvery, put, call } from 'redux-saga/effects';
import { createFaveTypes as Type } from '../../types/create-fave';
import { requestCreateFave } from '../../../services/create-fave';
import {
  createFaveFailure,
  createFaveSuccess,
} from '../../actions/create-fave';
import { requestCreateBasket } from '../../../services/basket';

function* createFaveHandler(action: any): any {
  try {
    const basketResponse = yield call(
      requestCreateBasket,
      action.payload.order,
    );
    const payload = {
      ...action.payload.basket,
      basketid: basketResponse.id,
    };
    yield call(requestCreateFave, payload);
    yield put(createFaveSuccess());
  } catch (error) {
    yield put(createFaveFailure(error));
  }
}
export function* createFaveSaga() {
  yield takeEvery(Type.CREATE_FAVE, createFaveHandler);
}
