import { rewardTypes as Type } from '../../types/reward';
import { userTypes } from '../../types/user';

const initialState = {
    challenges: null,
    loading: false,
    error: {},
};

const rewardChallengeReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case Type.GET_REWARDS_CHALLENGE:
            return { ...state, challenges: null, error: {}, loading: true };

        case Type.GET_REWARDS_CHALLENGE_SUCCESS:
            return {
                ...state,
                loading: false,
                challenges: action.payload,
                error: null,
            };
        case Type.GET_REWARDS_CHALLENGE_FAILURE:
            return { ...state, challenges: null, loading: false, error: action.error };
        case userTypes.USER_LOGOUT:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export default rewardChallengeReducer;
