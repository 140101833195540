import axiosInstance from '../../axiosInceptor';

export const getRewardsByTokenAndVendorID = (
  vendorID: string,
  authToken: string,
) => {
  try {
    const url = process.env.REACT_APP_OLO_API || '';

    return axiosInstance
      .get(url + `/users/${authToken}/qualifyingrewards?vendorId=${vendorID}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};
