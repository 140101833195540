import { Restaurant } from '@mui/icons-material';
import { restaurantActionsTypes } from '../../types/restaurant';
import { userTypes } from '../../types/user';
import moment from 'moment';
const INITIAL_STATE = {
  loading: false,
  restaurant: null,
  orderType: '',
  error: {},
  sessionTime: null,
  isMenu: true,
  saveLinkAddress: null,
  HandOffMode: null,
  favoriteLocation: null,
  selectRestaurant: null,
};

const restaurantInfoReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case restaurantActionsTypes.GET_RESTAURANT_INFO_REQUEST:
      return { ...state, loading: true, error: {}, isMenu: true };
    case restaurantActionsTypes.GET_RESTAURANT_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        restaurant: action.payload,
        error: {},
        sessionTime: moment().unix(),
        isMenu: true,
      };
    case restaurantActionsTypes.GET_RESTAURANT_INFO_FAILURE:
      return { ...state, loading: false, error: action.error, isMenu: true, };
    case restaurantActionsTypes.SET_RESTAURANT_INFO_REQUEST:
      return {
        ...state,
        loading: true,
        orderType: '',
        error: {},
        isMenu: true,
      };


    case restaurantActionsTypes.SET_RESTAURANT_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        restaurant: action.payload,
        orderType: action.orderType,
        error: {},
        sessionTime: moment().unix(),
        isMenu: true,
      };

    case restaurantActionsTypes.SET_RESTAURANT_SESSION_REQUEST:
      return {
        ...state,
        sessionTime: action.payload,
      };
    case restaurantActionsTypes.SET_MENU_MOBILE:
      return {
        ...state,
        isMenu: action.payload,
      };
    case restaurantActionsTypes.SET_RESTAURANT_INFO_FAILURE:
      return { ...state, loading: false, error: action.error };
    case restaurantActionsTypes.SET_RESTAURANT_INFO_ORDER_TYPE:
      return { ...state, orderType: action.orderType };
    case restaurantActionsTypes.SAVE_LINK_ADDRESS:
      return {
        ...state,
        saveLinkAddress: action.payload,
      }

    case restaurantActionsTypes.SAVE_HANDOFF_MODE:
      return {
        ...state,
        HandOffMode: action.payload,
      }
    case restaurantActionsTypes.SAVE_DELIVERY_ADDRESS:
      return {
        ...state,
        saveDeliveryAddress: action.payload,
      }
    case restaurantActionsTypes.GET_SELECTED_RESTAURANT_FOR_MOBILE:
      return {
        ...state,
        selectRestaurant: action.payload,
      }
    case restaurantActionsTypes.FAVORITE_LOCATION:
      return {
        ...state,
        favoriteLocation: action.payload,
      }
    case restaurantActionsTypes.RESTAURANT_RESET_REQUEST:
    case userTypes.USER_LOGOUT:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export default restaurantInfoReducer;
