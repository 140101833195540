import { takeEvery, put, call } from 'redux-saga/effects';
import { requestAccountHistory, requestAccountHistoryMobile } from '../../../../services/account-history';
import {
  getAccountHistoryMobileFailure,
  getAccountHistoryMobileSuccess,
  getPointHistoryMobileSuccess,
} from '../../../actions/account-history/mobile';
import { accountHistoryTypes as Type } from '../../../types/account-hostory';



function* accountHistoryMobileHandler(action: any): any {
  try {
    if (action.event_filter === 'rewards') {
      const redemptionresponse = yield call(requestAccountHistoryMobile, 'redemptions');
      const checkinResponse = yield call(requestAccountHistoryMobile, 'checkins');
      yield put(getAccountHistoryMobileSuccess([...redemptionresponse, ...checkinResponse]));
    } else {
      const response = yield call(requestAccountHistoryMobile, action.event_filter);
      yield put(getPointHistoryMobileSuccess(response));
    }
  } catch (error) {
    yield put(getAccountHistoryMobileFailure(error));
  }
}

export function* accountHistoryMobileSaga() {
  yield takeEvery(Type.GET_ACCOUNT_HISTORY_MOBILE, accountHistoryMobileHandler);
}


