import { getNewMenuOptionGroups } from '../../../helpers/product';
import axiosInstance from '../../axiosInceptor';

export const getProductOption = (id: number) => {
  try {
    const url = process.env.REACT_APP_OLO_API || '';
    return axiosInstance
      .get(url + `/products/${id}/modifiers?includedisabled=false`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};

export const getProductOptionNew = (id: number, selectedIds?: number[]) => {
  try {
    const url = process.env.REACT_APP_OLO_API || '';
    return axiosInstance
      .get(url + `/products/${id}/modifiers?includedisabled=false`)
      .then((response) =>
        getNewMenuOptionGroups(response.data.optiongroups, selectedIds),
      )
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};
