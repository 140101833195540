import { GoogleRefererTypes } from '../types/googleReferer.type';

const initialState = {
  rwgToken: null,
};

const googleRefererReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GoogleRefererTypes.CLEAR_RWG_TOKEN:
      return { rwgToken: null };
    case GoogleRefererTypes.SET_RWG_TOKEN:
      return { rwgToken: action.token };
    default:
      return state;
  }
};

export default googleRefererReducer;
