export enum rewardTypes {
  GET_REWARDS = 'GET_REWARDS',
  GET_REWARDS_SUCCESS = 'GET_REWARDS_SUCCESS',
  GET_REWARDS_FAILURE = 'GET_REWARDS_FAILURE',

  GET_REWARDS_NEW = 'GET_REWARDS_NEW',
  GET_REWARDS_NEW_SUCCESS = 'GET_REWARDS_NEW_SUCCESS',
  GET_REWARDS_NEW_FAILURE = 'GET_REWARDS_NEW_FAILURE',

  GET_REWARDS_LOCKED = 'GET_REWARDS_LOCKED',
  GET_REWARDS_LOCKED_SUCCESS = 'GET_REWARDS_LOCKED_SUCCESS',
  GET_REWARDS_LOCKED_FAILURE = 'GET_REWARDS_LOCKED_FAILURE',

  GET_REWARDS_CHALLENGE = 'GET_REWARDS_CHALLENGE',
  GET_REWARDS_CHALLENGE_SUCCESS = 'GET_REWARDS_CHALLENGE_SUCCESS',
  GET_REWARDS_CHALLENGE_FAILURE = 'GET_REWARDS_CHALLENGE_FAILURE',

  GET_USER_REWARDS_FOR_CHECKOUT_REQUEST = 'GET_USER_REWARDS_FOR_CHECKOUT_REQUEST',
  GET_USER_REWARDS_FOR_CHECKOUT_REQUEST_SUCCESS = 'GET_USER_REWARDS_FOR_CHECKOUT_REQUEST_SUCCESS',
  GET_USER_REWARDS_FOR_CHECKOUT_REQUEST_FAILURE = 'GET_USER_REWARDS_FOR_CHECKOUT_REQUEST_FAILURE',

  APPLY_REWARD_TO_BASKET_REQUEST = 'APPLY_REWARD_TO_BASKET_REQUEST',
  APPLY_REWARD_TO_BASKET_REQUEST_SUCCESS = 'APPLY_REWARD_TO_BASKET_REQUEST_SUCCESS',
  APPLY_REWARD_TO_BASKET_REQUEST_FAILURE = 'APPLY_REWARD_TO_BASKET_REQUEST_FAILURE',
  APPLY_REWARD_TO_BASKET_REQUEST_FAILURE_STOP_LOADER = 'APPLY_REWARD_TO_BASKET_REQUEST_FAILURE_STOP_LOADER',

  DELETE_REWARD_FROM_BASKET_REQUEST = 'DELETE_REWARD_FROM_BASKET_REQUEST',
  DELETE_REWARD_FROM_BASKET_REQUEST_SUCCESS = 'DELETE_REWARD_FROM_BASKET_REQUEST_SUCCESS',
  DELETE_REWARD_FROM_BASKET_REQUEST_FAILURE = 'DELETE_REWARD_FROM_BASKET_REQUEST_FAILURE',

  SET_REWARDS_OFFERS_STATE = 'SET_REWARDS_OFFERS_STATE',
}

