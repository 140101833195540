export enum orderActionTypes {
  GET_SINGLE_ORDER_REQUEST = 'GET_SINGLE_ORDER_REQUEST',
  GET_SINGLE_ORDER_REQUEST_SUCCESS = 'GET_SINGLE_ORDER_REQUEST_SUCCESS',
  GET_SINGLE_ORDER_REQUEST_FAILURE = 'GET_SINGLE_ORDER_REQUEST_FAILURE',

  GET_ORDER_RESTAURANT_REQUEST = 'GET_ORDER_RESTAURANT_REQUEST',
  GET_ORDER_RESTAURANT_REQUEST_SUCCESS = 'GET_ORDER_RESTAURANT_REQUEST_SUCCESS',
  GET_ORDER_RESTAURANT_REQUEST_FAILURE = 'GET_ORDER_RESTAURANT_REQUEST_FAILURE',

  UPDATE_GUEST_USER_INFO = 'UPDATE_GUEST_USER_INFO',
  FROM_CHECKOUT = 'FROM_CHECKOUT',
  ORDER_CONFIRMATION_ID = 'ORDER_CONFIRMATION_ID',
}
