import axiosInstance from '../axiosInceptor';

export const requestLocations = () => {
  try {
    const url = `${process.env.REACT_APP_PUNCHH_API}/api2/dashboard/locations`;
    return axiosInstance
      .get(url, {
        headers: {},
      })
      .then((response) => response.data);
  } catch (error) {
    throw error;
  }
};

export const requestSingleLocation = (store_number: any) => {
  try {
    const url = `${process.env.REACT_APP_PUNCHH_API}/api2/dashboard/locations?store_number=${store_number}`;
    return axiosInstance
      .get(url, {
        headers: {},
      })
      .then((response) => response.data);
  } catch (error) {
    throw error;
  }
};

const formatRestaurants = (restaurant: any) => ({
  ...restaurant,
  title: restaurant.name.replace(/\s-\s#\d+/, ''),
});

export const getNearByRestaurants = (
  lat: number,
  long: number,
  radius: number,
  limit: number,
  startDate: string,
  endDate: string,
) => {
  try {
    const url = process.env.REACT_APP_OLO_API || '';
    return axiosInstance
      .get(
        url +
          `/restaurants/near?lat=${lat}&long=${long}&radius=${radius}&limit=${limit}&calendarstart=${startDate}&calendarend=${endDate}`,
      )
      .then((response) => {
        const restaurants = (response.data?.restaurants || []).map(
          formatRestaurants,
        );
        return restaurants;
      })
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};

export const getAllResturants = () => {
  try {
    const url = process.env.REACT_APP_OLO_API || '';
    return axiosInstance
      .get(url + `/restaurants?includePrivate=true`)
      .then((response) => {
        const restaurants = (response.data?.restaurants || []).map(
          formatRestaurants,
        );
        return restaurants;
      })
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};
