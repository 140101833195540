import { ADD_ITEM, ADD_IMAGES } from '../../actions/images';

const initialState = {
  items: [],
  images: {},
};

const imagesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ADD_ITEM:
      return {
        ...state,
        items: [...state.items, action.payload],
      };
    case ADD_IMAGES:
      return {
        ...state,
        images: { ...state.images, ...action.payload },
      };
    default:
      return state;
  }
};

export default imagesReducer;
