import { Button, TextField, Box, Grid, Typography, IconButton, InputAdornment, Stack, Divider } from '@mui/material';
import React, { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CurrentLocationIcon from '../../assets/imgs/current-location-icon.svg';
import SelectedLocationIcon from '../../assets/imgs/selected-location-icon.svg';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CrossIcon from '../../assets/imgs/cross-rounded-icon.svg';
import Searchimage from '../../assets/imgs/search-symbol-icon.svg';
import BackArrowRounded from '../../assets/imgs/back-arrow-rounded-icon.svg';
import moment from 'moment';
import { getFavRestaurant } from '../../redux/actions/user';
import { useDispatch } from 'react-redux';

const OverlayComponent = ({ getSearchResults, handleChangeSearchText,
  searchText,
  currentLocation, filteredRestaurants, onSave, setSelectedResturantData, selectedResturant, isMobile, onClose }: any) => {
  const dispatch = useDispatch();
  const Icon = () => (
    <Button
      className="search-field"
      aria-label="search nearby locations"
      title="search nearby locations"
      onClick={() => getSearchResults(searchText)}
    >
      <SearchIcon />
    </Button>
  );

  const removeNumberFromRestaurantName = (restaurantName: string) => {
    return restaurantName.replace(/\s-\s#\d+/, '');
  }

  const getTimeFormat = (date: string) => {
    return moment(date, 'YYYYMMDD HH:mm').format('h:mm A');
  };
  return (
    <Box className='overlay-parent-container'>
      <div className={`${isMobile && 'overlay-mobile' || 'overlay'}`}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '16px 10px 0px 19px' }}>
          <Box component='img' sx={{ width: '32px', height: '32px', display: { xs: 'inline-block', md: 'none' }, paddingRight: '18px' }} src={BackArrowRounded} onClick={() => onClose()} />
          <Typography className='page-main-heading' sx={{ display: { xs: 'none', md: 'flex' }, margin: 'auto', width: '100%', justifyContent: 'center', alignItems: 'center' }}>SELECT YOUR RESTAURANT</Typography>
          <Typography className='page-main-heading' sx={{ display: { xs: 'flex', md: 'none' } }}>SELECT YOUR STORE</Typography>
        </Box>
        <Box sx={{ padding: '20px 25px 0 25px' }}>
          {
            <>
              <>
                <TextField
                  id="searchField"
                  type="text"
                  variant="standard"
                  placeholder="Search by city, zipcode, and state"
                  value={searchText || ''}
                  aria-label="Search by city, zipcode, and state"
                  autoComplete="false"
                  onChange={(e: any) => {
                    handleChangeSearchText(e.target.value);

                  }}
                  sx={{
                    height: '55px !important',
                    border: '1px solid #CBC8CD !important',
                    borderRadius: '16px !important',
                    fontSize: '14px', paddingRight: '0px', backgroundColor: "#F4F4F4",
                    width: '100%'
                  }}
                  onKeyPress={(e: any) => {
                    if (e.key === 'Enter') {
                      handleChangeSearchText(e.target.value);
                      getSearchResults(e.target.value);
                    }
                  }}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="start" onClick={() => getSearchResults(searchText)}>
                        <div style={{ display: "flex", width: '18px', height: '18px', cursor: 'pointer' }}>
                          <img src={Searchimage} style={{ marginLeft: '10px' }} alt="Search Icon" />
                          <Divider style={{ marginLeft: '2px' }} orientation="vertical" sx={{ borderColor: "#CCCCCC", width: "4px", height: "25px", marginTop: '-4px', borderRightWidth: "unset" }} />
                        </div>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment
                        sx={{ display: searchText?.length ? 'visible' : 'none', cursor: 'pointer' }}
                        position="end"
                        onClick={() => {
                          handleChangeSearchText('')
                          getSearchResults('clear')
                        }
                        }
                      >
                        <img src={CrossIcon} style={{ marginRight: '20px' }} alt="Cross Icon" />
                      </InputAdornment>
                    ),
                  }}
                />
              </>
            </>
          }
        </Box>
        <Grid item xs={12} >
          {
            !searchText?.length &&
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '10px', padding: '16px' }}>
              <Box component='img' src={CurrentLocationIcon} sx={{ width: '34px', height: '34px' }} />
              <a
                className='current-location current-location-sub-heading'
                title="USE YOUR CURRENT LOCATION?"
                role="button"
                tabIndex={0}
                aria-label="USE YOUR CURRENT LOCATION"
                onClick={currentLocation}
                style={{ cursor: 'pointer' }}
              >
                Current Location
              </a>
            </Box>
          }
          <Grid item xs={12} sx={{ padding: searchText ? '20px 0px 8px 0px' : '0px' }}
          >
            <h2 className='current-location-main-heading nearby-spacing'>Nearby {searchText ? `"${searchText}"` : 'Store'}</h2>
          </Grid>
          {
            <Grid
              container
              xs={12}
              sx={{ height: '210px', overflow: 'auto' }}
            >

              {
                filteredRestaurants?.map((resturantItem: any, index: number) => {
                  return (
                    <Grid container xs={12} onClick={() => {
                      setSelectedResturantData(resturantItem)
                    }}

                      className={`${selectedResturant === resturantItem ? 'nearby-stores-list-selected' : 'nearby-stores-list'}`

                      }
                      sx={{ cursor: 'pointer', height: filteredRestaurants.length === 1 ? '100px' : 'auto' }}
                    >
                      <Grid item xs={2}>
                        <Box component='img' src={SelectedLocationIcon} sx={{ width: '51px', height: '51px' }} />
                      </Grid>
                      <Grid item xs={9} >
                        <Stack sx={{ paddingTop: '5px' }}>
                          <Typography className='location-city'>{removeNumberFromRestaurantName(resturantItem.name)}</Typography>
                          <Typography className='location-street'>{resturantItem?.streetaddress},{resturantItem?.zip} {resturantItem?.city}, {resturantItem?.state},</Typography>
                          <Typography className='location-active-hours'></Typography>
                        </Stack>
                        {resturantItem?.distance > 0 && (
                          <Typography className='location-miles-away'>
                            {resturantItem.distance} mi <span style={{
                              verticalAlign: 'text-bottom',
                              fontWeight: 'bold',
                            }}> <span style={{ top: '1px', position: 'relative' }}>&bull;</span></span> Open until {getTimeFormat(resturantItem?.calendars?.[0]?.ranges?.[0]?.end)}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  )
                })

              }
            </Grid>
          }
        </Grid>
        <Button variant='contained' disabled={selectedResturant ? false : true} onClick={() => { onSave(selectedResturant); dispatch(getFavRestaurant(selectedResturant?.distance)) }} className='save-store-btn'> Save Store</Button>
      </div>
    </Box >
  )
}


export default OverlayComponent;
