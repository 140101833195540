import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { Grid, Typography } from '@mui/material';
import './index.css';
const AccountGuestDialog = (props: any) => {
    const {
        open,
        onClose,
        setOpenDialog,
        content,
        loginAccount,
        registerAccount,
    } = props;
    return (
        <Dialog open={open} onClose={onClose} sx={{ borderRadius: '16px' }} classes={{ paper: 'accountlinks-custom MuiDialog-paperWidthSm' }} >
            <Grid>
                <Grid sx={{ padding: '20px 22px 0px 0px', display: 'flex' }}>
                    <CloseIcon sx={{ cursor: 'pointer', marginLeft: 'auto', }} onClick={onClose} />
                </Grid>
                {content}
                <Grid sx={{ dislay: 'flex', justifyContent: 'center', margin: '28px auto auto auto', width: '230px', }}>
                    <Typography
                        aria-label="START ORDER BUTTON"
                        title="START ORDER"
                        sx={{
                            fontFamily: 'GritSans-Bold !important',
                        }}
                        style={{
                            fontSize: '16px',
                            boxShadow: '0px 0px 13px 0px #A1A1A14D',
                            fontWeight: '700',
                            lineHeight: '22px',
                            letterSpacing: '0.35px',
                            textAlign: 'center',
                            color: 'white',
                            backgroundColor: '#006DB7',
                            padding: '6px',
                            borderRadius: '23px',
                            height: '32px',
                            justifyContent: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                        }}
                        onClick={registerAccount}
                    >
                        Create an Account
                    </Typography>
                </Grid>
                <Typography
                    title="Already a Rewards member?"
                    sx={{
                        fontFamily: 'Librefranklin-Regular',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '18px',
                        letterSpacing: '0.15px',
                        textAlign: 'center',
                        color: '#052C42',
                        display: 'flex',
                        justifyContent: 'center',
                        paddingBottom: '40px',
                        paddingTop: '24px',
                    }}
                >
                    Already a member?{' '}

                    <Typography
                        aria-label="START ORDER BUTTON"
                        title="START ORDER"
                        sx={{
                            fontFamily: 'Librefranklin-Regular',
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '18px',
                            letterSpacing: '0.15px',
                            textAlign: 'center',
                            color: '#3D50DF', marginLeft: '7px',
                            cursor: 'pointer',
                        }}
                        onClick={loginAccount}
                    >
                        Login
                    </Typography>
                </Typography>

            </Grid>
        </Dialog >
    );
};

export default AccountGuestDialog;
