import { takeEvery, put } from 'redux-saga/effects';

import { GoogleRefererTypes } from '../types/googleReferer.type';
import { navigateAppAction } from '../actions/navigate-app';

function* asyncGoogleRefererRequest(action: any): any {
//   yield put(navigateAppAction(`/menu/${action.store}`));
}
export function* googleRefererSaga() {
  yield takeEvery(GoogleRefererTypes.SET_RWG_TOKEN, asyncGoogleRefererRequest);
}
