export function getUtensilsProductId(menu: any) {
  let utensilsChainProductIds: number[] = [];

  const utensilsString = process.env.REACT_APP_UTENSILS_CHAIN_PRODUCT_ID;
  
  // Check if the utensils string is defined and not empty
  if (utensilsString && utensilsString.trim() !== '') {
    utensilsChainProductIds = utensilsString.split(',').map(id => parseInt(id.trim()));
  }

  let utensilsProductIds: any[] = [];

  // Iterate over each utensil ID
    if (
      utensilsChainProductIds && utensilsChainProductIds.length > 0 &&
      menu &&
      menu.categories &&
      menu.categories.length
    ) {
      menu.categories.forEach((cat: any) => {
        if (cat.products && cat.products.length) {
          const filterProduct = cat.products.filter(
            (prod: any) => utensilsChainProductIds.includes(prod.chainproductid),
          );
          if (filterProduct && filterProduct.length) {
            // Push the product ID to the array
            utensilsProductIds = filterProduct[0].id;
          }
        }
      });
    }
  return utensilsProductIds;
}
