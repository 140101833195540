import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Avatar,
  Theme,
  Typography,
  ListItemAvatar,
  Collapse,
  IconButton,
  Tooltip,
  Box,
  Grid,
  useTheme,
  useMediaQuery,
  Slider,
  Skeleton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import React, { useEffect, useState } from 'react';

import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { removePreviousAddresses } from '../../helpers/checkout';
import { userLogout } from '../../redux/actions/user';
import rewardIcon from '../../assets/imgs/reward-icon.png';
import orderIcon from '../../assets/imgs/order-icon.png';
import favouriteIcon from '../../assets/imgs/favourite-icon.png';
import backgroundImage from '../../assets/imgs/rewards-sidebar-background.png';
import bluebackgroundImage from '../../assets/imgs/blue-background.svg';
import bluebackgroundMobile from '../../assets/imgs/blue-bg-mobile.svg';
import myOrders from '../../assets/imgs/my-orders.svg';
import myFavorites from '../../assets/imgs/my-favorites.svg';
import scanAndRegister from '../../assets/imgs/scan-and-register.svg';
import myRewardsAndChallenges from '../../assets/imgs/myreward-nd-challenges.svg';
import myOrdersGuest from '../../assets/imgs/MyOrderGuest.svg';
import myFavoritesGuest from '../../assets/imgs/MyFavoriteGuest.svg';
import scanAndRegisterGuest from '../../assets/imgs/ScanToEarnGuest.svg';
import myRewardsAndChallengesGuest from '../../assets/imgs/MyRewardsGuest.svg';
import './index.css';
import coolIcon from '../../assets/imgs/coolicon.svg';
import leftIcon from '../../assets/imgs/arrow-left.svg';
import crossIcon from '../../assets/imgs/ac.svg';
import { isLoginUser } from '../../helpers/auth';
import RewardGiftImage from '../../assets/imgs/reward-gift-image.svg';
import RewardStar from '../../assets/imgs/rewardsStar.svg';
import {
  getRewards,
  getRewardsLocked,
  getRewardsNew,
} from '../../redux/actions/reward';
import AccountGuestDialog from '../account-links-guest-dialog';
import { useStateContext } from '../../pages/scan-and-register/stateContext';
const useStyles = makeStyles((theme: Theme) => ({
  customThumb: {
    // Set the custom icon as the background image
    '& .MuiSlider-thumb:before': {
      boxShadow: 'none',
      width: '200%',
      height: '200%',
    },
    '& .MuiSlider-thumb': {
      width: 20, // Adjust width and height to match your custom icon
      height: 20,
      background: 'none', // Remove the default background
      zIndex: 1,
      '&::after': {
        content: "''",
        position: 'absolute',
        width: '46px',
        height: '52px',
        marginLeft: '2px',
        backgroundImage: `url(${RewardStar})`, // Replace with the path to your icon
        backgroundSize: 'inherit',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        zIndex: 1,
      },
    },
  },
  slider: {
    color: theme.palette.primary.main,
    position: 'relative',
    '& .MuiSlider-thumb': {
      zIndex: 1,
    },
    '& .MuiSlider-rail': {
      height: 4,
      borderRadius: 2,
      border: '0px solid #052C42',
      backgroundColor: '#82959E',
    },
    '& .MuiSlider-mark': {
      width: 8,
      height: 8, // Change the color as
      opacity: 1,
      borderRadius: '10px',
      backgroundColor: '#82959E',
    },
    '& .MuiSlider-markActive': {
      backgroundColor: '#052C42',
    },
    '& .MuiSlider-track': {
      height: 4,
      borderRadius: 2,
      border: '0px solid #052C42',
      backgroundColor: '#052C42',
    },
  },
  link: {
    color: '#052C42 !important',
    textDecoration: 'none',
    textTransform: 'capitalize',
    padding: '5px 5px',
    '& span': {
      fontSize: '16px !important',
      fontFamily: "'LibreFranklin-Regular' !important",
      fontWeight: 600,
      lineHeight: '17.5px',
    },
    '@media (max-width: 600px)': {
      '& span': {
        fontSize: '14px !important',
      },
    },
  },
  name: {
    fontFamily: 'GritSans-Bold',
    fontSize: '28px',
    fontWeight: '700',
    lineHeight: '30px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#ffffff',
  },
  otherLink: {
    color: '#ffffff !important',
    textDecoration: 'none',
    textTransform: 'capitalize',
    padding: '5px 5px',
    '& span': {
      fontSize: '16px !important',
      fontFamily: "'LibreFranklin-Regular' !important",
      fontWeight: 600,
      lineHeight: '17.5px',
    },
  },
  selectedOtherLink: {
    color: '#052C42 !important',
  },
  selected: {
    color: '#006DB7 !important',
  },
  scan: {
    textDecoration: 'underline !important',
  },
}));
const AccountLinks = (props: any) => {
  const { closeDrawer, right } = props;
  const { sharedState, updateSharedState } = useStateContext();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const { basket } = useSelector((state: any) => state.basketReducer);
  const { addresses: basketAddresses } = useSelector(
    (state: any) => state.basketReducer,
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [dataArray, setDataArray] = useState<string[]>([]);
  const { data, loading: loadingRedemptions } = useSelector(
    (state: any) => state.rewardReducerNew,
  );
  const { address: deliveryAddress } = useSelector(
    (state: any) => state.deliveryAddressReducer,
  );
  const [imageSize, setImageSize] = useState({
    width: '116px',
    height: '116px',
    margin: 'auto',
  });
  const { providerToken } = useSelector((state: any) => state.providerReducer);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const logout = () => {
    removePreviousAddresses(basketAddresses, deliveryAddress, basket, true);
    setTimeout(() => {
      dispatch(userLogout());
      if (closeDrawer) {
        closeDrawer(false);
      }
      setTimeout(() => {
        window.location.href = '/login';
      }, 500);
    }, 100);
  };
  const loginAccount = () => {
    setTimeout(() => {
      if (closeDrawer) {
        closeDrawer(false);
      }
      window.location.href = '/login';
    }, 100);
  };
  const registerAccount = () => {
    setTimeout(() => {
      closeDrawer(false);
      window.location.href = '/register';
    }, 100);
  };
  useEffect(() => {
    if (isLoginUser() && right) {
      dispatch(getRewardsNew());
    }
  }, []);
  const breakpoints = [0, 500, 1000, 1400];
  const marks = breakpoints.map((value) => ({ value }));
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpandClick = () => {
    setIsExpanded((prevExpanded) => !prevExpanded);
  };
  const checkSelectedLink = (page: string) => {
    return window.location.href.toLocaleLowerCase().includes(page);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  useEffect(() => {
    // Load the image to determine its size
    const img = new Image();
    img.src = dataArray[0];
    img.onload = () => {
      // Set the container's dimensions based on the image size
      setImageSize({
        width: `${img.width}px`,
        height: `${img.height}px`,
        margin: `auto`,
      });
    };
  }, [dataArray[0]]);
  const dialogContent = (
    <>
      <div style={imageSize}>
        <img
          src={dataArray[0]}
          alt="Your Image"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover', // You can adjust this property based on your layout needs
          }}
        />
      </div>
      <Grid
        sx={{
          fontFamily: 'GritSans-Bold',
          fontSize: '20px',
          fontWeight: '700',
          lineHeight: '24px',
          letterSpacing: '0px',
          textAlign: 'center',
          textTransform: 'uppercase',
          marginTop: dataArray[0] === scanAndRegisterGuest ? '8px' : '16px',
          color: '#052C42',
        }}
      >
        {dataArray[1]}
      </Grid>
      <Grid
        sx={{
          fontFamily: 'GritSans-Bold',
          fontSize: '20px',
          fontWeight: '700',
          lineHeight: '24px',
          letterSpacing: '0px',
          textAlign: 'center',
          textTransform: 'uppercase',
          color: '#052C42',
        }}
      >
        {dataArray[2]}
      </Grid>
      <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
        <Grid
          sx={{
            fontFamily: 'Librefranklin-Regular',
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '22px',
            letterSpacing: '0.15px',
            textAlign: 'center',
            width: '323px',
            marginTop: '20px',
            color: '#052C42',
          }}
        >
          {dataArray[3]}
        </Grid>
      </Grid>
    </>
  );
  const closeDrawerfunc = (isScan?: boolean) => {
    if (closeDrawer) {
      closeDrawer(false);
    }
    if (isMobile && window.location.href.includes('scan')) {
      window.history.back();
      setTimeout(() => {
        updateSharedState({
          type: 'UPDATE_SHARED_STATE_FALSE',
          payload: false,
        });
      }, 100);
    }
  };
  return (
    <>
      <AccountGuestDialog
        registerAccount={registerAccount}
        loginAccount={loginAccount}
        open={openDialog}
        setOpenDialog={setOpenDialog}
        onClose={handleCloseDialog}
        content={dialogContent}
      />
      <List
        component="nav"
        aria-label="Account Menu"
        sx={{ paddingTop: '0px' }}
      >
        <Box
          sx={{
            backgroundColor: right === true ? '#006aa6' : '',
            backgroundImage:
              right === true && !isMobile
                ? `url(${bluebackgroundImage})`
                : !(right === true)
                ? `url(${backgroundImage})`
                : `url(${bluebackgroundMobile})`,
          }}
        >
          {!isMobile &&
            right === true &&
            !data &&
            !data?.net_balance &&
            loadingRedemptions && (
              <Grid sx={{ paddingTop: '63px' }}>
                <Box
                  sx={{
                    width: '358px',
                    height: 'auto',
                    borderRadius: '16px',
                    backgroundColor: 'white',
                    margin: '0px 80px',
                  }}
                >
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    sx={{
                      marginLeft: '-3px',
                      backgroundColor: 'hsl(200, 50%, 30%)',
                      borderRadius: '16px',
                    }}
                    width={364}
                    height={150}
                  />{' '}
                </Box>{' '}
              </Grid>
            )}

          {!isMobile &&
            right === true &&
            data &&
            data?.net_balance > 0 &&
            !loadingRedemptions && (
              <Grid sx={{ paddingTop: '63px' }}>
                <Box
                  sx={{
                    width: '320px',
                    backgroundColor: 'white',
                    height: 'auto',
                    boxShadow: '0px 0px 13px 0px rgba(161, 161, 161, 0.30)',
                    borderRadius: '16px',
                    padding: '16px 21px 12px 18px',
                    margin: '0px 80px',
                    position: 'relative !important',
                  }}
                >
                  <Box
                    component="img"
                    src={RewardGiftImage}
                    sx={{
                      width: '54px',
                      height: '54px',
                      position: 'absolute !important',
                      top: '-20px !important',
                      right: '-7px !important',
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: '12px',
                      color: '#052C42',
                      fontWeight: 600,
                      fontFamily: 'Librefranklin-SemiBold',
                      paddingBottom: '13px',
                    }}
                  >
                    You've Got
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'inline-flex',
                        borderRadius: '18px',
                        background: '#052C42',
                        color: '#6AC7BD',
                        padding: '1px 16px',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '24px !important',
                          fontWeight: 600,
                          fontFamily: 'Librefranklin-SemiBold !important',
                        }}
                      >
                        {data && data?.net_balance ? data?.net_balance : 0}{' '}
                        Points
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          color: '#82959E',
                          fontSize: '12px',
                          fontWeight: 600,
                          textAlign: 'right',
                          fontFamily: 'Librefranklin-SemiBold !important',
                        }}
                      >
                        Keep Eating,
                      </Typography>
                      <Typography
                        sx={{
                          color: '#82959E',
                          fontSize: '12px',
                          fontWeight: 600,
                          fontFamily: 'Librefranklin-SemiBold !important',
                        }}
                      >
                        Keep Earning!
                      </Typography>
                    </Box>
                  </Box>
                  <Slider
                    value={data && data.net_balance ? data.net_balance : 0}
                    step={1}
                    min={0}
                    max={1400}
                    marks={marks}
                    className={`${classes.slider} ${classes.customThumb}`}
                    sx={{
                      width: '100%',
                      padding: '20px 0px !important',
                      marginTop: '10px',
                    }}
                  />
                </Box>
              </Grid>
            )}
          <Grid sx={{ marginLeft: '20px', marginRight: '20px' }}>
            {right === true && isMobile && (
              <>
                <Grid
                  container
                  onClick={() => closeDrawerfunc()}
                  style={{
                    padding: '0px',
                    marginBottom: '10px',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flex: 'auto',
                      marginBottom: '5px',
                      marginTop: '10px',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Grid
                      sx={{
                        backgroundColor: 'white',
                        height: '32px',
                        width: '32px',
                        boxShadow: '0px 0px 13px 0px #A1A1A14D',
                        borderRadius: '18px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        alt="crossIcon"
                        style={{
                          width: '14px',
                          height: '14px',
                          display: 'flex',
                        }}
                        src={crossIcon}
                      />
                    </Grid>
                    <Grid
                      xs={10}
                      className={`${classes.name}`}
                      sx={{
                        padding: '0px 0px 0px 15px',
                        overflow: 'hidden',
                        display: 'flex',
                        justifyContent: 'center',
                        marginRight: '25px',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        textTransform: 'uppercase',
                        flexShrink: 0,
                      }}
                    >
                      Hi{' '}
                      {(
                        (isLoginUser() &&
                          providerToken?.first_name?.split(' ')[0]) ||
                        ''
                      ).length > 7
                        ? (
                            (isLoginUser() &&
                              providerToken?.first_name?.split(' ')[0]) ||
                            ''
                          ).substring(0, 8) + '...'
                        : (isLoginUser() &&
                            providerToken?.first_name?.split(' ')[0]) ||
                          '' ||
                          (!isLoginUser() && 'There') ||
                          ''}
                      !
                    </Grid>
                  </Grid>
                </Grid>
                <Divider
                  sx={{
                    marginBottom: '0px',
                    background: 'rgba(5, 44, 66, 0.23)',
                  }}
                />
              </>
            )}
            {isLoginUser() && (
              <>
                <Link
                  to={isLoginUser() ? '/account/reward' : ''}
                  onClick={() => {
                    if (!isLoginUser()) {
                      setOpenDialog(true);
                      setDataArray([
                        myRewardsAndChallengesGuest,
                        'My Rewards &',
                        'Challenges',
                        'Login or create an account to earn rewards and start challenges!',
                      ]);
                    } else {
                      if (closeDrawer) {
                        closeDrawer(false);
                      }
                      setOpenDialog(false);
                    }
                    updateSharedState({
                      type: 'UPDATE_SHARED_STATE_FALSE',
                      payload: false,
                    });
                  }}
                  style={{ padding: '0px', cursor: 'pointer' }}
                  className={`${
                    right === true ? classes.otherLink : classes.link
                  } (${
                    checkSelectedLink('/reward') && right === true
                      ? ''
                      : checkSelectedLink('/reward') && !(right === true)
                      ? classes.selected
                      : ''
                  })`}
                >
                  <ListItem
                    sx={{
                      paddingTop: !(right === true) ? '25px !important' : '',
                      paddingBottom: !(right === true) ? '25px !important' : '',
                      marginLeft:
                        !(right === true) && !isMobile ? '6px' : '0px',
                    }}
                    className={`${
                      checkSelectedLink('/reward') && right === true
                        ? ''
                        : checkSelectedLink('/reward') && !(right === true)
                        ? classes.selected
                        : ''
                    } list-item-styles`}
                  >
                    <ListItemAvatar
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginLeft:
                          right === true && !isMobile ? '60px' : '0px',
                        marginRight:
                          right === true && !isMobile ? '47px' : '0px',
                        flex: 'auto',
                      }}
                    >
                      <Avatar
                        alt="Image"
                        src={myRewardsAndChallenges}
                        sx={{ width: '51px', height: '51px' }}
                      />
                      <ListItemText
                        primary="My Rewards & Challenges"
                        sx={{
                          padding: '0px 0px 0px 15px',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          width: right === true ? '200px' : '150px',
                        }}
                      />
                      {right === true && (
                        <Grid style={{ marginRight: '17px', marginTop: '5px' }}>
                          <img src={leftIcon} />
                        </Grid>
                      )}
                    </ListItemAvatar>
                  </ListItem>
                  <Divider
                    sx={{
                      marginBottom: '0px',
                      background: 'rgba(5, 44, 66, 0.23)',
                      marginLeft: right === true && isMobile ? '0px' : '-21px',
                      marginRight: right === true && isMobile ? '0px' : '-21px',
                    }}
                  />
                </Link>
                <Link
                  to={isLoginUser() ? '/account/orders' : ''}
                  onClick={() => {
                    if (!isLoginUser()) {
                      setOpenDialog(true);
                      setDataArray([
                        myOrdersGuest,
                        'MY',
                        'ORDERS',
                        'Create an account to save your delivery address and add payment methods for faster ordering.',
                      ]);
                    } else {
                      if (closeDrawer) {
                        closeDrawer(false);
                      }
                      setOpenDialog(false);
                    }
                    updateSharedState({
                      type: 'UPDATE_SHARED_STATE_FALSE',
                      payload: false,
                    });
                  }}
                  style={{ padding: '0px', cursor: 'pointer' }}
                  className={`${
                    right === true ? classes.otherLink : classes.link
                  } ${
                    checkSelectedLink('/orders') && right === true
                      ? ''
                      : (checkSelectedLink('/orders') &&
                          !(right === true) &&
                          classes.selected) ||
                        ''
                  }`}
                >
                  <ListItem
                    sx={{
                      paddingTop: !(right === true) ? '25px !important' : '',
                      paddingBottom: !(right === true) ? '25px !important' : '',
                      marginLeft:
                        !(right === true) && !isMobile ? '6px' : '0px',
                    }}
                    className={`${
                      checkSelectedLink('/orders') && right === true
                        ? ''
                        : (!(right === true) &&
                            checkSelectedLink('/orders') &&
                            classes.selected) ||
                          ''
                    } list-item-styles`}
                  >
                    <ListItemAvatar
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flex: 'auto',
                        marginLeft:
                          right === true && !isMobile ? '60px' : '0px',
                        marginRight:
                          right === true && !isMobile ? '47px' : '0px',
                      }}
                    >
                      <Avatar
                        alt="Image"
                        src={myOrders}
                        sx={{ width: '51px', height: '51px' }}
                      />

                      <ListItemText
                        primary="My Orders"
                        sx={{ padding: '0px 0px 0px 15px', width: '100%' }}
                      />
                      {right === true && (
                        <Grid sx={{ marginRight: '20px', marginTop: '5px' }}>
                          <img src={leftIcon} />
                        </Grid>
                      )}
                    </ListItemAvatar>
                  </ListItem>
                  <Divider
                    sx={{
                      background: 'rgba(5, 44, 66, 0.23)',
                      marginLeft: right === true && isMobile ? '0px' : '-21px',
                      marginRight: right === true && isMobile ? '0px' : '-21px',
                    }}
                  />
                </Link>
                <Link
                  to={isLoginUser() ? '/account/favorites' : ''}
                  onClick={() => {
                    if (!isLoginUser()) {
                      setOpenDialog(true);
                      setDataArray([
                        myFavoritesGuest,
                        'MY',
                        'FAVORITES',
                        'Login or create an account to save a meal as your favorites and easily reorder!',
                      ]);
                    } else {
                      if (closeDrawer) {
                        closeDrawer(false);
                      }
                      setOpenDialog(false);
                    }
                    updateSharedState({
                      type: 'UPDATE_SHARED_STATE_FALSE',
                      payload: false,
                    });
                  }}
                  style={{
                    padding: '0px',
                    cursor: 'pointer',
                    textTransform: 'initial',
                  }}
                  className={`${
                    right === true ? classes.otherLink : classes.link
                  } ${
                    checkSelectedLink('/account/favorites') && right === true
                      ? ''
                      : (!(right === true) &&
                          checkSelectedLink('/account/favorites') &&
                          classes.selected) ||
                        ''
                  }`}
                >
                  <ListItem
                    sx={{
                      paddingTop: !(right === true) ? '25px !important' : '',
                      paddingBottom: !(right === true) ? '25px !important' : '',
                      marginLeft:
                        !(right === true) && !isMobile ? '6px' : '0px',
                    }}
                    className={`${
                      checkSelectedLink('/favorites') && right === true
                        ? ''
                        : (checkSelectedLink('/favorites') &&
                            !(right === true) &&
                            classes.selected) ||
                          ''
                    } list-item-styles`}
                  >
                    <ListItemAvatar
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flex: 'auto',
                        alignItems: 'center',
                        marginLeft:
                          right === true && !isMobile ? '60px' : '0px',
                        marginRight:
                          right === true && !isMobile ? '47px' : '0px',
                      }}
                    >
                      <Avatar
                        alt="Image"
                        src={myFavorites}
                        sx={{ width: '51px', height: '51px' }}
                      />

                      <ListItemText
                        primary="My Favorites"
                        sx={{ padding: '0px 0px 0px 15px' }}
                      />
                      {right === true && (
                        <Grid sx={{ marginRight: '20px', marginTop: '5px' }}>
                          <img src={leftIcon} />
                        </Grid>
                      )}
                    </ListItemAvatar>
                  </ListItem>
                  <Divider
                    sx={{
                      background: 'rgba(5, 44, 66, 0.23)',
                      marginLeft: right === true && isMobile ? '0px' : '-21px',
                      marginRight: right === true && isMobile ? '0px' : '-21px',
                    }}
                  />
                </Link>
              </>
            )}
            {!isLoginUser() && (
              <>
                <a
                  href={isLoginUser() ? '/account/reward' : undefined}
                  onClick={() => {
                    if (!isLoginUser()) {
                      setOpenDialog(true);
                      setDataArray([
                        myRewardsAndChallengesGuest,
                        'My Rewards &',
                        'Challenges',
                        'Login or create an account to earn rewards and start challenges!',
                      ]);
                    } else {
                      if (closeDrawer) {
                        closeDrawer(false);
                      }
                      setOpenDialog(false);
                    }
                    updateSharedState({
                      type: 'UPDATE_SHARED_STATE_FALSE',
                      payload: false,
                    });
                  }}
                  style={{ padding: '0px', cursor: 'pointer' }}
                  className={`${
                    right === true ? classes.otherLink : classes.link
                  } (${
                    checkSelectedLink('/reward') && right === true
                      ? ''
                      : checkSelectedLink('/reward') && !(right === true)
                      ? classes.selected
                      : ''
                  })`}
                >
                  <ListItem
                    sx={{
                      paddingTop: !(right === true) ? '25px !important' : '',
                      paddingBottom: !(right === true) ? '25px !important' : '',
                      marginLeft:
                        !(right === true) && !isMobile ? '6px' : '0px',
                    }}
                    className={`${
                      checkSelectedLink('/reward') && right === true
                        ? ''
                        : checkSelectedLink('/reward') && !(right === true)
                        ? classes.selected
                        : ''
                    } list-item-styles`}
                  >
                    <ListItemAvatar
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginLeft:
                          right === true && !isMobile ? '60px' : '0px',
                        marginRight:
                          right === true && !isMobile ? '47px' : '0px',
                        flex: 'auto',
                      }}
                    >
                      <Avatar
                        alt="Image"
                        src={myRewardsAndChallenges}
                        sx={{ width: '51px', height: '51px' }}
                      />
                      <ListItemText
                        primary="My Rewards & Challenges"
                        sx={{
                          padding: '0px 0px 0px 15px',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          width: right === true ? '200px' : '150px',
                        }}
                      />
                      {right === true && (
                        <Grid style={{ marginRight: '17px', marginTop: '5px' }}>
                          <img src={leftIcon} />
                        </Grid>
                      )}
                    </ListItemAvatar>
                  </ListItem>
                  <Divider
                    sx={{
                      marginBottom: '0px',
                      background: 'rgba(5, 44, 66, 0.23)',
                      marginLeft: right === true && isMobile ? '0px' : '-21px',
                      marginRight: right === true && isMobile ? '0px' : '-21px',
                    }}
                  />
                </a>
                <a
                  href={isLoginUser() ? '/account/orders' : undefined}
                  onClick={() => {
                    if (!isLoginUser()) {
                      setOpenDialog(true);
                      setDataArray([
                        myOrdersGuest,
                        'MY',
                        'ORDERS',
                        'Create an account to save your delivery address and add payment methods for faster ordering.',
                      ]);
                    } else {
                      if (closeDrawer) {
                        closeDrawer(false);
                      }
                      setOpenDialog(false);
                    }
                    updateSharedState({
                      type: 'UPDATE_SHARED_STATE_FALSE',
                      payload: false,
                    });
                  }}
                  style={{ padding: '0px', cursor: 'pointer' }}
                  className={`${
                    right === true ? classes.otherLink : classes.link
                  } ${
                    checkSelectedLink('/orders') && right === true
                      ? ''
                      : (checkSelectedLink('/orders') &&
                          !(right === true) &&
                          classes.selected) ||
                        ''
                  }`}
                >
                  <ListItem
                    sx={{
                      paddingTop: !(right === true) ? '25px !important' : '',
                      paddingBottom: !(right === true) ? '25px !important' : '',
                      marginLeft:
                        !(right === true) && !isMobile ? '6px' : '0px',
                    }}
                    className={`${
                      checkSelectedLink('/orders') && right === true
                        ? ''
                        : (!(right === true) &&
                            checkSelectedLink('/orders') &&
                            classes.selected) ||
                          ''
                    } list-item-styles`}
                  >
                    <ListItemAvatar
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flex: 'auto',
                        marginLeft:
                          right === true && !isMobile ? '60px' : '0px',
                        marginRight:
                          right === true && !isMobile ? '47px' : '0px',
                      }}
                    >
                      <Avatar
                        alt="Image"
                        src={myOrders}
                        sx={{ width: '51px', height: '51px' }}
                      />

                      <ListItemText
                        primary="My Orders"
                        sx={{ padding: '0px 0px 0px 15px', width: '100%' }}
                      />
                      {right === true && (
                        <Grid sx={{ marginRight: '20px', marginTop: '5px' }}>
                          <img src={leftIcon} />
                        </Grid>
                      )}
                    </ListItemAvatar>
                  </ListItem>
                  <Divider
                    sx={{
                      background: 'rgba(5, 44, 66, 0.23)',
                      marginLeft: right === true && isMobile ? '0px' : '-21px',
                      marginRight: right === true && isMobile ? '0px' : '-21px',
                    }}
                  />
                </a>
                <a
                  href={isLoginUser() ? '/account/favorites' : undefined}
                  onClick={() => {
                    if (!isLoginUser()) {
                      setOpenDialog(true);
                      setDataArray([
                        myFavoritesGuest,
                        'MY',
                        'FAVORITES',
                        'Login or create an account to save a meal as your favorites and easily reorder!',
                      ]);
                    } else {
                      if (closeDrawer) {
                        closeDrawer(false);
                      }
                      setOpenDialog(false);
                    }
                    updateSharedState({
                      type: 'UPDATE_SHARED_STATE_FALSE',
                      payload: false,
                    });
                  }}
                  style={{
                    padding: '0px',
                    cursor: 'pointer',
                    textTransform: 'initial',
                  }}
                  className={`${
                    right === true ? classes.otherLink : classes.link
                  } ${
                    checkSelectedLink('/account/favorites') && right === true
                      ? ''
                      : (!(right === true) &&
                          checkSelectedLink('/account/favorites') &&
                          classes.selected) ||
                        ''
                  }`}
                >
                  <ListItem
                    sx={{
                      paddingTop: !(right === true) ? '25px !important' : '',
                      paddingBottom: !(right === true) ? '25px !important' : '',
                      marginLeft:
                        !(right === true) && !isMobile ? '6px' : '0px',
                    }}
                    className={`${
                      checkSelectedLink('/favorites') && right === true
                        ? ''
                        : (checkSelectedLink('/favorites') &&
                            !(right === true) &&
                            classes.selected) ||
                          ''
                    } list-item-styles`}
                  >
                    <ListItemAvatar
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flex: 'auto',
                        alignItems: 'center',
                        marginLeft:
                          right === true && !isMobile ? '60px' : '0px',
                        marginRight:
                          right === true && !isMobile ? '47px' : '0px',
                      }}
                    >
                      <Avatar
                        alt="Image"
                        src={myFavorites}
                        sx={{ width: '51px', height: '51px' }}
                      />

                      <ListItemText
                        primary="My Favorites"
                        sx={{ padding: '0px 0px 0px 15px' }}
                      />
                      {right === true && (
                        <Grid sx={{ marginRight: '20px', marginTop: '5px' }}>
                          <img src={leftIcon} />
                        </Grid>
                      )}
                    </ListItemAvatar>
                  </ListItem>
                  <Divider
                    sx={{
                      background: 'rgba(5, 44, 66, 0.23)',
                      marginLeft: right === true && isMobile ? '0px' : '-21px',
                      marginRight: right === true && isMobile ? '0px' : '-21px',
                    }}
                  />
                </a>
              </>
            )}
            {isLoginUser() && (
              <Link
                to={isLoginUser() ? '/account/scan' : ''}
                onClick={() => {
                  updateSharedState({
                    type: 'UPDATE_SHARED_STATE_FALSE',
                    payload: false,
                  });
                  if (closeDrawer) {
                    closeDrawer(false);
                  }
                }}
                style={{
                  padding: '0px',
                  cursor: 'pointer',
                  textTransform: 'initial',
                }}
                className={`${
                  right === true ? classes.otherLink : classes.link
                } ${
                  checkSelectedLink('/account/scan') && right === true
                    ? ''
                    : (checkSelectedLink('/scan') &&
                        !(right === true) &&
                        classes.selected) ||
                      ''
                }`}
              >
                <ListItem
                  sx={{
                    paddingTop: !(right === true) ? '25px !important' : '',
                    paddingBottom: !(right === true) ? '25px !important' : '',
                    marginLeft: !(right === true) && !isMobile ? '6px' : '0px',
                  }}
                  className={`${
                    checkSelectedLink('/account/scan') && right === true
                      ? ''
                      : (checkSelectedLink('/account/scan') &&
                          !(right === true) &&
                          classes.selected &&
                          classes.scan) ||
                        ''
                  } list-item-styles`}
                >
                  <ListItemAvatar
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flex: 'auto',
                      marginLeft: right === true && !isMobile ? '60px' : '0px',
                      marginRight: right === true && !isMobile ? '47px' : '0px',
                    }}
                  >
                    <Avatar
                      alt="Image"
                      src={scanAndRegister}
                      sx={{ width: '51px', height: '51px' }}
                    />

                    <ListItemText
                      primary="Scan at Register"
                      sx={{ padding: '0px 0px 0px 15px' }}
                    />
                    {right === true && (
                      <Grid style={{ marginRight: '20px', marginTop: '5px' }}>
                        <img src={leftIcon} />
                      </Grid>
                    )}
                  </ListItemAvatar>
                </ListItem>
              </Link>
            )}
            {/* {isMobile && isLoginUser() &&
              <Link
                to={isLoginUser() ? "/account/scan/dailog" : ''}
                onClick={() => {
                  if (!isLoginUser()) {
                    setOpenDialog(true);
                    setDataArray([scanAndRegisterGuest, 'SCAN TO EARN', 'POINTS!', 'Login or create an account to scan and earn points toward free Rubios!']);
                  } else {
                    closeDrawerfunc()
                    setOpenDialog(false);
                  }
                }}
                style={{ padding: '0px', cursor: 'pointer', textTransform: 'initial', }}
                className={`${right === true ? classes.otherLink : classes.link} ${checkSelectedLink('/account/scan') && right === true ? '' : checkSelectedLink('/scan') && !(right === true) && classes.selected || ''}`}
              >
                <ListItem
                  sx={{ paddingTop: !(right === true) ? '25px !important' : '', paddingBottom: !(right === true) ? '25px !important' : '', marginLeft: !(right === true) && !isMobile ? '6px' : '0px', }}
                  className={`${checkSelectedLink('/account/scan') && right === true ? '' : checkSelectedLink('/account/scan') && !(right === true) && classes.selected && classes.scan || ''} list-item-styles`}
                >
                  <ListItemAvatar
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flex: 'auto',
                      marginLeft: right === true && !isMobile ? '60px' : '0px',
                      marginRight: right === true && !isMobile ? '47px' : '0px',
                    }}
                  >
                    <Avatar
                      alt="Image"
                      src={scanAndRegister}
                      sx={{ width: '51px', height: '51px' }} />

                    <ListItemText
                      primary="Scan at Register"
                      sx={{ padding: '0px 0px 0px 15px', }} />
                    {right === true &&
                      <Grid style={{ marginRight: '20px', marginTop: '5px' }}>
                        <img src={leftIcon} />
                      </Grid>}
                  </ListItemAvatar>
                </ListItem>
              </Link>} */}
            {!isMobile && !isLoginUser() && (
              <a
                href={isLoginUser() ? '/account/scan' : undefined}
                onClick={() => {
                  if (!isLoginUser()) {
                    setOpenDialog(true);
                    setDataArray([
                      scanAndRegisterGuest,
                      'SCAN TO EARN',
                      'POINTS!',
                      'Login or create an account to scan and earn points toward free Rubios!',
                    ]);
                  } else {
                    closeDrawerfunc();
                    setOpenDialog(false);
                  }
                  updateSharedState({
                    type: 'UPDATE_SHARED_STATE_FALSE',
                    payload: false,
                  });
                }}
                style={{
                  padding: '0px',
                  cursor: 'pointer',
                  textTransform: 'initial',
                }}
                className={`${
                  right === true ? classes.otherLink : classes.link
                } ${
                  checkSelectedLink('/account/scan') && right === true
                    ? ''
                    : (checkSelectedLink('/scan') &&
                        !(right === true) &&
                        classes.selected) ||
                      ''
                }`}
              >
                <ListItem
                  sx={{
                    paddingTop: !(right === true) ? '25px !important' : '',
                    paddingBottom: !(right === true) ? '25px !important' : '',
                    marginLeft: !(right === true) && !isMobile ? '6px' : '0px',
                  }}
                  className={`${
                    checkSelectedLink('/account/scan') && right === true
                      ? ''
                      : (checkSelectedLink('/account/scan') &&
                          !(right === true) &&
                          classes.selected &&
                          classes.scan) ||
                        ''
                  } list-item-styles`}
                >
                  <ListItemAvatar
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flex: 'auto',
                      marginLeft: right === true && !isMobile ? '60px' : '0px',
                      marginRight: right === true && !isMobile ? '47px' : '0px',
                    }}
                  >
                    <Avatar
                      alt="Image"
                      src={scanAndRegister}
                      sx={{ width: '51px', height: '51px' }}
                    />

                    <ListItemText
                      primary="Scan at Register"
                      sx={{ padding: '0px 0px 0px 15px' }}
                    />
                    {right === true && (
                      <Grid style={{ marginRight: '20px', marginTop: '5px' }}>
                        <img src={leftIcon} />
                      </Grid>
                    )}
                  </ListItemAvatar>
                </ListItem>
              </a>
            )}
          </Grid>
        </Box>
        {isLoginUser() && (
          <>
            <ListItem
              sx={{ padding: '0px', paddingLeft: { xs: '0px !important' } }}
              className={`${classes.link}`}
            >
              <div style={{ width: '100%' }}>
                <IconButton
                  sx={{
                    whiteSpace: 'nowrap',
                    display: 'flex',
                    width: '100%',
                    marginRight: '0px !important',
                    marginTop: !isMobile ? '10px' : '0px',
                    marginBottom: !isMobile ? '4px' : '0px',
                    marginLeft: { xs: '-3px', sm: '0px' },
                    paddingLeft: {
                      xs: '0px',
                      sm: !(right === true) && !isMobile ? '15px' : '0px',
                    },
                  }}
                  onClick={handleExpandClick}
                >
                  <ListItemText
                    primary="Profile & Settings"
                    sx={{
                      display: 'flex',
                      paddingLeft: {
                        xs: '23px',
                        sm: right === true && !isMobile ? '64px' : '0px',
                      },
                      marginLeft: { xs: '0px', sm: '12px' },
                      textTransform: 'initial',
                      fontFamily: 'Librefranklin-Bold !important',
                      color: '#052C42',
                      fontSize: '14px',
                    }}
                  />
                  <Grid
                    sx={{
                      marginRight: {
                        xs: '33px',
                        sm: right === true && !isMobile ? '83px' : '20px',
                      },
                    }}
                  >
                    <img src={coolIcon} />
                  </Grid>
                </IconButton>
                {isExpanded && (
                  <Divider
                    sx={{
                      margin: isMobile && right === true ? '0px 20px' : '0px',
                    }}
                  />
                )}
                <Collapse in={isExpanded}>
                  <Link
                    to="/account/deliveryaddress"
                    onClick={() => {
                      if (closeDrawer) {
                        closeDrawer(false);
                      }
                      updateSharedState({
                        type: 'UPDATE_SHARED_STATE_FALSE',
                        payload: false,
                      });
                    }}
                    className={`${classes.link} ${
                      checkSelectedLink('/deliveryaddress')
                        ? classes.selected
                        : ''
                    }`}
                  >
                    <ListItemText
                      sx={{
                        marginLeft:
                          right === true && !isMobile ? '108px' : '46px',
                        textTransform: 'initial',
                        fontFamily: 'Librefranklin-Regular !important',
                      }}
                      primary="Delivery Address"
                    />
                  </Link>

                  <Link
                    to="/account/favorite-restaurant"
                    style={{ textTransform: 'initial' }}
                    onClick={() => {
                      updateSharedState({
                        type: 'UPDATE_SHARED_STATE_FALSE',
                        payload: false,
                      });
                      if (closeDrawer) {
                        closeDrawer(false);
                      }
                    }}
                    className={`${classes.link} ${
                      checkSelectedLink('/favorite-restaurant')
                        ? classes.selected
                        : ''
                    }`}
                  >
                    {isMobile ? (
                      <ListItemText
                        sx={{
                          marginLeft:
                            right === true && !isMobile ? '108px' : '46px',
                          textTransform: 'initial',
                          fontFamily: 'Librefranklin-Regular !important',
                        }}
                        primary="Favorite Restaurant"
                      />
                    ) : (
                      <ListItemText
                        sx={{
                          marginLeft:
                            right === true && !isMobile ? '108px' : '46px',
                          textTransform: 'initial',
                          fontFamily: 'Librefranklin-Regular !important',
                        }}
                        primary="Favorite Restaurant"
                      />
                    )}
                  </Link>

                  <Link
                    to="/account/paymentinformation"
                    onClick={() => (closeDrawer ? closeDrawer(false) : false)}
                    className={`${classes.link} ${
                      checkSelectedLink('/paymentinformation')
                        ? classes.selected
                        : ''
                    }`}
                  >
                    <ListItemText
                      sx={{
                        marginLeft:
                          right === true && !isMobile ? '108px' : '46px',
                        textTransform: 'initial',
                        fontFamily: 'Librefranklin-Regular !important',
                      }}
                      primary="Payment Methods"
                    />
                  </Link>

                  <Link
                    to="/account/profile"
                    onClick={() => (closeDrawer ? closeDrawer(false) : false)}
                    className={`${classes.link} ${
                      checkSelectedLink('/profile') ? classes.selected : ''
                    }`}
                  >
                    <ListItemText
                      sx={{
                        marginLeft:
                          right === true && !isMobile ? '108px' : '46px',
                        textTransform: 'initial',
                        fontFamily: 'Librefranklin-Regular !important',
                      }}
                      primary="My Profile"
                    />
                  </Link>
                </Collapse>
              </div>
            </ListItem>
            <Divider
              sx={{ margin: isMobile && right === true ? '0px 20px' : '0px' }}
            />
            <ListItem
              className={`${
                checkSelectedLink('/invite') ? classes.selected : ''
              }`}
            >
              <Link
                to="/account/invite"
                onClick={() => {
                  updateSharedState({
                    type: 'UPDATE_SHARED_STATE_FALSE',
                    payload: false,
                  });
                  if (closeDrawer) {
                    closeDrawer(false);
                  }
                }}
                className={`${classes.link} ${
                  checkSelectedLink('/invite') ? classes.selected : ''
                }`}
                style={{
                  marginTop: !isMobile ? '6px' : '0px',
                  marginBottom: !isMobile ? '1px' : '0px',
                  marginLeft: right === true && !isMobile ? '37px' : '0px',
                  textTransform: 'none',
                }}
              >
                <ListItemText
                  primary="Refer a Friend"
                  sx={{
                    marginLeft: !(right === true) && !isMobile ? '6px' : '-4px',
                  }}
                />
              </Link>
            </ListItem>
            <Divider
              sx={{ margin: isMobile && right === true ? '0px 20px' : '0px' }}
            />
          </>
        )}
        {isMobile && right === true && isLoginUser() && (
          <>
            <ListItem>
              <Link
                to=""
                onClick={() => {
                  window.open('https://rubios.com/contact-us/', '_blank');
                  if (closeDrawer) {
                    closeDrawer(false);
                  }
                }}
                className={`${classes.link}`}
                style={{
                  marginLeft: right === true && !isMobile ? '37px' : '0px',
                }}
              >
                <ListItemText
                  primary="Contact Us"
                  sx={{ marginLeft: '-4px' }}
                />
              </Link>
            </ListItem>
            <Divider
              sx={{ margin: isMobile && right === true ? '0px 20px' : '0px' }}
            />
            <ListItem>
              <Link
                to=""
                onClick={() => {
                  window.open(
                    'https://rubios.mymenuhd.com/Restaurant?sView=default',
                    '_blank',
                  );
                  if (closeDrawer) {
                    closeDrawer(false);
                  }
                }}
                className={`${classes.link}`}
                style={{
                  marginLeft: right === true && !isMobile ? '37px' : '0px',
                }}
              >
                <ListItemText
                  primary="Nutrition Info"
                  sx={{ marginLeft: '-4px' }}
                />
              </Link>
            </ListItem>
            <Divider
              sx={{ margin: isMobile && right === true ? '0px 20px' : '0px' }}
            />
            <ListItem>
              <Link
                to=""
                onClick={() => {
                  window.open(
                    'https://rubios.com/rewards/#rewards-faqs',
                    '_blank',
                  );
                  if (closeDrawer) {
                    closeDrawer(false);
                  }
                }}
                className={`${classes.link}`}
                style={{
                  marginLeft: right === true && !isMobile ? '37px' : '0px',
                }}
              >
                <ListItemText primary="FAQs" sx={{ marginLeft: '-4px' }} />
              </Link>
            </ListItem>
            <Divider
              sx={{ margin: isMobile && right === true ? '0px 20px' : '0px' }}
            />
            <ListItem>
              <Link
                to=""
                onClick={() => {
                  window.open(
                    'https://rubioslivedev.wpengine.com/terms-of-use/',
                    '_blank',
                  );
                  if (closeDrawer) {
                    closeDrawer(false);
                  }
                }}
                className={`${classes.link}`}
                style={{
                  marginLeft: right === true && !isMobile ? '37px' : '0px',
                }}
              >
                <ListItemText
                  primary="Terms and Conditions"
                  sx={{ marginLeft: '-4px' }}
                />
              </Link>
            </ListItem>
            <Divider
              sx={{ margin: isMobile && right === true ? '0px 20px' : '0px' }}
            />
            <ListItem>
              <Link
                to=""
                onClick={() => {
                  window.open(
                    'https://rubioslivedev.wpengine.com/privacy/',
                    '_blank',
                  );
                  if (closeDrawer) {
                    closeDrawer(false);
                  }
                }}
                className={`${classes.link}`}
                style={{
                  marginLeft: right === true && !isMobile ? '37px' : '0px',
                }}
              >
                <ListItemText
                  primary="Privacy Policy"
                  sx={{ marginLeft: '-4px' }}
                />
              </Link>
            </ListItem>
            <Divider
              sx={{ margin: isMobile && right === true ? '0px 20px' : '0px' }}
            />
          </>
        )}
        {isLoginUser() && (
          <ListItem>
            <Typography
              onClick={() => {
                logout();
              }}
              className={classes.link}
              style={{
                marginTop: !isMobile ? '6px' : '0px',
                marginBottom: !isMobile ? '1px' : '0px',
                cursor: 'pointer',
                marginLeft: right === true && !isMobile ? '37px' : '0px',
              }}
            >
              <ListItemText
                title="Log ut"
                primary="Log Out"
                sx={{
                  marginLeft: !(right === true) && !isMobile ? '6px' : '-4px',
                }}
              />
            </Typography>
          </ListItem>
        )}
        {!isLoginUser() && (
          <>
            <ListItem>
              <Typography
                onClick={() => loginAccount()}
                className={classes.link}
                sx={{
                  textTransform: 'unset !important',
                }}
                style={{
                  marginTop: !isMobile ? '6px' : '0px',
                  marginBottom: !isMobile ? '1px' : '0px',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  marginLeft: right === true && !isMobile ? '37px' : '0px',
                }}
              >
                <ListItemText
                  title="Log In"
                  primary="Log In"
                  sx={{
                    marginLeft: !(right === true) && !isMobile ? '6px' : '-4px',
                  }}
                />
              </Typography>
            </ListItem>
            <Divider
              sx={{ marginBottom: '0px', background: 'rgba(5, 44, 66, 0.23)' }}
            />
            <ListItem>
              <Typography
                onClick={() => registerAccount()}
                className={classes.link}
                sx={{
                  textTransform: 'unset !important',
                }}
                style={{
                  marginTop: !isMobile ? '6px' : '0px',
                  textDecoration: 'underline',
                  marginBottom: !isMobile ? '1px' : '0px',
                  cursor: 'pointer',
                  marginLeft: right === true && !isMobile ? '37px' : '0px',
                }}
              >
                <ListItemText
                  title="Create an Account"
                  primary="Create an Account"
                  sx={{
                    marginLeft: !(right === true) && !isMobile ? '6px' : '-4px',
                  }}
                />
              </Typography>
            </ListItem>
            <Divider
              sx={{ marginBottom: '0px', background: 'rgba(5, 44, 66, 0.23)' }}
            />
          </>
        )}
      </List>
    </>
  );
};

export default AccountLinks;
