import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import './index.css';
import { removeBasketCouponCode } from '../../../redux/actions/basket/checkout';
import { useDispatch, useSelector } from 'react-redux';

export const CouponNotApplied = (props: any) => {
  const {
    setProductRemove,
    setCouponDialogOpen,
    couponDialogOpen,
    duplicateProductHandle,
    removeProductId,
  } = props;
  const theme = useTheme();
  const dispatch = useDispatch();
  const basketObj = useSelector((state: any) => state.basketReducer);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const handleClose = () => {
    setCouponDialogOpen(false);
    setProductRemove(false);
    duplicateProductHandle(removeProductId);
  };
  const backdropClose = (event: any, reason: any) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    handleClose();
  };
  const handleRemoveCoupon = () => {
    setCouponDialogOpen(false);
    setProductRemove(false);
    dispatch(removeBasketCouponCode(basketObj?.basket?.id, ''));
  };
  return (
    <div>
      <Dialog
        className="error-dailog-store"
        open={couponDialogOpen}
        onClose={backdropClose}
        fullWidth
        sx={{ borderRadius: '16px !important' }}
      >
        <Stack
          direction="row"
          sx={{
            justifyContent: 'center !important',
            paddingTop: { xs: '16px', sm: '24px' },
            paddingBottom: { xs: '5px', sm: '0px' },
            alignItems: 'center !important',
            display: 'flex',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'GritSans-Bold !important',
              fontSize: { xs: '14px !important', sm: '20px !important' },
            }}
            className="pick-up-unavaliable-store"
          >
            REMOVE COUPON
          </Typography>
          <Box
            sx={{
              display: { xs: 'none', sm: 'flex' },
              justifyContent: 'flex-end !important',
              alignItems: 'flex-end !important',
              position: 'absolute',
              right: '20px',
              cursor: 'pointer',
            }}
          >
            <CloseIcon onClick={handleClose} />
          </Box>
        </Stack>
        <DialogContent
          sx={{
            margin: 'auto',
            padding: { xs: '0px 0px 16px 0px', sm: '40px 0px' },
          }}
        >
          <Typography
            className="error-dailog-text-store"
            sx={{
              width: '323px',
              fontSize: '15px',
              fontFamily: "'Librefranklin-Regular' !important",
              textAlign: 'center',
            }}
          >
            Your coupon won’t be valid for the order after removing this item from your cart.
            Are you sure you want to remove the coupon?
          </Typography>
        </DialogContent>
        {isMobile && <Divider />}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            marginBottom: { sm: '24px', xs: '0px' },
            gap: { sm: '16px', xs: '0' },
          }}
          justifyContent={'center'}
        >
          <Button
            onClick={handleClose}
            color="primary"
            variant="outlined"
            sx={{
              borderRadius: '30px !important',
              width: '175px',
              height: '42px',
              color: 'var(--Secondary, #006DB7)',
              fontFamily: 'GritSans-Bold !important',
              fontWeight: '700',
              fontSize: '16px',
              border: {
                xs: 'none',
                sm: '2px solid var(--Secondary, #006DB7) !important',
              },
            }}
          >
            CANCEL
          </Button>
          {isMobile && (
            <Divider orientation="vertical" sx={{ height: '44px' }} />
          )}
          <Button
            onClick={handleRemoveCoupon}
            color="secondary"
            variant="contained"
            sx={{
              color: { xs: '#006DB7 !important', sm: 'white !important' },
              backgroundColor: {
                xs: 'transparent !important',
                sm: '#006DB7 !important',
              },
              borderRadius: '30px !important',
              width: '192px',
              height: '42px',
              fontFamily: 'GritSans-Bold !important',
              fontWeight: '700',
              fontSize: '16px',
              padding: ' 9px 10px 12px 10px !important',
            }}
          >
            REMOVE
          </Button>
        </Box>
      </Dialog>
    </div>
  );
};
