import { iframeActionTypes } from '../../types/iframe';

const INITIAL_STATE = {
  chainProductId: null,
  iframeProductId: null,
};

const iframeRedirectReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case iframeActionTypes.CHAIN_PRODUCT_ID:
      return {
        ...state,
        chainProductId: action.payload,
      };
    case iframeActionTypes.IFRAME_PRODUCT_ID:
      return {
        ...state,
        iframeProductId: action.payload,
      };

    default:
      return state;
  }
};

export default iframeRedirectReducer;
