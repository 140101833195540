import { forwardRef, useEffect, useState } from 'react';
import { Box, Button, Card, Divider, Drawer, Grid, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { makeStyles } from '@mui/styles';
import { QrReader } from 'react-qr-reader';
import './index.css'
import receiptImage from '../../assets/imgs/reciept-resize.png'
import DrawerCloseCross from '../../assets/imgs/cross-links-icon.svg';

import * as Yup from 'yup';
import { Formik } from 'formik';
import { IMaskInput } from 'react-imask';
import { createCheckIn } from '../../redux/actions/check-in';
import ManualEditDialog from './ManualEditDailog';
import { useStateContext } from './stateContext';
import { displayToast } from '../../helpers/toast';


const useStyles = makeStyles({
  drawerPaper: {
    marginTop: "40px"
  }
});

const ScanAndRegister = () => {
  const { state, updateSharedState } = useStateContext();
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { providerToken } = useSelector((state: any) => state.providerReducer);
  const [showScanner, setShowScanner] = useState<any>(false);
  const [openDrawer, setOpenDrawer] = useState<any>(true);
  const [openManualDialog, setOpenManualDialog] = useState<any>(false);

  useEffect(() => {
    setOpenDrawer(true)
  }, [state.sharedState === false]);

  interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
  }

  const NumberFormatCustom = forwardRef<HTMLElement, CustomProps>(
    function NumberFormatCustom(props, ref) {
      const { onChange, ...other } = props;

      return (
        <IMaskInput
          {...other}
          mask="00-000-000-0000-0"
          definitions={{
            '#': /[1-9]/,
          }}
          onAccept={(value: any) =>
            onChange({ target: { name: props.name, value } })
          }
          overwrite
        />
      );
    },
  );
  const closeCam = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: true,
    });
    // the rest of the cleanup code
    window.location.reload()
  };

  return (
    <>
      <ManualEditDialog
        openManualDialog={openManualDialog}
        setOpenManualDialog={setOpenManualDialog}
        setOpenDrawer={setOpenDrawer} />
      <Formik
        initialValues={{
          barcode: '',
        }}
        validationSchema={Yup.object({
          barcode: Yup.string()
            .min(16, 'Must be at least 13 digits')
            .required('required'),
        })}
        onSubmit={async (values: any) => {
          dispatch(createCheckIn(values.barcode.replace(/\D/g, '')));
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          isValid,
          dirty,
        }) => (
          <form onSubmit={handleSubmit}>
            {!isMobile &&
              <Box
                className="my-reward-challenges"
                sx={{ paddingLeft: { sm: '30px', lg: '24px' } }}
              >

                <Typography
                  sx={{
                    fontSize: { xs: '16px', sm: '30px' },
                    fontWeight: 700,
                    lineHeight: '52px',
                    fontFamily: 'GritSans-Bold',
                    color: '#052C42',
                  }}
                >
                  REDEEM YOUR REWARDS
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: '13px', sm: '16px' },
                    fontWeight: 500,
                    lineHeight: '19px',
                    fontFamily: 'Librefranklin-Regular',
                    color: '#052C4280',
                    width: { sm: 'auto', lg: '523px' },
                    paddingTop: '8px',
                    paddingBottom: { xs: '20px', sm: '40px' },
                  }}
                >
                  To check in at the restaurant, scan the QR code below at the register.
                  If you order online, you are automatically checked in.
                </Typography>
                <Grid
                  container
                  sx={{
                    width: { sm: 'auto', md: '260px' },
                    border: '1px solid #C6C6C6',
                    borderRadius: '16px',
                    boxShadow: '0px 0px 13px 0px rgba(161, 161, 161, 0.30)',
                  }}
                >
                  <Grid item sm={5} xs={5}>
                    <Card className="scan-point-qr">
                      <QRCodeSVG
                        size={130}
                        value={providerToken?.user_as_qrcode}
                      />
                    </Card>
                  </Grid>
                  <Grid
                    item
                    sm={7}
                    xs={7}
                    sx={{
                      paddingLeft: { xs: '20.5px', sm: '36px', lg: '40.5px' },
                      display: 'flex',
                      marginTop: '-10px',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#052C42',
                        fontSize: '24px',
                        fontWeight: 700,
                        fontFamily: 'GritSans-Bold',
                        lineHeight: '30px',
                        width: '97px',
                        height: '60px'
                      }}
                    > SCAN CODE
                    </Typography>
                  </Grid>
                </Grid>
                <Divider style={{ marginTop: '40px', borderBottomWidth: '2px' }}></Divider>
                <Grid container xs={12} className='forget-check-in-parent'>
                  <Grid xs={12} md={8} >
                    <Typography
                      sx={{
                        fontSize: { xs: '16px', sm: '24px' },
                        textTransform: 'uppercase',
                        fontWeight: 700,
                        lineHeight: '30px',
                        fontFamily: 'GritSans-Bold',
                        color: '#052C42',
                        marginTop: '24px'
                      }}
                    >
                      Forgot to check in?
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: '13px', sm: '16px' },
                        fontWeight: 400,
                        lineHeight: '19.3px',
                        fontFamily: 'Librefranklin-Regular',
                        color: '#052C4280',
                        paddingTop: '16px',
                        paddingBottom: { xs: '20px', sm: '24px' },
                        width: { sm: 'auto', md: '562px' },
                      }}
                    >
                      If you did not check in at the restaurant, you may enter the Rubio’s Rewards barcode number located at the bottom of your receipt.
                      Receipt codes must be entered within 48 hours of purchase.
                    </Typography>
                    <TextField
                      aria-label="Rubio's rewards barcode number"
                      name="barcode"
                      placeholder="xx-xxx-xxx-xxxx-x"
                      aria-required="true"
                      title="Rubio's rewards barcode number"
                      value={values.barcode}
                      onChange={handleChange('barcode')}
                      onBlur={handleBlur('barcode')}
                      error={Boolean(touched.barcode && errors.barcode)}
                      helperText={touched.barcode && errors.barcode}
                      InputProps={{
                        inputComponent: NumberFormatCustom as any,
                      }}
                      sx={{ width: '80%' }}
                    />
                    <Typography
                      sx={{
                        fontSize: { xs: '13px', sm: '16px' },
                        fontWeight: 400,
                        lineHeight: '19.3px',
                        fontFamily: 'Librefranklin-Regular',
                        color: '#052C4280',
                        paddingTop: '24px',
                        paddingBottom: { xs: '20px', sm: '40px' },
                        width: { sm: 'auto', md: '502px' },
                      }}
                    >
                      Please enter the 12 or 13 digit numeric barcode at the bottom of your receipt.
                      Please ensure that you enter any leading zeroes that may appear in the barcode. e.g. 0600101234124
                    </Typography>
                    <Button
                      variant="contained"
                      type='submit'
                      disabled={values.barcode.length < 17}
                      sx={{
                        background: '#006DB7',
                        width: { sm: '100%', md: '282px', lg: '282px' },
                        borderRadius: '30px !important',
                        marginBottom: '2rem',
                        color: '#FFF',
                        fontFamily: 'GritSans-Bold !important',
                        fontSize: '15px',
                        fontWeight: 700,
                        height: '44px',
                        letterSpacing: '0.25px !important',

                      }}
                    >
                      SUBMIT
                    </Button>
                  </Grid>
                  <Grid xs={0.5}>
                    <Divider orientation="vertical" style={{ height: '63%', marginTop: '70px' }}></Divider>
                  </Grid>
                  <Grid xs={3.5} style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '-20px',
                  }}>
                    <img src={receiptImage} />
                  </Grid>
                </Grid>
              </Box>
            }

            {isMobile &&
              <div>
                {openDrawer &&
                  <Drawer
                    classes={{
                      paper: classes.drawerPaper
                    }}
                    // variant="permanent"
                    // anchor="bottom"
                    open={openDrawer}
                    sx={{
                      width: '100%',
                      height: 'calc(90vh)',
                      flexShrink: 0,
                      '& .MuiDrawer-paper': {
                        width: '100%',
                        height: 'calc(100vh)',
                        transition: 'height 0.3s',
                        borderRadius: '16px 16px 0 0',
                        background: 'white',

                      },
                    }}
                  >
                    <div className='time-slot-mobile-drawer-container'>
                      {showScanner &&
                        <>
                          <div className='schedule-spacing' onClick={() => {
                            setTimeout(() => {
                              setShowScanner(false)
                            }, 100);
                          }}>
                            <Typography className='schedule-delivery-text'>My Rewards Code</Typography>
                            <Box component='img' src={DrawerCloseCross} sx={{ cursor: 'pointer' }} />
                          </div>
                          <QrReader
                            scanDelay={500}
                            onResult={(result: any, error: any, codeReader: any,) => {
                              if (!!result) {
                                dispatch(createCheckIn(result.text.replace(/\D/g, '')));
                                setShowScanner(false)
                              }
                              if (!!error) {
                                if (error.toString().length > 1) {
                                  displayToast('ERROR', error.toString());
                                  setShowScanner(false)
                                }
                              }
                            }}

                            constraints={{ facingMode: 'environment' }}
                          />
                        </> ||
                        <div
                          style={{
                            height: '85vh', /* Set the initial content height to 80% of the viewport height */
                            overflowY: 'auto'
                          }}>
                          <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            textAlign: 'center',
                            position: 'absolute',
                            width: '100%',
                            top: 0,
                            background: '#ffffff',
                            overflowY: 'hidden'
                          }} onClick={() => {
                            updateSharedState({ type: 'UPDATE_SHARED_STATE', payload: true });
                            setTimeout(() => {
                              setOpenDrawer(false)
                            }, 100);
                            // window.history.back();
                            // navigate(-1)

                          }}>
                            <Typography
                              sx={{
                                marginLeft: '49px',
                                fontFamily: 'GritSans-Bold !important',
                                textAlign: 'center !important',
                                width: '90%',
                                fontSize: '20px !important',
                              }}>
                              MY REWARDS CODE
                            </Typography>
                            <Box component='img' src={DrawerCloseCross} sx={{ cursor: 'pointer' }} />
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', }}>
                            <div style={{
                              background: '#F3F2EF',
                              width: '100%',
                              alignItems: 'center',
                              display: 'flex',
                              flexDirection: 'column',
                              padding: '32px 0px',
                              marginTop: '60px'
                            }}>
                              <Grid item sm={5} xs={12}>
                                <Box sx={{ borderRadius: '15px !important', padding: '52px', background: 'white !important', }}>
                                  <QRCodeSVG
                                    size={227}
                                    value={providerToken?.user_as_qrcode}
                                  />
                                </Box>
                              </Grid>
                              <Typography
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  textAlign: 'center',
                                  height: '40px',
                                  width: '50%',
                                  fontWeight: 700,
                                  lineHeight: '20px',
                                  paddingTop: '32px'
                                }}
                                sx={{
                                  fontFamily: "'Librefranklin-SemiBold' !important",
                                }}>
                                Scan at register to earn points for this order!</Typography>
                            </div>
                            <Grid item xs={12} sx={{ paddingTop: '24px !important' }}>
                              <Typography
                                sx={{
                                  fontFamily: "'Librefranklin-SemiBold' !important",
                                  fontWeight: 700,
                                  textAlign: 'center'
                                }}>Forgot to show your QR code at register?
                              </Typography>
                              <Typography style={{
                                justifyItems: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                                padding: '0px 26px',
                              }}>No worries! You can still earn points for your order, just scan the QR code on your receipt or enter the code manually.</Typography>
                            </Grid>
                            <Stack sx={{
                              width: '90%'
                            }}>
                              <Button
                                variant="contained"
                                onClick={() => setShowScanner(true)}
                                sx={{
                                  background: '#006DB7',
                                  width: { sm: '100%', md: '333px', lg: '333px' },
                                  borderRadius: '30px !important',
                                  marginTop: '40px',
                                  color: '#FFF',
                                  fontFamily: 'GritSans-Bold !important',
                                  fontSize: '15px',
                                  fontWeight: 700,
                                  height: { xs: '44px', md: '57px' },
                                  letterSpacing: '0.25px !important',
                                  textTransform: 'uppercase',
                                }}
                              >
                                Scan Reciept
                              </Button>
                              <Button
                                variant="outlined"
                                onClick={() => setOpenManualDialog(true)}

                                sx={{
                                  background: '#FFFFFF',
                                  width: { sm: '100%', md: '333px', lg: '333px' },
                                  borderRadius: '30px !important',
                                  marginTop: '16px',
                                  marginBottom: '30px',
                                  color: '#006DB7',
                                  fontFamily: 'GritSans-Bold !important',
                                  fontSize: '15px',
                                  fontWeight: 700,
                                  height: { xs: '44px', md: '57px' },
                                  letterSpacing: '0.25px !important',
                                  textTransform: 'uppercase',
                                  border: '2px solid #006DB7'
                                }}
                              >
                                enter code manually
                              </Button>
                              <Button
                                sx={{
                                  visibility: 'hidden',
                                  cursor: 'none'
                                }}
                              >
                              </Button>
                            </Stack>
                          </div>
                        </div>
                      }
                    </div>
                  </Drawer>
                }
              </div>

            }
          </form>)
        }
      </Formik >
    </>
  );
};

export default ScanAndRegister;
