export enum redemptionTypes {
  GET_REWARD_REDEMPTION_CODE = 'GET_REWARD_REDEMPTION_CODE',
  GET_REWARD_REDEMPTION_CODE_SUCCESS = 'GET_REWARD_REDEMPTION_CODE_SUCCESS',
  GET_REWARD_REDEMPTION_CODE_FAILURE = 'GET_REWARD_REDEMPTION_CODE_FAILURE',

  GET_REDEEMABLE_REDEMPTION_CODE = 'GET_REDEEMABLE_REDEMPTION_CODE',
  GET_REDEEMABLE_REDEMPTION_CODE_SUCCESS = 'GET_REDEEMABLE_REDEMPTION_CODE_SUCCESS',
  GET_REDEEMABLE_REDEMPTION_CODE_FAILURE = 'GET_REDEEMABLE_REDEMPTION_CODE_FAILURE',

  SET_REWARD = 'SET_REWARD',
}
