import { takeEvery, put, call } from 'redux-saga/effects';
import { rewardTypes as Type } from '../../types/reward';
import {
  getRewardsChallengeFailure,
  getRewardsChallengeSuccess,
  getRewardsFailure, getRewardsLockedFailure, getRewardsLockedSuccess,
  getRewardsNewFailure,
  getRewardsNewSuccess,
  getRewardsSuccess,
} from '../../actions/reward';
import { requestRewards, requestRewardsChallenges, requestRewardsLocked, requestRewardsNew } from '../../../services/reward';

function* redeemRewardHandler(): any {
  try {
    const response = yield call(requestRewards);
    yield put(getRewardsSuccess(response));
  } catch (error) {
    yield put(getRewardsFailure(error));
  }
}

function* getRewardNewHandler(): any {
  try {
    const response = yield call(requestRewardsNew);
    yield put(getRewardsNewSuccess(response));
  } catch (error) {
    yield put(getRewardsNewFailure(error));
  }
}
function* getRewardChallengeHandler(): any {
  try {
    const response = yield call(requestRewardsChallenges);
    yield put(getRewardsChallengeSuccess(response));
  } catch (error) {
    yield put(getRewardsChallengeFailure(error));
  }
}
function* getRewardLockedHandler(): any {
  try {
    const response = yield call(requestRewardsLocked);
    yield put(getRewardsLockedSuccess(response));
  } catch (error) {
    yield put(getRewardsLockedFailure(error));
  }
}

export function* redeemRewardSada() {
  yield takeEvery(Type.GET_REWARDS, redeemRewardHandler);
  yield takeEvery(Type.GET_REWARDS_NEW, getRewardNewHandler);
  yield takeEvery(Type.GET_REWARDS_LOCKED, getRewardLockedHandler);
  yield takeEvery(Type.GET_REWARDS, getRewardChallengeHandler);
}
