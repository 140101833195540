import MainApp from './MainApp';
import IframeApp from './IframeApp';
import { StateProvider } from './pages/scan-and-register/stateContext';

const isIframe = window.location.href.toLocaleLowerCase().includes('/iframe');

function App(props: any) {
  return (
    <>
      {isIframe ? (
        <IframeApp />
      ) : (
        <StateProvider>
          <MainApp />
        </StateProvider>
      )}
    </>
  );
}
export default App;
