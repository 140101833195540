import { accountHistoryTypes as Type } from '../../../types/account-hostory';

export function getAccountHistoryMobile(event_filter: string = '') {
  return {
    type: Type.GET_ACCOUNT_HISTORY_MOBILE,
    event_filter,
  };
}

export function getAccountHistoryMobileSuccess(data: any) {
  return {
    type: Type.GET_ACCOUNT_HISTORY_MOBILE_SUCCESS,
    payload: data,
  };

}

export function getAccountHistoryMobileFailure(error: any) {
  return {
    type: Type.GET_ACCOUNT_HISTORY_MOBILE_FAILURE,
    error: error,
  };
}


export function getPointHistoryMobileSuccess(data: any) {
  return {
    type: Type.GET_POINT_HISTORY_MOBILE_SUCCESS,
    payload: data,
  };
}
