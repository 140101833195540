import { Product } from "../../types/olo-api";
import axiosInstance from "../axiosInceptor";

export const getMenuItem = (id: number) => {
  try {
    const url = process.env.REACT_APP_OLO_API || "";
    return axiosInstance
      .get(url + `/restaurants/${id}/menu?includedisabled=false`)
      .then((response) => {
        const data = response.data
        for (const category of data.categories) {
          category.products = category.products.map((product: Product) => {
            product.categoryInfo = {
              id: category.id,
              name: category.name
            };
            return product;
          })
        }
        return data;
      })
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};
