import { NewOptionActionTypes } from '../../../types/product/new-option';
import { userTypes } from '../../../types/user';

const INITIAL_STATE = {
  loading: false,
  options: null,
  selectedIds: {},
  defaultSelectedIds: {},
  isChanged: false,
  activeGroupId: null,
  error: {},
};

const checkChanged = (selectedIds: any, defaultSelectedIds: any) => {
  for (const key in selectedIds) {
    const selectedValue = selectedIds[key];
    const defaultValue = defaultSelectedIds[key];
    if (selectedValue > 0 && selectedValue !== defaultValue) {
      return true;
    }
  }
  return false;
};

const productNewOptionsReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case NewOptionActionTypes.ITEM_SELECTED:
      return {
        ...state,
        selectedIds: {
          ...state.selectedIds,
          [action.id]: action.quantity,
        },
      };
    case NewOptionActionTypes.GROUP_ACTIVATED:
      return {
        ...state,
        activeGroupId: action.activeGroupId,
      };
    case NewOptionActionTypes.ITEMS_SELECTED:

      return {
        ...state,
        isChanged: checkChanged(action.selectedIds, state.defaultSelectedIds),
        selectedIds: { ...action.selectedIds },
      };
    case NewOptionActionTypes.ITEMS_MERGED:
      return {
        ...state,
        isChanged: checkChanged(
          { ...state.selectedIds, ...action.selectedIds },
          state.defaultSelectedIds,
        ),
        selectedIds: { ...state.selectedIds, ...action.selectedIds },
      };
    case NewOptionActionTypes.PRODUCT_NEW_OPTION_REQUEST:
      return {
        ...INITIAL_STATE,
        loading: true,
      };
    case NewOptionActionTypes.PRODUCT_NEW_OPTION_SUCCESS:
      const createMap = (acc: any, id: number) => ({
        ...acc,
        [id]: (acc[id] ?? 0) + 1,
      });
      const defaultSelectedIds = action.payload.defaultSelectedIds.reduce(
        createMap,
        {},
      );
      const selectedIds = action.payload.selectedIds.reduce(createMap, {});
      return {
        ...state,
        loading: false,
        error: {},
        options: action.payload.options,
        defaultSelectedIds,
        selectedIds,
        isChanged: checkChanged(selectedIds, defaultSelectedIds),
      };
    case NewOptionActionTypes.PRODUCT_NEW_OPTION_FAILURE:
      return { ...state, loading: false, error: action.error };
    case userTypes.USER_LOGOUT:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export default productNewOptionsReducer;
