import { takeEvery, put, call } from 'redux-saga/effects';
import { getProductOption, getProductOptionNew } from '../../../../services/product/option';
import { NewOptionActionTypes } from '../../../types/product/new-option';
import {
  getProductNewOptionRequestFailure,
  getProductNewOptionRequestSuccess,
} from '../../../actions/product/new-option';

function* asyncProductOptionsRequest(action: any): any {
  try {
    const response = yield call(
      getProductOptionNew,
      action.id,
      action.selectedIds,
    );
    yield put(getProductNewOptionRequestSuccess(response));
  } catch (error) {
    yield put(getProductNewOptionRequestFailure(error));
  }
}

export function* productNewOptionsSaga() {
  yield takeEvery(
    NewOptionActionTypes.PRODUCT_NEW_OPTION_REQUEST,
    asyncProductOptionsRequest,
  );
}
