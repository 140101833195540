export const ADD_ITEM = 'ADD_ITEM';
export const ADD_IMAGES = 'ADD_IMAGES';

const formatImagesResponse = (imagesResponse: any[]) => {
  const resp = imagesResponse.reduce((images: any, info) => {
    info.production_plu_names.forEach((pluName: any) => {
      images[pluName] = info.fimg_url;
    });
    info.sandbox_plu_names.forEach((pluName: any) => {
      images[pluName] = info.fimg_url;
    });
    return images;
  }, {});
  return resp;
};

export function getImagesList(item: any | undefined) {
  return {
    type: ADD_ITEM,
    payload: item,
  };
}

export function getImages(item: any | undefined) {
  return {
    type: ADD_IMAGES,
    payload: formatImagesResponse(item),
  };
}
