import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Grid,
  Skeleton,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { resetBasketRequest } from '../../redux/actions/basket';
import { addProductRequest } from '../../redux/actions/basket/product/add';
import { removeProductRequest } from '../../redux/actions/basket/product/remove';

import cboxIcon from '../../assets/imgs/Checkbox.svg';
import deleteIcon from '../../assets/imgs/DeleteRounded.svg';
import plusRoundedIcon from '../../assets/imgs/PlusRounded.svg';
import backIcon from '../../assets/imgs/backArrowIcon.svg';
import ChangedCrossIcon from '../../assets/imgs/changedCrossIcon.svg';
import ForkAndSpoon from '../../assets/imgs/fork-and-spoon.svg';
import minusIcon from '../../assets/imgs/minusIcon.svg';
import MyBagCartImage from '../../assets/imgs/my-bag-cart.svg';
import pickupIcon from '../../assets/imgs/pickupImg.svg';
import plusIcon from '../../assets/imgs/plusImg.svg';
import seeLessIcon from '../../assets/imgs/seeLess.svg';
import seeMoreIcon from '../../assets/imgs/seeMore.svg';
import statusIcon from '../../assets/imgs/statusImg.svg';
import ArrowUpIcon from '../../assets/imgs/up-arrow-rounded.svg';

import './mybag.css';

import {
  UPSELLS_TYPES,
  getUpsellsProducts,
  getUpsellsStatsFromOrder,
} from '../../helpers/upsells';
import { displayToast } from '../../helpers/toast';
import { calculateTaxAndFee, orderFees } from '../../helpers/common';
import {
  addUtensilsRequest,
  removeUtensilsRequest,
} from '../../redux/actions/basket/utensils';
import { DeliveryModeEnum } from '../../types/olo-api/olo-api.enums';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { validateBasket } from '../../../src/services/checkout';
import { isLoginUser } from '../../helpers/auth';
import { facebookSendEvent } from '../../redux/actions/facebook-conversion';
import { facebookConversionTypes } from '../../redux/types/facebook-conversion';

import _, { debounce } from 'lodash';
import moment from 'moment';
import TagManager from 'react-gtm-module';
import { GetRestaurantHoursRange } from '../../helpers/checkout';
import { CalendarTypeEnum } from '../../helpers/hoursListing';
import { addMultipleProductsRequest } from '../../redux/actions/basket/addMultipleProducts';
import { updateProductRequest } from '../../redux/actions/basket/product/update';
import { addUpsellsRequest } from '../../redux/actions/basket/upsell/Add';
import { getUpsellsRequest } from '../../redux/actions/basket/upsell/Get';
import { getCategoriesRequest } from '../../redux/actions/category';
import { saveLinkAddress, updateMenu } from '../../redux/actions/restaurant';
import OrderTime from '../order-time';
import { CouponNotApplied } from './cartdialog';
import { showPromoDialog } from '../../redux/actions/basket/checkout';

const useStyles = makeStyles((theme: Theme) => ({
  dimPanel: {
    position: 'fixed',
    background: 'rgba(0, 0, 0, 0.5)',
    top: 0,
    right: 0,
    width: '100%',
    height: '100vh',
    zIndex: 1101,
    [theme.breakpoints.down('xl')]: {
      display: 'block !important',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  cartBox: {
    border: '1px solid #666',
    borderTop: '0',
    borderRight: '0',
    position: 'fixed',
    background: '#fff',
    top: 0,
    right: 0,
    width: '100%',
    height: '100%',
    zIndex: 1101,
    [theme.breakpoints.up('md')]: {
      maxWidth: '375px',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '375px',
    },
    [theme.breakpoints.up('xs')]: {
      maxWidth: 'auto !important',
    },
  },
  cartRoot: {
    position: 'relative',
    padding: '16px 5px 10px 14px',
  },
  cartTitle: {
    color: theme.palette.secondary.main,
    textTransform: 'uppercase',
    fontSize: '30px !important',
    fontWeight: '700',
    fontFamily: "'GritSans-Bold' !important",
    lineHeight: '52px',
    padding: '8px 0px 14px 0px',
  },
  crossIcon: {
    position: 'absolute',
    right: '10px !important',
    display: 'flex !important',
    minWidth: '0px !important',
    paddingRight: '2px !important',
    justifyContent: 'right !important',
    '& img': {
      cursor: 'pointer',
    },
  },
  smallLink: {
    color: '#0075BF !important',
    fontSize: '11px !important',
    fontFamily: "'Sunborn-Sansone' !important",
    textDecoration: 'underline !important',
    display: 'inline',
    cursor: 'pointer',
    textTransform: 'uppercase',
    padding: '0px 30px 0px 0px !important',
  },
  disabledLink: {
    color: '#ccc !important',
    fontSize: '11px !important',
    fontFamily: "'Sunborn-Sansone' !important",
    display: 'inline',
    cursor: 'default',
    textDecoration: 'underline',
    textTransform: 'uppercase',
    padding: '0px 30px 0px 0px !important',
  },
  btnsList: {
    width: '100%',
    display: 'flex',
    listStyle: 'none',
    textDecoration: 'underline',
    height: '40px',
  },
  btn: {
    paddingLeft: '0px  !important',
    letterSpacing: 'normal !important',
    marginTop: '-5px !important',
  },
  emptyCart: {
    fontFamily: "'Librefranklin-Regular' !important",
    fontSize: '16px !important',
    color: '#525252',
    fontWeight: 'bold',
  },
  helpicon: {
    borderRadius: 20,
    width: 18,
    height: 18,
    border: '1px solid #2b4b62',
    textAlign: 'center',
    display: 'inline-flex',
    fontFamily: "'GritSans-Bold' !important",
    fontSize: 14,
    marginLeft: 5,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
}));

const sidesToShow = [
  'fresh guacamole & chips',
  'tortilla chips',
  'mexican street corn - large side',
];
const dessertsToShow = [
  'warm cinnamon churro',
  'gluten free honduran chocolate brownie',
  'chocolate chunk cookie',
];
const drinksToShow = [
  'large drink',
  'bottled drinks',
  'bottled drinks',
  'bottled drinks',
];

const Cart = ({ upsellsType, showCart, handleUpsells }: any) => {
  const classes = useStyles();
  const [restaurantHours, setRestaurantHours] = useState<any[]>();
  const theme = useTheme();
  const [actionStatus, setActionStatus] = useState(false);
  const [utensils, setUtensils] = useState(false);
  const [utensilsDisabled, setUtensilsDisabled] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [clickAction, setClickAction] = useState('');
  const [upsellsProductKeys, setUpsellsProductKeys] = useState<any[]>();
  const [products, setProducts] = useState<any[]>();
  const [showMore, setShowMore] = useState(false);
  const [productRemove, setProductRemove] = React.useState(false);
  const [runOnce, setRunOnce] = React.useState(true);
  const [loading, setLoading] = React.useState([]);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [pickupTimeView, setpickupTimeView] = React.useState(false);
  const [checkoutBtnDisabled, setCheckoutBtnDisabled] = React.useState(false);
  const [timeSlots, setTimeSlots] = React.useState<string[]>([]);

  const productRemoveObj = useSelector(
    (state: any) => state.removeProductReducer,
  );
  const productAddObj = useSelector((state: any) => state.addProductReducer);
  const utensilsReducer = useSelector((state: any) => state.utensilsReducer);
  const images = useSelector((state: any) => state.imagesReducer);

  const { restaurant, orderType } = useSelector(
    (state: any) => state.restaurantInfoReducer,
  );
  const basketObj = useSelector((state: any) => state.basketReducer);
  const addUpsellsObj = useSelector((state: any) => state.addUpsellReducer);
  const { upsells, vendorId: upsellsVendorId } = useSelector(
    (state: any) => state.getUpsellsReducer,
  );
  const { providerToken } = useSelector((state: any) => state.providerReducer);
  const orderTypeSelected = basketObj?.basket?.deliverymode || orderType || '';

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [basketType, setBasketType] = useState();
  const [sections, setSections]: any = useState([]);
  const [salsaUpsell, setSalsaUpsell]: any = useState([]);
  const [isRotated, setIsRotated] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [salsaCounter, setSalsaCounter]: any = useState([]);
  const [completeOrderCounter, setcompleteOrderCounter]: any = useState([]);
  const { categories } = useSelector((state: any) => state.categoryReducer);

  const [selectedOptions, setSelectedOptions]: any = useState({});
  const [sidesProducts, setSidesProducts] = useState([]);
  const [dessertsProducts, setDessertsProducts] = useState([]);
  const [drinksProducts, setDrinksProducts] = useState([]);
  const [upsellsCombination, setUpsellsCombination] = useState([]);
  const [keyByProducts, setKeyByProducts]: any = useState([]);
  const [couponDialogOpen, setCouponDialogOpen]: any = useState(false);
  const [removeProductId, setRemoveProductId]: any = useState();
  const [upSellCost, setUpSellCost]: any = useState();
  const [skeleton, setSkeleton]: any = useState<any>(true);

  const {
    availableSides = [],
    availableDrinks = [],
    availableDesserts = [],
  } = useMemo(() => {
    const { upsellsSides, upsellsDrinks, upsellsDesserts } =
      getUpsellsProducts(upsells);
    const sidesShouldInclude: any = [];
    upsellsSides?.forEach((item: any) => {
      if (sidesToShow.includes(item.name.toLowerCase())) {
        sidesShouldInclude.push(item);
      }
    });
    const drinksShouldInclude: any = [];
    upsellsDrinks?.forEach((item: any) => {
      if (drinksToShow.includes(item.name.toLowerCase())) {
        drinksShouldInclude.push(item);
      }
    });
    const dessertsShouldInclude: any = [];
    upsellsDesserts?.map((item: any) => {
      if (dessertsToShow.includes(item.name.toLowerCase())) {
        dessertsShouldInclude.push(item);
      }
    });

    return {
      availableSides: sidesShouldInclude || [],
      availableDrinks: drinksShouldInclude || [],
      availableDesserts: dessertsShouldInclude || [],
    };
  }, [upsells]);

  React.useEffect(() => {
    if (basketObj.calendar && basketObj.calendar.data) {
      const data = GetRestaurantHoursRange(
        basketObj.calendar.data,
        CalendarTypeEnum.business,
      );
      setRestaurantHours(data);
    }
  }, [basketObj.calendar]);

  useEffect(() => {
    const mappedSidesProducts: any = mapProducts(availableSides, 'sides');
    const mappedDessertsProducts: any = mapProducts(
      availableDesserts,
      'desserts',
    );
    const mappedDrinksProducts: any = mapProducts(availableDrinks, 'drinks');
    setSidesProducts(mappedSidesProducts);
    setDessertsProducts(mappedDessertsProducts);
    setDrinksProducts(mappedDrinksProducts);
  }, [availableDesserts, availableDrinks, availableSides]);

  const globalOptions: any = {};
  const mapProducts = (arr = [], type = '') => {
    if (arr.length > 0) {
      if (type === 'drinks') {
        const bottledDrinkItem = arr.filter(
          (item: any) => item.name === 'Bottled Drinks',
        )[0];
        arr.push(bottledDrinkItem);
        arr.push(bottledDrinkItem);
      }
      let prod = arr?.map((obj: any, index) => {
        const updatedProd = {
          ...obj,
          newId: index + 2100 + obj.id,
          quantity: 0,
          selected: false,
        };
        const { options = [], mandatory = false } = updatedProd || {};
        if (options?.length > 0) {
          if (
            !mandatory &&
            options?.findIndex((opt: any) => opt.id === 55555555555) === -1
          ) {
            const newOption = {
              adjustsparentcalories: false,
              adjustsparentprice: false,
              availability: {
                always: true,
                description: null,
                enddate: null,
                isdisabled: false,
                now: true,
                startdate: null,
              },
              basecalories: null,
              caloriesseparator: null,
              children: false,
              cost: 0,
              costoverridelabel: null,
              displayid: null,
              fields: null,
              id: 55555555555,
              isdefault: false,
              maxcalories: null,
              menuitemlabels: [],
              metadata: null,
              modifiers: null,
              name: 'As is',
            };
            options.unshift(newOption);
          }
          globalOptions[`${updatedProd.newId}`] = {
            optionId: `${updatedProd.options[0].id}`,
            cost: updatedProd.options[0].cost,
            name: updatedProd.options[0].name,
          };
        }
        return updatedProd;
      });
      if (type === 'drinks') {
        prod?.forEach((item, index) => {
          const { options = [] } = item || {};
          let requiredOptionName: any;
          if (index === 1) {
            requiredOptionName = 'Jarritos Mineragua';
          } else if (index === 2) {
            requiredOptionName = 'Jarritos Mandarin';
          } else if (index === 3) {
            requiredOptionName = 'Jarritos Pineapple';
          }
          if (requiredOptionName) {
            let requiredOptionIndex = -1;
            options?.forEach((item: any, index: any) => {
              if (item.name === requiredOptionName) {
                requiredOptionIndex = index;
              }
            });
            if (requiredOptionIndex > -1) {
              globalOptions[`${item.newId}`] = {
                optionId: `${item.options[requiredOptionIndex].id}`,
                cost: item.options[requiredOptionIndex].cost,
                name: item.options[requiredOptionIndex].name,
                image: setImageURLs(
                  item.options[requiredOptionIndex].chainoptionid,
                ),
              };
            }
          }
        });
      }
      setSelectedOptions(globalOptions);
      return prod;
    }
  };

  const setImageURLs = (id: any) => {
    let imageUrl = '';
    images.items[0]?.map((item: any) => {
      if (process.env.REACT_APP_NODE_ENV !== 'production') {
        if (item.sandbox_plu_names.includes(id?.toString())) {
          imageUrl = item.fimg_url;
        }
      } else {
        if (item?.production_plu_names?.includes(id?.toString())) {
          imageUrl = item.fimg_url;
        }
      }
    });
    return imageUrl;
  };

  useEffect(() => {
    if (
      runOnce &&
      basketObj?.basket?.products?.length > 0 &&
      categories?.categories
    ) {
      fireViewCartEvent(1);
      setRunOnce(false);
    }

    const productsData: any = [];
    categories?.categories?.forEach((categoryData: any) => {
      categoryData.products.forEach((product: any) => {
        productsData.push(product);
      });
    });
    if (productsData) setKeyByProducts(_.keyBy(productsData, 'id'));
  }, [basketObj?.basket?.products?.length > 0 && categories?.categories]);

  useEffect(() => {
    if (upsellsVendorId && upsellsVendorId !== restaurant?.id) {
      dispatch(getUpsellsRequest(restaurant?.id));
    } else if (!upsells && restaurant?.id) {
      dispatch(getUpsellsRequest(restaurant?.id));
    }
    if (restaurant && basketObj?.basket?.products.length > 0) {
      dispatch(getCategoriesRequest(restaurant.id));
    }
  }, []);

  useEffect(() => {
    if (upsells?.length) {
      const upsellsProductKeys: any = [];
      upsells?.forEach((upsell: any) => {
        const productIds = upsell?.products.map((prod: any) => prod.id);

        if (productIds?.length) {
          Array.prototype.push.apply(upsellsProductKeys, productIds);
        }
      });
      const qunatityKey = 'quantity';
      const qtyInitialValue = 0;
      setUpsellsProductKeys(upsellsProductKeys);
      setSalsaUpsell(
        _.find(upsells, (up) => up.type === UPSELLS_TYPES.SALSA)?.products?.map(
          (obj: any) => ({ ...obj, [qunatityKey]: qtyInitialValue }),
        ),
      );
      resetSalsaCounter();
      resetCounter();
    }
  }, [upsells]);

  const resetCounter = () => {
    setcompleteOrderCounter(
      _.map(_.range(3), (index: any) => {
        setUpSellCost(0);
        return { state: false, index: index };
      }),
    );
  };

  const resetSalsaCounter = () => {
    const data = _.map(
      _.range(
        _.find(upsells, (up) => up.type === UPSELLS_TYPES.SALSA)?.products
          .length,
      ),
      (index) => {
        return { state: false, index: index };
      },
    );
    setSalsaCounter(data);
  };

  const updateSalsaCount = (id: number, type: string, index?: any) => {
    let basketCount = 0;
    if (
      basketObj &&
      basketObj?.basket &&
      basketObj?.basket?.products &&
      basketObj?.basket?.products?.length
    ) {
      const filterProduct = basketObj?.basket?.products.filter(
        (obj: any) => obj.productId === id,
      );
      if (filterProduct && filterProduct.length) {
        filterProduct?.forEach((prod: any) => {
          basketCount += prod.quantity;
        });
      }
    }
    const updatedProducts = salsaUpsell.map((obj: any) => {
      if (obj.id === id) {
        const maximumBasketQuantity = _.isEmpty(obj.maximumbasketquantity)
          ? -1
          : parseInt(obj.maximumbasketquantity);
        const maximumQuantity = _.isEmpty(obj.maximumquantity)
          ? -1
          : parseInt(obj.maximumquantity);
        let limit = maximumBasketQuantity - basketCount;
        let count =
          type === 'PLUS'
            ? obj.quantity + 1
            : obj.quantity === 0
            ? 0
            : obj.quantity - 1;

        count =
          maximumQuantity === -1 || maximumBasketQuantity === -1
            ? count
            : count >= maximumQuantity
            ? maximumQuantity
            : count >= maximumBasketQuantity
            ? maximumBasketQuantity
            : count <= 0
            ? 0
            : count;

        if (index !== undefined && count == 0) {
          salsaSection(index);
        }

        showErrorMsg(
          obj,
          basketCount,
          count,
          type,
          maximumBasketQuantity,
          maximumQuantity,
        );

        if (count <= limit || limit < 0) {
          return {
            ...obj,
            quantity: count,
          };
        } else {
          return {
            ...obj,
          };
        }
      } else {
        return {
          ...obj,
        };
      }
    });
    if (
      index !== undefined &&
      type == 'MINUS' &&
      updatedProducts[index].quantity == 0
    ) {
      updatedProducts[index].quantity = 0;
      salsaSection(index, 0);
    }
    setSalsaUpsell(updatedProducts);
    fitContainer();
  };

  const showErrorMsg = (
    obj: any,
    basketCount: any,
    count: any,
    type: string,
    maximumBasketQuantity: any,
    maximumQuantity: any,
  ) => {
    if (
      maximumBasketQuantity !== -1 &&
      maximumBasketQuantity === basketCount &&
      type === 'PLUS'
    ) {
      setErrorMsg(
        `You may only order upto ${maximumBasketQuantity} ${
          obj.name ? obj.name : ''
        }`,
      );
    } else if (
      maximumQuantity !== -1 &&
      obj.quantity + 1 > maximumQuantity &&
      type === 'PLUS'
    ) {
      setErrorMsg(
        `You may only add upto ${maximumQuantity} ${
          obj.name ? obj.name : ''
        } at a time.`,
      );
    } else if (count !== obj.quantity) {
      setErrorMsg('');
    }
  };
  const addSalsaToBasket = () => {
    const finalSalsa: any = [];
    setIsRotated(!isRotated);
    if (salsaUpsell && salsaUpsell.length) {
      salsaUpsell?.forEach((salsaItem: any) => {
        if (salsaItem.quantity > 0) {
          let choices: any = [];
          const obj = {
            productid: salsaItem.id,
            quantity: salsaItem.quantity,
            choices: choices,
          };
          finalSalsa.push(obj);
        }
      });
      if (finalSalsa.length) {
        let payload: any = {};
        payload = {
          products: finalSalsa,
        };
        resetSalsaCounter();
        dispatch(addMultipleProductsRequest(basketObj?.basket.id, payload));
        const qunatityKey = 'quantity';
        const qtyInitialValue = 0;
        setSalsaUpsell(
          _.find(
            upsells,
            (up) => up.type === UPSELLS_TYPES.SALSA,
          )?.products?.map((obj: any) => ({
            ...obj,
            [qunatityKey]: qtyInitialValue,
          })),
        );
      }
    }
  };

  const fitContainer = () => {
    const elem = document.getElementById('cart-main-conatiner');
    const cartBox = document.getElementById('cart-box');

    if (elem && cartBox) {
      if (basketObj?.basket?.products?.length > 0) {
        elem.style.height = cartBox?.clientHeight - 172 + 'px';
      } else {
        elem.style.height = cartBox?.clientHeight - 100 + 'px';
      }
      elem.style.overflow = 'auto';
    }
  };
  window.addEventListener(
    'orientationchange',
    function () {
      fitContainer();
    },
    false,
  );

  window.addEventListener(
    'resize',
    function () {
      fitContainer();
    },
    false,
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    fitContainer();
    setBasketType(basketObj?.basketType || '');
  }, []);

  useEffect(() => {
    if (addUpsellsObj?.basket && clickAction != '') {
      setClickAction('');
      fitContainer();
    }
  }, [addUpsellsObj]);

  useEffect(() => {
    if (productRemoveObj?.basket && actionStatus) {
      displayToast('SUCCESS', '1 item removed from cart.');
      fitContainer();
      setActionStatus(false);
      navigate(`/menu/${restaurant.slug}?cart=true`);
    }
  }, [productRemoveObj]);

  useEffect(() => {
    if (basketObj?.basket?.products?.length) {
      const utensils = basketObj?.basket?.products.filter(
        (obj: any) => obj.productId === utensilsReducer.utensilsProductId,
      );

      if (utensils?.length) {
        setUtensils(true);
      } else {
        setUtensils(false);
      }
    } else {
      setUtensils(false);
    }
  }, [basketObj]);
  useEffect(() => {
    setTimeout(() => {
      setSkeleton(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (basketObj?.basket?.products?.length) {
      let array = basketObj?.basket?.products;
      const utensilsIndex = array.findIndex(
        (obj: any) => obj.productId === utensilsReducer.utensilsProductId,
      );
      if (utensilsIndex !== -1) {
        array.push(array.splice(utensilsIndex, 1)[0]);
      }
      setProducts(array);
    } else {
      setProducts([]);
    }
    setTimeout(() => {
      fitContainer();
    }, 500);
  }, [basketObj]);

  useEffect(() => {
    if (productAddObj && productAddObj.basket && actionStatus) {
      displayToast('SUCCESS', 'Duplicate item added to cart.');
      fitContainer();
      setActionStatus(false);
      navigate(`/menu/${restaurant.slug}?cart=true`);
    }
  }, [productAddObj]);
  const removeProductHandle = (item: any) => {
    setActionStatus(true);
    const request: any = {};
    request.productid = item?.productId;
    request.quantity = item.quantity + 1;

    let options = '';
    item.choices.map((it: any) => {
      options = options + it.optionid + ',';
    });
    request.options = options;
    setRemoveProductId([item.id, options, item.quantity, item?.productId]);
    dispatch(removeProductRequest(basketObj?.basket.id, item.id));
    setProductRemove(true);
  };
  const updateProductQuantity = (item: any, type: any, index?: any) => {
    setLoading((prevLoadingStates) => {
      const updatedStates: any = [...prevLoadingStates];
      updatedStates[index] = true;
      return updatedStates;
    });
    const request: any = {};
    request.productid = item?.productId;
    if (type === 'minus') {
      request.quantity = item.quantity - 1;
    } else {
      request.quantity = item.quantity + 1;
    }

    let options = '';
    item.choices.map((it: any) => {
      options = options + it.optionid + ',';
    });
    request.options = options;

    dispatch(
      updateProductRequest(
        basketObj?.basket.id || '',
        parseInt(item.id, 10),
        request,
      ),
    );
    if (type === 'minus') {
      setProductRemove(true);
      setRemoveProductId([item.id, options, item?.quantity, item?.productId]);
    }
    setTimeout(() => {
      setLoading((prevLoadingStates) => {
        const updatedStates: any = [...prevLoadingStates];
        updatedStates[index] = false;
        return updatedStates;
      });
    }, 1500);
  };

  const toggleSection = (index: any) => {
    setSections((prevSections: any) => {
      const existingSection = prevSections.find(
        (section: any) => section.index === index,
      );
      if (existingSection) {
        return prevSections.map((section: any) =>
          section.index === index
            ? { ...section, isOpen: !section.isOpen }
            : section,
        );
      } else {
        return [...prevSections, { index, isOpen: true }];
      }
    });
  };

  const salsaSection = (index: any, qty?: any) => {
    setSalsaCounter((prevSections: any) => {
      const existingSection = prevSections.find(
        (section: any) => section.index === index,
      );
      if (existingSection) {
        return prevSections.map((section: any) =>
          section.index === index
            ? { ...section, state: qty === 0 ? false : !section.state }
            : section,
        );
      } else {
        return [...prevSections, { index, state: true }];
      }
    });
  };

  const completeOrderSelection = (index: number) => {
    setcompleteOrderCounter(
      (prevSections: { index: number; state: boolean }[]) => {
        const existingSection = prevSections.find(
          (section: any) => section.index === index,
        );
        if (existingSection) {
          return prevSections.map((section: any) =>
            section.index === index
              ? { ...section, state: !section.state }
              : section,
          );
        } else {
          return [...prevSections, { index, state: true }];
        }
      },
    );
  };

  const handleQtyChange = (item: any, type: string, index?: any) => {
    const { id, newId } = item || {};
    let itemsInBasket = 0;
    if (basketObj?.basket && basketObj?.basket?.products?.length) {
      const filterProduct = basketObj?.basket?.products.filter(
        (obj: any) => obj.productId === id,
      );
      if (filterProduct && filterProduct.length > 0) {
        filterProduct?.forEach((prod: any) => {
          itemsInBasket += prod.quantity;
        });
      }
    }
    const isDrinkItem = item?.name.toLowerCase().includes('drink');

    const maximumBasketQuantity = _.isEmpty(item.maximumbasketquantity)
      ? -1
      : parseInt(item.maximumbasketquantity, 10);
    const maximumQuantity = _.isEmpty(item.maximumquantity)
      ? -1
      : parseInt(item.maximumquantity, 10);
    let limit = isDrinkItem ? 6 : maximumBasketQuantity - itemsInBasket;

    let count;
    if (type === 'plus') {
      count = item.quantity + 1;
    } else {
      if (item.quantity === 0) {
        count = 0;
      } else {
        if (item.quantity === 1) {
          completeOrderSelection(index);
        }
        count = item.quantity - 1;
      }
    }
    count =
      maximumQuantity === -1 || maximumBasketQuantity === -1
        ? count
        : count >= maximumQuantity
        ? maximumQuantity
        : count >= maximumBasketQuantity
        ? maximumBasketQuantity
        : count <= 0
        ? 0
        : count;

    if (count <= limit || limit < 0) {
      item = {
        ...item,
        quantity: count,
      };
      const updatedProducts: any = upsellsCombination.map((obj: any) => {
        if (obj?.newId === newId) {
          return { ...item };
        } else {
          return {
            ...obj,
          };
        }
      });

      setUpSellCost(
        ((selectedOptions?.[item?.newId]?.cost &&
          selectedOptions?.[item?.newId]?.cost) ||
          item.cost) * count,
      );
      setUpsellsCombination(updatedProducts);
      debounceAddToBag(item);
    }
  };

  const addToBag = (item: any) => {
    const isDrinkItem = item?.name?.toLowerCase().includes('drink');

    if (item.quantity > 0) {
      let choices: any = [];
      let options = '';
      if (
        selectedOptions[item.newId]?.optionId &&
        selectedOptions[item?.newId].optionId !== '55555555555'
      ) {
        choices = [
          {
            choiceid: selectedOptions[item.newId].optionId,
          },
        ];
        options = `${selectedOptions[item.newId].optionId}`;
      }
      const obj: any = {
        productid: item.id,
        quantity: item.quantity,
      };
      if (isDrinkItem) {
        obj.options = options;
        dispatch(addUpsellsRequest(basketObj?.basket.id, obj));
      } else {
        obj.choices = choices;
        const payload = {
          products: [obj],
        };
        dispatch(addMultipleProductsRequest(basketObj?.basket?.id, payload));
      }
    }
    resetCounter();
  };

  const debounceAddToBag = useCallback(debounce(addToBag, 2000), [
    selectedOptions,
  ]);

  const handleClick = () => {
    setIsRotated(!isRotated);
  };

  const duplicateProductHandle = (detail: any) => {
    const foundItem = _.find(basketObj?.basket?.products, { id: detail?.[0] });

    const request: any = {};
    request.productid = detail?.[3];
    request.quantity = detail?.[2];
    request.options = detail?.[1];
    if (foundItem?.id) {
      dispatch(
        updateProductRequest(
          basketObj?.basket.id || '',
          parseInt(detail?.[0], 10),
          request,
        ),
      );
    } else {
      dispatch(addProductRequest(basketObj?.basket.id, request));
    }
  };

  const getOptions = (options: any) => {
    let val = '';
    options.map((item: any) => {
      val = val + ' ' + item.name.trim() + ',';
    });
    return val.trim().replace(/,*$/, '');
  };

  const checkItemIsUpsells = (id: number) => {
    let aval = false;

    if (upsellsProductKeys?.length) {
      aval = upsellsProductKeys.includes(id);
    }
    return aval;
  };

  const checkItemIsSalsaUpsells = (id: number) => {
    let aval = false;

    if (upsellsProductKeys?.length) {
      aval = upsellsProductKeys.includes(id);
    }
    return aval;
  };

  useEffect(() => {
    const basketProducts = basketObj?.basket?.products;
    if (basketProducts?.length === 0) {
      dispatch(resetBasketRequest());
    }
  }, [basketObj]);

  useEffect(() => {
    const modal = document.querySelector('#cart-box'); // select the modal by it's id
    if (modal) {
      const focusableContent = modal.querySelectorAll('[tabindex="0"]');
      const firstFocusableElement = focusableContent[0]; // get first element to be focused inside modal

      const lastFocusableElement =
        focusableContent[focusableContent.length - 1]; // get last element to be focused inside modal

      document.addEventListener('keydown', function (e) {
        let isTabPressed = e.key === 'Tab' || e.keyCode === 9;

        if (!isTabPressed) {
          return;
        }

        if (e.shiftKey) {
          // if shift key pressed for shift + tab combination
          if (document.activeElement === firstFocusableElement) {
            // add focus for the last focusable element
            lastFocusableElement &&
              (lastFocusableElement as HTMLElement)?.focus();
            e.preventDefault();
          }
        } else {
          // if tab key is pressed
          if (document.activeElement === lastFocusableElement) {
            // if focused has reached to last focusable element then focus first focusable element after pressing tab
            firstFocusableElement &&
              (firstFocusableElement as HTMLElement)?.focus(); // add focus for the first focusable element
            e.preventDefault();
          }
        }
      });

      firstFocusableElement && (firstFocusableElement as HTMLElement)?.focus();
    }
  }, []);

  const getRemainingUpsellsItems = useCallback(() => {
    const basketProducts = basketObj?.basket?.products;
    const allProducts = [
      dessertsProducts[0],
      ...sidesProducts,
      ...drinksProducts,
    ];
    const basketBottledDrinks = basketProducts.filter(
      (item: any) => item.name === 'Bottled Drinks',
    );
    const choicesMadeAlready = basketBottledDrinks.map(
      (item: any) => item?.choices?.[0]?.name,
    );
    const remainingProducts: any = [];
    allProducts?.forEach((item: any) => {
      const isDrinkItem = item?.name === 'Bottled Drinks';
      const { name = '' } = selectedOptions?.[item?.newId] || '';
      const basketItem = basketProducts.filter(
        (p: any) => p.productId === item?.id,
      )[0];
      const doesSameChoiceExists = choicesMadeAlready.includes(name);
      if (!basketItem || (isDrinkItem && !doesSameChoiceExists)) {
        remainingProducts.push(item);
      }
    });
    return remainingProducts;
  }, [
    basketObj,
    dessertsProducts,
    drinksProducts,
    selectedOptions,
    sidesProducts,
  ]);

  useEffect(() => {
    let items = [];
    const basketProducts = basketObj?.basket?.products;
    if (
      sidesProducts?.length > 0 &&
      drinksProducts?.length > 0 &&
      dessertsProducts?.length > 0
    ) {
      const { sides, desserts, drinks } = getUpsellsStatsFromOrder(
        basketProducts,
        upsells,
      );
      let drinksFirstIndex =
        basketObj?.basket?.deliverymode === 'dispatch' ? 1 : 0;
      if (!desserts && !drinks && !sides) {
        items = [
          dessertsProducts?.[0],
          sidesProducts?.[0],
          drinksProducts[drinksFirstIndex],
        ];
      } else if (drinks && !desserts && !sides) {
        items = [dessertsProducts?.[0], sidesProducts?.[0], sidesProducts?.[2]];
      } else if (drinks && sides && !desserts) {
        items = [...dessertsProducts];
      } else if (drinks && desserts && !sides) {
        items = [...sidesProducts];
      } else if (sides && !drinks && !desserts) {
        items = [
          dessertsProducts?.[0],
          ...drinksProducts.slice(drinksFirstIndex, 2 + drinksFirstIndex),
        ];
      } else if (sides && desserts && !drinks) {
        items = drinksProducts.slice(drinksFirstIndex, 3 + drinksFirstIndex);
      } else if (desserts && !drinks && !sides) {
        items = [
          sidesProducts?.[0],
          sidesProducts?.[2],
          drinksProducts?.[drinksFirstIndex],
        ];
      } else {
        //If all category upsells are in basket
        const remainingItems = getRemainingUpsellsItems();
        items = remainingItems.slice(0, 3);
      }
      if (items?.length < 3) {
        const remainingItems = getRemainingUpsellsItems();
        if (remainingItems.length <= 3) {
          items = remainingItems;
        } else {
          items.forEach((upsellItem: any) => {
            if (items?.length < 3) {
              const newItem = remainingItems?.filter(
                (rem: any) => rem.newId !== upsellItem.newId,
              )[0];
              if (newItem) {
                items.push(newItem);
              }
            }
          });
        }
      }
    }
    setUpsellsCombination(items);
  }, [
    basketObj,
    dessertsProducts,
    drinksProducts,
    getRemainingUpsellsItems,
    sidesProducts,
    upsells,
  ]);

  const addRemoveUtensils = (e: any) => {
    if (e.target.checked) {
      const request: any = {};
      request.productid = utensilsReducer.utensilsProductId;
      request.quantity = 1;
      request.options = '';
      setUtensilsDisabled(true);
      dispatch(addUtensilsRequest(basketObj?.basket.id, request));
    } else {
      if (basketObj?.basket?.products?.length) {
        const utensilsAllProducts = basketObj?.basket?.products.filter(
          (obj: any) => obj.productId === utensilsReducer.utensilsProductId,
        );
        if (utensilsAllProducts?.length) {
          setUtensilsDisabled(true);
          dispatch(
            removeUtensilsRequest(
              basketObj?.basket.id,
              utensilsAllProducts[0].id,
            ),
          );
        }
      }
    }
  };

  const fireViewCartEvent = (stepValue: number) => {
    const productCategoryMap = categories?.categories?.reduce(
      (map: any, category: any) => {
        for (const product of category?.products) {
          map[product.id] = { id: category.id, name: category.name };
        }
        return map;
      },
      {},
    );

    const productItems = basketObj?.basket?.products;

    if (productItems?.length) {
      let actionMap: { step: number; list?: String } = { step: stepValue };

      if (stepValue == 1) {
        actionMap.list = 'CART';
      }
      const tagManagerArgs: any = {
        dataLayer: {
          event: 'eec.checkout',
          ecommerce: {
            checkout: {
              actionField: actionMap,
            },
            products: productItems.map((pItem: any) => ({
              id: pItem?.productId,
              name: pItem?.name,
              category: productCategoryMap[pItem?.productId]?.name,
              quantity: pItem?.quantity,
              location: basketObj?.basket?.vendorid,
            })),
          },
        },
      };
      TagManager.dataLayer(tagManagerArgs);
    }
  };

  const triggerFacebookEventOnCheckout = () => {
    let userObj: any = null;
    if (isLoginUser()) {
      userObj = {
        first_name: providerToken?.first_name || '',
        last_name: providerToken?.last_name || '',
        email: providerToken?.email || '',
        phone: providerToken?.phone || '',
      };
    }
    dispatch(
      facebookSendEvent(
        facebookConversionTypes.FACEBOOK_INITIATE_CHECKOUT_EVENT,
        userObj,
        null,
      ),
    );
    setTimeout(() => {
      navigate('/checkout');
    }, 1000);
  };

  const checkOrderType = () => {
    if (orderTypeSelected === 'dispatch') return 'Delivery Order';
    if (orderTypeSelected === 'dinein') return 'Dine In At';
    if (orderTypeSelected === 'pickup' || orderTypeSelected === 'curbside')
      return 'Pick Up In Restaurant';
  };
  useEffect(() => {
    const couponCodeDialog = async () => {
      if (
        productRemove &&
        basketObj?.basket?.coupon &&
        basketObj?.basket?.coupon?.couponcode &&
        basketObj?.basket?.coupondiscount === 0
      ) {
        try {
          const response: any = await validateBasket(
            basketObj?.basket?.id + '',
          );
          if (response) {
          }
        } catch (error: any) {
          setCouponDialogOpen(true);
          displayToast(
            'ERROR',
            error?.response?.data?.message
              ? error.response.data.message
              : 'ERROR! Please Try again later',
          );
        }
      }
    };
    couponCodeDialog();
  }, [productRemove === true && basketObj?.basket?.coupondiscount === 0]);

  const asapTime = useMemo(() => {
    return (
      'ASAP ' +
      moment(
        timeSlots[0] || basketObj?.basket?.earliestreadytime,
        'YYYYMMDD HH:mm',
      ).format('h:mm A')
      
    );
  }, [
    basketObj?.basket?.earliestreadytime,
    basketObj?.basket?.leadtimeestimateminutes,
    restaurantHours,
  ]);

  return (
    <>
      {couponDialogOpen && (
        <CouponNotApplied
          setProductRemove={setProductRemove}
          setCouponDialogOpen={setCouponDialogOpen}
          couponDialogOpen={couponDialogOpen}
          removeProductId={removeProductId}
          duplicateProductHandle={duplicateProductHandle}
        />
      )}
      <div className={classes.dimPanel} onClick={showCart}></div>
      <Box
        className={classes.cartBox}
        id="cart-box"
        aria-label="view your cart"
        role="dialog"
        aria-modal="true"
        style={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Grid
          container
          className="scrollBar"
          spacing={0}
          aria-label="view your cart"
          role="dialog"
          aria-modal="true"
          style={{ overflowY: 'scroll' }}
        >
          {(basketObj?.basket?.products?.length == 0 ||
            basketObj?.basket == null) && (
            <>
              <Grid
                item
                className="my-bag-header"
                xs={12}
                sx={{
                  padding: '0px !important',
                  display: 'flex',
                  justifyContent: {
                    xs: 'flex-start',
                    sm: 'space-between !important',
                  },
                  boxShadow: '0px 0px 13px 0px rgba(161, 161, 161, 0.30)',
                  gap: '10px',
                  position: 'relative',
                }}
              >
                <Button
                  className={classes.crossIcon}
                  onClick={showCart}
                  sx={{
                    display: {
                      xs: 'flex !important',
                      md: 'none !important',
                      sm: 'none !important',
                      lg: 'none !important',
                    },
                    top: { xs: '25px !important', sm: '37px !important' },
                    left: '20px',
                    right: '3px',
                    backgroundColor: 'transparent',
                    transition: 'backgroundColor 0.3s ease',
                  }}
                >
                  <img
                    src={backIcon}
                    style={{
                      width: '24px',
                      height: '24px',
                    }}
                  />
                </Button>
                <Typography
                  variant="h6"
                  component="h6"
                  className={classes.cartTitle}
                  sx={{
                    padding: {
                      xs: '25px 0px 25px 25px',
                      sm: '35px 25px 19px 15px',
                    },
                  }}
                  title="Your Order"
                >
                  MY BAG
                </Typography>
                <Box
                  className={classes.crossIcon}
                  aria-label="Close Cart"
                  onClick={showCart}
                  sx={{
                    top: { xs: '30px !important', sm: '50px !important' },
                    backgroundColor: 'transparent',
                    visibility: { xs: 'hidden', sm: 'visible' },
                  }}
                >
                  <img
                    src={ChangedCrossIcon}
                    style={{
                      fontSize: '24px',
                      width: '24px',
                      height: '24px',
                    }}
                  />
                </Box>
              </Grid>
              <Grid
                id="cart-main-conatiner"
                item
                xs={12}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  height: 'auto !important',
                  paddingTop: '100px',
                }}
              >
                <Box component="img" src={MyBagCartImage} />
                <Box
                  sx={{
                    padding: '24px 0px 40px 0px',
                    textAlign: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '12px !important',
                      fontWeight: '400 !important',
                      lineHeight: '133.3%',
                      fontFamily: 'Librefranklin-Regular !important',
                      color: 'rgba(5, 44, 66, 0.50) ',
                    }}
                  >
                    Your bag is currently empty.
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '12px !important',
                      fontWeight: '400 !important',
                      lineHeight: '133.3%',
                      fontFamily: 'Librefranklin-Regular !important',
                      color: 'rgba(5, 44, 66, 0.50) ',
                    }}
                  >
                    Order now and enjoy a taste of Baja!
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  title="Add Another Menu Item"
                  sx={{
                    width: '230px',
                    margin: '0px',
                    borderRadius: '23px !important ',
                    fontSize: '18px !important',
                    fontWeight: '700 !important',
                    lineHeight: '122.22%',
                    fontFamily: 'GritSans-Bold !important',
                    letterSpacing: '.15px !important',
                    height: '44px ',
                  }}
                  onClick={() => {
                    showCart();
                    if (basketObj.showPromoMessage){
                      dispatch(showPromoDialog(true));
                      }
                    return false;
                  }}
                  onKeyUp={(e) => {
                    if (e.keyCode === 13) {
                      showCart();
                      return false;
                    }
                  }}
                >
                  Order Now
                </Button>
              </Grid>
            </>
          )}
          {basketObj?.basket?.products?.length > 0 && (
            <Grid
              item
              className="my-bag-header"
              xs={12}
              sx={{
                padding: '0px !important',
                display: 'flex',
                justifyContent: {
                  xs: 'flex-start',
                  sm: 'space-between !important',
                },
                boxShadow: '0px 0px 13px 0px rgba(161, 161, 161, 0.30)',
                gap: '10px',
                position: 'relative',
              }}
            >
              <Button
                className={classes.crossIcon}
                onClick={showCart}
                sx={{
                  display: {
                    xs: 'flex !important',
                    md: 'none !important',
                    sm: 'none !important',
                    lg: 'none !important',
                  },
                  top: { xs: '25px !important', sm: '37px !important' },
                  left: '20px',
                  right: '3px',
                  backgroundColor: 'transparent',
                  transition: 'backgroundColor 0.3s ease',
                }}
              >
                <img
                  src={backIcon}
                  style={{
                    width: '24px',
                    height: '24px',
                  }}
                />
              </Button>
              <Typography
                variant="h6"
                component="h6"
                className={classes.cartTitle}
                sx={{
                  padding: {
                    xs: '25px 0px 25px 25px',
                    sm: '35px 25px 19px 15px',
                  },
                }}
                title="Your Order"
              >
                MY BAG
              </Typography>
              <Box
                className={classes.crossIcon}
                aria-label="Close Cart"
                onClick={showCart}
                sx={{
                  top: { xs: '30px !important', sm: '50px !important' },
                  backgroundColor: 'transparent',
                  visibility: { xs: 'hidden', sm: 'visible' },
                }}
              >
                <img
                  src={ChangedCrossIcon}
                  style={{
                    fontSize: '24px',
                    width: '24px',
                    height: '24px',
                  }}
                />
              </Box>
            </Grid>
          )}
          {basketObj?.basket?.products?.length > 0 && (
            <>
              <Box
                sx={{
                  width: '92%',
                  marginLeft: '15px',
                }}
              >
                <Grid
                  style={{
                    boxShadow: '0px 0px 13px 0px rgba(161, 161, 161, 0.30) ',
                    borderRadius: '10px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginTop: '10px',
                    padding: '15px 5px 10px 16px',
                  }}
                >
                  <Grid item xs={2}>
                    <img
                      src={pickupIcon}
                      style={{
                        width: '37px',
                        height: '37px',
                      }}
                    />
                  </Grid>
                  <Grid item xs={7}>
                    <Typography
                      variant="body1"
                      style={{
                        color: '#052C42',
                        fontFamily: "'Librefranklin-Regular' !important",
                        fontSize: '14px',
                        lineHeight: '20px',
                        fontWeight: '600',
                        letterSpacing: '0.15px !important',
                      }}
                    >
                      {checkOrderType()}
                    </Typography>
                    <Typography
                      style={{
                        color: '#052C42',
                        fontFamily: "'Librefranklin-Regular' !important",
                        fontSize: '12px',
                        lineHeight: '12px',
                        width: '90%',
                        fontWeight: '400',
                        padding: '3px 0px',
                        letterSpacing: '0.15px !important',
                      }}
                    >
                      {(basketObj?.basket?.deliverymode || orderType) ===
                        'dispatch' && (
                        <span>
                          {basketObj?.basket?.deliveryaddress?.streetaddress}
                          ,&nbsp;
                          {basketObj?.basket?.deliveryaddress?.building}
                          {basketObj?.basket?.deliveryaddress?.building !==
                            '' && <br />}
                          {basketObj?.basket?.deliveryaddress?.city},&nbsp;
                          {basketObj?.basket?.deliveryaddress?.zipcode}
                        </span>
                      )}
                      {(basketObj?.basket?.deliverymode || orderType) ===
                        'pickup' && (
                        <span>
                          {restaurant?.streetaddress} {restaurant?.city},{' '}
                          {restaurant?.state}
                        </span>
                      )}
                    </Typography>
                  </Grid>
                  <Grid item style={{ height: '40px' }}>
                    <Divider
                      orientation="vertical"
                      sx={{
                        margin: '2px 0px',
                        width: '1px',
                        stroke: '#052C423B',
                      }}
                    />
                  </Grid>
                  <Grid item xs={2.8}>
                    <Typography
                      variant="body1"
                      onClick={() => {
                        navigate('/location');
                        showCart();
                        dispatch(saveLinkAddress('cart'));
                      }}
                      style={{
                        color: '#3D50DF',
                        fontFamily: "'Librefranklin-Regular' !important",
                        fontSize: '12px',
                        lineHeight: '17.5px',
                        fontWeight: 600,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                    >
                      {'Change'}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              <Box sx={{ width: '92%', marginTop: '2px', marginLeft: '15px' }}>
                <Grid
                  style={{
                    boxShadow: '0px 0px 13px 0px rgba(161, 161, 161, 0.30) ',
                    borderRadius: '10px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginTop: '10px',
                    padding: '15px 5px 10px 16px',
                  }}
                >
                  <Grid item xs={2}>
                    <img
                      alt=""
                      src={statusIcon}
                      style={{
                        width: '37px',
                        height: '37px',
                      }}
                    />
                  </Grid>
                  <Grid item xs={7}>
                    <Typography
                      variant="body1"
                      style={{
                        color: '#052C42',
                        fontFamily: "'Librefranklin-Regular' !important",
                        fontSize: '14px',
                        lineHeight: '20px',
                        fontWeight: '600',
                      }}
                    >
                      {basketObj?.basket?.timewanted == null
                        ? 'Prepare Now'
                        : 'Schedule For'}
                    </Typography>
                    {skeleton ? (
                      <Skeleton
                        sx={{
                          width: '92%',
                          height: '30px',
                          marginTop: '-6px',
                          marginBottom: '-5px',
                        }}
                      />
                    ) : (
                      <Typography
                        variant="body1"
                        style={{
                          color: '#052C42',
                          fontFamily: "'Librefranklin-Regular' !important",
                          fontSize: '12px',
                          lineHeight: '16px',
                          fontWeight: '400',
                        }}
                      >
                        Ready{' '}
                        {(basketObj?.basket?.timewanted !== null &&
                          moment(
                            basketObj?.basket?.timewanted,
                            'YYYYMMDD HH:mm',
                          ).format('dddd h:mm A')) ||
                          asapTime}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item style={{ height: '40px' }}>
                    <Divider
                      orientation="vertical"
                      sx={{
                        margin: '2px 0px',
                        width: '1px',
                        stroke: '#052C423B',
                        color: '#052C423B',
                      }}
                    />
                  </Grid>
                  <Grid item xs={2.8}>
                    <Typography
                      variant="body1"
                      onClick={() => {
                        setpickupTimeView(!pickupTimeView);
                        setOpen(!open);
                      }}
                      style={{
                        color: '#3D50DF',
                        fontFamily: "'Librefranklin-Regular' !important",
                        fontSize: '12px',
                        lineHeight: '17.5px',
                        fontWeight: 600,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                    >
                      {(!isMobile && pickupTimeView && (
                        <Box
                          component="img"
                          src={ArrowUpIcon}
                          sx={{ width: '24px', height: '24px' }}
                        />
                      )) ||
                        'Schedule'}
                    </Typography>
                  </Grid>
                </Grid>
                {pickupTimeView === false && (
                  <hr
                    style={{
                      marginTop: '16px',
                      height: '1px',
                      backgroundColor: '#052c423a',
                      border: 'none',
                    }}
                  />
                )}
              </Box>
            </>
          )}

          {
            <Box
              sx={{
                margin: 'auto',
                width: '92%',
              }}
            >
              {basketObj?.basket?.products?.length > 0 && (
                <OrderTime
                  orderType={orderTypeSelected || ''}
                  setpickupTimeView={setpickupTimeView}
                  pickupTimeView={pickupTimeView}
                  setTimeSlots={setTimeSlots}
                  timeSlots={timeSlots}
                />
              )}
            </Box>
          }

          {basketObj?.basket?.products?.length > 0 && (
            <Box
              sx={{
                margin: 'auto',
                width: '92%',
              }}
            >
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  sx={{
                    color: '#006DB7',
                    fontFamily: "'GritSans-Bold' !important",
                    lineHeight: '22px',
                    fontWeight: 700,
                    fontSize: '16px',
                    padding: '16px 0px',
                    cursor: 'pointer',
                    textTransform: 'uppercase',
                  }}
                >
                  <Link
                    to={restaurant ? '/menu/' + restaurant.slug : '/'}
                    style={{
                      color: '#006DB7',
                    }}
                    title="Add More Menu Item"
                    onClick={() => {
                      showCart();
                      if (basketObj.showPromoMessage){
                      dispatch(showPromoDialog(true));
                      }
                      dispatch(updateMenu(true));
                      // navigate(restaurant ? '/menu/' + restaurant.slug : '/');
                    }}
                  >
                    + Add More Menu Items
                  </Link>
                </Typography>
              </Grid>
              <hr
                style={{
                  height: '1px' /* Set the height of the line */,
                  backgroundColor: '#052c423a' /* Set the color of the line */,
                  border: 'none',
                }}
              />
            </Box>
          )}

          {products && products?.length > 0 && (
            <Grid
              container
              className={classes.cartRoot}
              xs={12}
              sx={{ paddingRight: '25px' }}
            >
              {products.map((item: any, index: number) => (
                <Grid
                  key={Math.random() + index}
                  item
                  xs={12}
                  sx={{ padding: '0px' }}
                >
                  <Grid container spacing={0}>
                    {products.length > 0 && item.name !== 'Utensils' && (
                      <Grid item xs={12} sx={{ textAlign: 'left' }}>
                        {index == 0 && (
                          <Typography
                            sx={{
                              fontSize: '14px',
                              color: '#052C42',
                              fontFamily: "'Librefranklin-SemiBold' !important",
                              fontWeight: '600',
                              lineHeight: '16px',
                              paddingBottom: '16px',
                            }}
                          >
                            My Order
                          </Typography>
                        )}
                      </Grid>
                    )}
                    {item.productId !== utensilsReducer.utensilsProductId && (
                      <Grid
                        container
                        xs={12}
                        sx={{
                          paddingTop: (index !== 0 && '16px') || '0px',
                        }}
                      >
                        <Grid item xs={3}>
                          <img
                            aria-hidden="true"
                            style={{
                              width: '75px',
                              height: '75px',
                              borderRadius: '15px',
                              objectFit: 'cover',
                            }}
                            alt=""
                            src={
                              (keyByProducts[item.productId] &&
                                categories?.imagepath +
                                  keyByProducts[item.productId]
                                    .imagefilename) ||
                              require('../../assets/imgs/No ingredient.png')
                            }
                            onError={(e: any) => {
                              e.target.src = require('../../assets/imgs/No ingredient.png');
                            }}
                            className="item-image"
                          />
                        </Grid>

                        <Grid item xs={9}>
                          <Typography
                            title={item.name}
                            sx={{
                              fontSize: '14px',
                              color: '#052C42',
                              fontFamily: "'Librefranklin-Bold' !important",
                              lineHeight: '16px',
                              textAlign: 'left',
                              paddingTop: (index !== 0 && '16px') || '8px',
                            }}
                            style={{ fontSize: '12px', fontWeight: '600' }}
                          >
                            {item.productId !==
                            utensilsReducer.utensilsProductId
                              ? item.name.toString()
                              : item.name.toString()}
                          </Typography>
                          {item.choices.length > 0 && (
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyItems: 'center',
                                position: 'relative',
                              }}
                            >
                              <Typography
                                sx={{
                                  marginTop: '7px',
                                  color: '#052C42',
                                  fontFamily:
                                    "'Librefranklin-Regular' !important",
                                  lineHeight: '16px',
                                  textAlign: 'left',
                                }}
                                style={{ fontSize: '12px', fontWeight: '400' }}
                              >
                                {_.keyBy(sections, 'index')[index]?.isOpen
                                  ? 'See Less'
                                  : 'See More'}
                              </Typography>
                              <img
                                aria-hidden="true"
                                style={{
                                  width: '24px',
                                  height: '24px',
                                  position: 'inherit',
                                  cursor: 'pointer',
                                  top: '4px',
                                }}
                                alt=""
                                onClick={() => toggleSection(index)}
                                src={
                                  _.keyBy(sections, 'index')[index]?.isOpen
                                    ? seeLessIcon
                                    : seeMoreIcon
                                }
                              />
                            </Box>
                          )}
                          {_.keyBy(sections, 'index')[index]?.isOpen && (
                            <Grid item xs={12}>
                              <Typography
                                title={getOptions(item.choices)}
                                variant="caption"
                                sx={{
                                  paddingBottom: '11px',
                                  marginTop: '12px',
                                  display: 'block',
                                  fontFamily:
                                    "'Librefranklin-Regular' !important",
                                }}
                                style={{
                                  fontWeight: '400',
                                  fontSize: '12px',
                                  lineHeight: '16px',
                                  color: ' rgba(5, 44, 66, 0.50)',
                                }}
                              >
                                {getOptions(item.choices)}
                              </Typography>
                            </Grid>
                          )}

                          <Box
                            sx={{
                              display: 'flex',
                              height: '30px',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <Grid
                              container
                              xs={4}
                              sx={{ position: 'relative' }}
                            >
                              <Grid item xs={1}>
                                <Box
                                  component="img"
                                  sx={{
                                    width: item.quantity > 1 ? '12px' : '15px',
                                    height: item.quantity > 1 ? '25px' : '25px',
                                    cursor: 'pointer',
                                    position: 'relative',
                                    paddingLeft:
                                      item.quantity > 1 ? '2px' : '0px',
                                    top: { xs: '1px', sm: '2px' },
                                  }}
                                  src={
                                    item.quantity === 1 ? deleteIcon : minusIcon
                                  }
                                  onClick={() => {
                                    if (Number(item.quantity) === 1) {
                                      removeProductHandle(item);
                                    } else {
                                      updateProductQuantity(
                                        item,
                                        'minus',
                                        index,
                                      );
                                    }
                                    setClickAction(item.id + '-remove');
                                  }}
                                />
                              </Grid>

                              <Grid
                                item
                                style={{
                                  borderRight: '2px solid #ddd',
                                  paddingLeft: '9px',
                                  margin: '8px',
                                }}
                              />
                              <Grid item xs={1}>
                                <Typography
                                  sx={{
                                    marginTop: '6px',
                                    color: '#052C42',
                                    fontFamily:
                                      "'Librefranklin-Regular' !important",
                                    lineHeight: '16px',
                                  }}
                                  style={{
                                    marginLeft: '3px',
                                    fontSize: '15px',
                                    fontWeight: '600',
                                  }}
                                >
                                  {loading[index] ? (
                                    <CircularProgress
                                      size={14}
                                      color="inherit"
                                    />
                                  ) : (
                                    item.quantity.toString()
                                  )}
                                </Typography>
                              </Grid>

                              <Grid
                                item
                                style={{
                                  borderRight: '2px solid #ddd',
                                  paddingLeft: '8px',
                                  margin: '8px',
                                }}
                              />
                              <Grid item xs={1}>
                                <Box
                                  component="img"
                                  sx={{
                                    width: '12px',
                                    height: '25px',
                                    cursor: 'pointer',
                                    position: 'relative',
                                    top: { xs: '1px', sm: '2px' },
                                  }}
                                  onClick={() => {
                                    updateProductQuantity(item, 'plus', index);
                                  }}
                                  alt=""
                                  src={plusIcon}
                                />
                              </Grid>
                            </Grid>

                            {
                              <Grid
                                item
                                xs={5}
                                sx={{
                                  textAlign: 'Center',
                                  fontSize: '12px',
                                  fontWeight: '600',
                                  fontFamily:
                                    "'Librefranklin-Regular' !important",
                                  color: '#006DB7',
                                  lineHeight: '16px',
                                  display: 'block',
                                }}
                              >
                                {productRemoveObj?.loading ||
                                productAddObj?.loading ? (
                                  <label
                                    key={Math.random() + 'disable-edit'}
                                    onClick={() => false}
                                    title="Edit"
                                    style={{
                                      height: '15px !important',
                                      textDecoration: 'underline',
                                      color: '#006DB7',
                                      fontFamily:
                                        "'Librefranklin-Regular' !important",
                                      fontWeight: '600',
                                      lineHeight: '16px',
                                      cursor: 'pointer',
                                      display: !checkItemIsUpsells(
                                        item.productId,
                                      )
                                        ? 'block'
                                        : 'none',
                                    }}
                                  >
                                    Edit
                                  </label>
                                ) : (
                                  <label
                                    onClick={() => {
                                      showCart();
                                      navigate(
                                        `product/${item.productId}/${item.id}${
                                          window.location.href
                                            .toLowerCase()
                                            .indexOf('product') == -1
                                            ? '?edit=true'
                                            : ''
                                        }`,
                                      );
                                    }}
                                    key={Math.random() + 'active-edit'}
                                    title="Edit"
                                    style={{
                                      height: '15px !important',
                                      textDecoration: 'underline',
                                      color: '#006DB7',
                                      fontFamily:
                                        "'Librefranklin-Regular' !important",
                                      fontWeight: '600',
                                      lineHeight: '16px',
                                      cursor: 'pointer',
                                      display: !checkItemIsUpsells(
                                        item.productId,
                                      )
                                        ? 'block'
                                        : 'none',
                                    }}
                                  >
                                    Edit
                                  </label>
                                )}
                              </Grid>
                            }

                            <Grid item xs={3} sx={{ textAlign: 'right' }}>
                              <Typography
                                variant="caption"
                                title={'$' + item.totalcost?.toFixed(2)}
                                sx={{
                                  textAlign: 'right',
                                  fontSize: '12px',
                                  fontWeight: '600',
                                  fontFamily:
                                    "'Librefranklin-Regular' !important",
                                  color: '#052C42',
                                }}
                              >
                                ${item.totalcost?.toFixed(2)}
                              </Typography>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  {item.productId !== utensilsReducer.utensilsProductId && (
                    <hr
                      style={{
                        marginTop: '16px',
                        height: '1px' /* Set the height of the line */,
                        backgroundColor:
                          '#052c423a' /* Set the color of the line */,
                        border: 'none',
                      }}
                    />
                  )}
                </Grid>
              ))}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderRadius: '15px',
                  background: '#77C043',
                  height: '80px',
                  width: { xs: '100%', sm: '360px' },
                  padding: '0px 16px',
                  maxWidth: '100%',
                  margin: '16px 0px',
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      color: '#052C42',
                      fontSize: '16px',
                      lineHeight: '24px',
                      fontWeight: '700',
                      fontFamily: 'GritSans-Bold !important',
                    }}
                  >
                    SPICE UP YOUR ORDER
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      lineHeight: '24px',
                      fontWeight: '700',
                      color: '#F5F1E9',
                      fontFamily: 'GritSans-Bold !important',
                    }}
                  >
                    <span style={{ color: '#052C42' }}> WITH</span> FREE SALSA
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      color: '#FFFFFF',
                      fontFamily: "'Librefranklin-SemiBold' !important",
                      lineHeight: '22px',
                      fontWeight: 600,
                      fontSize: '14px',
                      padding: '16px 0px',
                      cursor: 'pointer',
                      textDecoration: 'underline !important',
                      textTransform: 'uppercase',
                    }}
                    onClick={() => {
                      isRotated ? addSalsaToBasket() : handleClick();
                    }}
                  >
                    {isRotated ? 'Done' : 'Select'}
                  </Typography>
                </Box>
              </Box>
              {isRotated && (
                <>
                  <Typography className="max-limit" sx={{}}>
                    Max 6 items per flavor
                  </Typography>
                  <Grid container style={{ top: '4px' }}>
                    {salsaUpsell?.map((upsellItem: any, index: number) => (
                      <Grid
                        item
                        xs={4}
                        style={{
                          padding: '20px 0px',
                          textAlign: 'center',
                          position: 'relative',
                        }}
                      >
                        <div
                          style={{
                            position: 'relative',
                            objectFit: 'cover',
                          }}
                        >
                          <img
                            style={{
                              width: '65.15px',
                              height: '65px',
                              borderRadius: '33px',
                              boxShadow:
                                '0 4px 8px rgba(0, 0, 0, 0.2)' /* Add a shadow */,
                              backgroundColor: '#f0f0f0',
                              objectFit: 'cover',
                            }}
                            src={
                              categories &&
                              categories.imagepath + upsellItem.imagefilename
                            }
                            alt="Main Image"
                          />
                          {_.keyBy(salsaCounter, 'index')[index]?.state ===
                            false && (
                            <img
                              style={{
                                position: 'absolute',
                                top: 14,
                                left: '78.5%',
                                transform: 'translate(-50%, -50%)',
                                width: '19px',
                                height: '19px',
                                cursor: 'pointer',
                              }}
                              src={plusRoundedIcon}
                              onClick={() => {
                                salsaSection(index);
                                updateSalsaCount(upsellItem.id, 'PLUS');
                              }}
                              alt="Icon"
                            />
                          )}
                        </div>
                        <Typography
                          style={{
                            marginTop: '8px',
                            fontFamily: "'Librefranklin-Regular' !important",
                            color: '#052C42',
                            fontSize: '12px',
                            lineHeight: '16px',
                            fontWeight: '600',
                            textAlign: 'center',
                          }}
                        >
                          {upsellItem.name}
                        </Typography>

                        {_.keyBy(salsaCounter, 'index')[index]?.state ===
                          true && (
                          <Box
                            sx={{
                              top: { xs: '18px', sm: '20px' },
                              marginLeft: { xs: '5px', sm: 0 },
                            }}
                            className="upsells-details"
                          >
                            <div className="quantity">
                              <Button
                                sx={{
                                  background: ' #052c427f',
                                }}
                                title=""
                                className="subtract"
                                aria-label="reduce"
                                onClick={() => {
                                  updateSalsaCount(
                                    upsellItem.id,
                                    'MINUS',
                                    index,
                                  );
                                }}
                              >
                                {upsellItem.quantity > 1 ? (
                                  '-'
                                ) : (
                                  <img
                                    style={{
                                      position: 'absolute',
                                      top: 12,
                                      left: '78.5%',
                                      transform: 'translate(-50%, -50%)',
                                      width: '12px',
                                      height: '12px',
                                      cursor: 'pointer',
                                      paddingRight: '10px',
                                    }}
                                    src={deleteIcon}
                                    alt="Icon"
                                  />
                                )}
                              </Button>
                              <Divider
                                orientation="vertical"
                                flexItem
                                sx={{
                                  margin: { xs: '5px 0px', md: '6px 0px' },
                                  background: '#8E8E93 !important',
                                }}
                              />
                              <input
                                value={upsellItem.quantity}
                                readOnly
                                id="quantityfield"
                                onChange={() => {}}
                                style={{
                                  fontWeight: '600',
                                  fontSize: '15px',
                                  lineHeight: '17.5px',
                                  fontFamily:
                                    "'Librefranklin-Regular' !important",
                                  color: '#000000',
                                  border: 'none',
                                }}
                                title="quantity"
                              />
                              <Divider
                                orientation="vertical"
                                flexItem
                                sx={{
                                  margin: { xs: '5px 0px', md: '6px 0px' },
                                  background: '#8E8E93 !important',
                                }}
                              />
                              <Button
                                title=""
                                className="add"
                                aria-label="increase"
                                disabled={
                                  upsellItem.quantity ==
                                  upsellItem.maximumquantity
                                }
                                onClick={() => {
                                  updateSalsaCount(upsellItem.id, 'PLUS');
                                }}
                              >
                                {' '}
                                +{' '}
                              </Button>
                            </div>
                          </Box>
                        )}
                      </Grid>
                    ))}
                    <Box
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {/* <Button
                        variant="contained"
                        sx={{
                          textTransform: 'uppercase',
                          color: '#ffffff',
                          fontSize: '16px',
                          fontWeight: '700',
                          fontFamily: "'GritSans-Bold' !important",
                          lineHeight: '22px',
                          letterSpacing: '0px !important',
                          backgroundColor: '##006DB7 !important',
                          borderRadius: '33px !important',
                          margin: '32px 0px',
                          width: '282px',
                          height: '44px',
                        }}
                        onClick={() => addSalsaToBasket()}
                      >
                        Done
                      </Button> */}
                    </Box>
                  </Grid>

                  <Box sx={{ margin: 'auto' }}>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        addSalsaToBasket();
                      }}
                      sx={{
                        textTransform: 'uppercase',
                        width: '282px',
                        height: '44px',
                        borderRadius: '33px !important',
                        padding: '6px',
                        fontSize: { xs: '16px', md: '18px' },
                        fontFamily: 'GritSans-Bold !important',
                        border: '3px solid #006DB7',
                        fontWeight: 700,
                        display: 'flex',
                        marginBottom: { xs: '1rem', sm: '25px' },
                        marginTop: { xs: '.5rem', sm: '8px' },
                      }}
                      title="Checkout"
                      aria-label="Checkout"
                    >
                      Done
                    </Button>
                  </Box>
                </>
              )}

              {
                <Box
                  sx={{
                    background: '#EEEDE9',
                    marginRight: '-25px !important',
                    marginLeft: '-14px',
                    width: 'calc(100% + 39px)',
                  }}
                >
                  {upsellsCombination.length > 0 && (
                    <Grid
                      xs={12}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontWeight: 700,
                          fontFamily: 'GritSans-Bold',
                          lineHeight: '24px',
                          color: '#006DB7',
                          padding: '20px 15px 16px 15px',
                        }}
                      >
                        COMPLETE YOUR ORDER
                      </Typography>
                      {upSellCost > 0 && (
                        <Typography
                          sx={{
                            fontSize: '12px',
                            fontWeight: '600',
                            fontFamily: "'Librefranklin-Regular' !important",
                            color: '#052C42',
                          }}
                          style={{ padding: '20px 25px 16px 15px' }}
                        >{`$${upSellCost.toFixed(2)}`}</Typography>
                      )}
                    </Grid>
                  )}

                  <Grid container style={{ top: '4px' }}>
                    {upsellsCombination?.map(
                      (upsellCombinationItem: any, index: number) =>
                        upsellCombinationItem !== undefined && (
                          <Grid
                            item
                            xs={4}
                            style={{
                              textAlign: 'center',
                              position: 'relative',
                              padding: '0px 15px 20px  15px',
                              gap: '10px',
                            }}
                          >
                            <div style={{ position: 'relative' }}>
                              <img
                                style={{
                                  width: '65.15px',
                                  height: '65px',
                                  borderRadius: '33px',
                                  objectFit: 'cover',
                                  boxShadow:
                                    '0 4px 8px rgba(0, 0, 0, 0.2)' /* Add a shadow */,
                                  backgroundColor: '#ffffff',
                                }}
                                src={
                                  selectedOptions?.[
                                    upsellCombinationItem?.newId
                                  ]?.image ||
                                  categories?.imagepath +
                                    upsellCombinationItem?.imagefilename
                                }
                                onError={(e: any) => {
                                  e.target.src = require('../../assets/imgs/No ingredient.png');
                                }}
                                alt=""
                              />
                              {_.keyBy(completeOrderCounter, 'index')[index]
                                ?.state === false && (
                                <Box
                                  component="img"
                                  sx={{
                                    position: 'absolute',
                                    top: { xs: '10px', sm: '14px' },
                                    left: { xs: '78%', sm: '78.5%' },
                                    transform: 'translate(-50%, -50%)',
                                    width: '19px',
                                    height: '19px',
                                    cursor: 'pointer',
                                    pointerEvents:
                                      _.some(completeOrderCounter, 'state') ==
                                      true
                                        ? 'none'
                                        : 'auto',
                                  }}
                                  src={plusRoundedIcon}
                                  onClick={() => {
                                    completeOrderSelection(index);
                                    handleQtyChange(
                                      upsellCombinationItem,
                                      'plus',
                                    );
                                  }}
                                  alt="Icon"
                                />
                              )}
                              <Typography
                                style={{
                                  fontFamily:
                                    "'Librefranklin-Regular' !important",
                                  color: '#052C42',
                                  fontSize: '12px',
                                  lineHeight: '16px',
                                  fontWeight: '600',
                                  textAlign: 'center',
                                  height: '50px',
                                  paddingTop: '8px',
                                  overflow: 'hidden',
                                  position: 'relative',
                                }}
                              >
                                {upsellCombinationItem?.name == 'Bottled Drinks'
                                  ? selectedOptions?.[
                                      upsellCombinationItem?.newId
                                    ]?.name
                                  : upsellCombinationItem?.name || ''}
                              </Typography>
                              <Typography
                                style={{
                                  fontFamily:
                                    "'Librefranklin-Regular' !important",
                                  color: '#052C42',
                                  fontSize: '12px',
                                  lineHeight: '16px',
                                  fontWeight: '400',
                                  textAlign: 'center',
                                  bottom: '10px',
                                }}
                              >
                                {upsellCombinationItem?.cost > 0
                                  ? `$ ${upsellCombinationItem?.cost.toFixed(
                                      2,
                                    )}`
                                  : `$ ${
                                      selectedOptions?.[
                                        upsellCombinationItem?.newId
                                      ]?.cost
                                    }` || ''}
                              </Typography>
                            </div>

                            {_.keyBy(completeOrderCounter, 'index')[index]
                              ?.state === true && (
                              <Box
                                sx={{
                                  top: { xs: 0, sm: '4px' },
                                  marginLeft: { xs: '5px', sm: 0 },
                                }}
                                className="upsells-details"
                              >
                                <div className="quantity">
                                  <Button
                                    sx={{
                                      background: ' #052c427f',
                                    }}
                                    title=""
                                    className="subtract"
                                    aria-label="reduce"
                                    onClick={() => {
                                      handleQtyChange(
                                        upsellCombinationItem,
                                        'minus',
                                        index,
                                      );
                                    }}
                                  >
                                    {upsellCombinationItem.quantity > 1 ? (
                                      '-'
                                    ) : (
                                      <Box
                                        component="img"
                                        sx={{
                                          position: 'absolute',
                                          top: 12,
                                          left: '78.5%',
                                          transform: 'translate(-50%, -50%)',
                                          width: '12px',
                                          height: '12px',
                                          cursor: 'pointer',
                                          paddingRight: '10px',
                                        }}
                                        src={deleteIcon}
                                        alt="Icon"
                                      />
                                    )}
                                  </Button>
                                  <Divider
                                    orientation="vertical"
                                    flexItem
                                    sx={{
                                      margin: { xs: '5px 0px', md: '6px 0px' },
                                      background: '#8E8E93 !important',
                                    }}
                                  />
                                  <input
                                    value={upsellCombinationItem?.quantity}
                                    readOnly
                                    id="quantityfield"
                                    title="quantity"
                                    style={{
                                      fontWeight: '600',
                                      fontSize: '15px',
                                      lineHeight: '17.5px',
                                      fontFamily:
                                        "'Librefranklin-Regular' !important",
                                      color: '#000000',
                                      border: 'none',
                                    }}
                                  />
                                  <Divider
                                    orientation="vertical"
                                    flexItem
                                    sx={{
                                      margin: { xs: '5px 0px', md: '6px 0px' },
                                      background: '#8E8E93 !important',
                                    }}
                                  />
                                  <Button
                                    title=""
                                    className="add"
                                    aria-label="increase"
                                    onClick={() => {
                                      handleQtyChange(
                                        upsellCombinationItem,
                                        'plus',
                                      );
                                    }}
                                  >
                                    {' '}
                                    +{' '}
                                  </Button>
                                  {<span>{errorMsg}</span>}
                                </div>
                              </Box>
                            )}
                          </Grid>
                        ),
                    )}
                  </Grid>
                </Box>
              }
              {utensilsReducer?.utensilsProductId &&
                basketObj?.basket?.deliverymode !== DeliveryModeEnum.dinein && (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingTop: '16px',
                    }}
                  >
                    <img
                      className="item-image"
                      style={{ width: '36px', height: '36px' }}
                      src={ForkAndSpoon}
                    />
                    <Typography
                      className="body-text"
                      sx={{
                        paddingLeft: '10px',
                        width: '100%',
                        color: '#052C42',
                        fontFamily: "'Librefranklin-Regular' !important",
                        fontSize: '12px',
                        fontWeight: 600,
                        lineHeight: '133%',
                      }}
                    >
                      Include Napkins & Utensils
                    </Typography>
                    <Checkbox
                      checked={utensils}
                      disabled={utensilsReducer?.loading && utensilsDisabled}
                      onChange={(e) => {
                        addRemoveUtensils(e);
                      }}
                      inputProps={{
                        'aria-label': ' Include Utensils',
                      }}
                      sx={{
                        paddingLeft: 0,
                        fontFamily: "'GritSans-Bold' !important",
                      }}
                      icon={<CircleUnchecked style={{ color: '#6AC7BD' }} />}
                      checkedIcon={
                        <img
                          src={cboxIcon}
                          alt="Custom Checkbox Checked Icon"
                          style={{ width: '20px', height: '20px' }} // Adjust the width and height as needed
                        />
                      }
                    />
                  </Grid>
                )}

              {basketObj?.basket?.deliverymode !== DeliveryModeEnum.dinein && (
                <Grid item xs={12} sx={{ padding: '16px 0px' }}>
                  <hr
                    style={{
                      height: '1px' /* Set the height of the line */,
                      backgroundColor:
                        '#052c423a' /* Set the color of the line */,
                      border: 'none',
                    }}
                  />
                </Grid>
              )}

              {basketObj?.basket?.products?.length > 0 && (
                <Grid item xs={12}>
                  <Grid container spacing={0} sx={{ color: '#052C42' }}>
                    <Grid
                      item
                      xs={9}
                      sx={{
                        fontFamily: "'Librefranklin-Bold' !important",
                        fontSize: '14px',
                        fontWeight: 'bold',
                        paddingBottom: '8px',
                      }}
                      title="Sub Total"
                    >
                      Subtotal
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sx={{
                        fontFamily: "'Librefranklin-Bold' !important",
                        fontSize: '14px',
                        textAlign: 'right',
                        fontWeight: 'bold',
                        paddingBottom: '2px',
                      }}
                      title={'$' + basketObj?.basket?.subtotal?.toFixed(2)}
                    >
                      ${basketObj?.basket?.subtotal?.toFixed(2)}
                    </Grid>
                    {basketObj?.basket?.discounts?.length > 0
                      ? basketObj?.basket.discounts.map((discount: any) => {
                          return (
                            <>
                              <Grid
                                item
                                xs={9}
                                sx={{
                                  fontSize: '14px',
                                  paddingBottom: '2px',
                                  fontFamily:
                                    "'Librefranklin-Regular' !important",
                                }}
                              >
                                {discount.type === 'Coupon'
                                  ? 'Coupon Code: '
                                  : 'REWARD: '}
                                {discount.description}
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                sx={{
                                  fontSize: '14px',
                                  textAlign: 'right',
                                  paddingBottom: '2px',
                                  fontFamily:
                                    "'Librefranklin-Regular' !important",
                                }}
                              >
                                -$
                                {discount.amount && discount.amount.toFixed(2)}
                              </Grid>
                            </>
                          );
                        })
                      : null}
                    <Grid
                      item
                      xs={9}
                      sx={{
                        fontSize: '14px',
                        paddingBottom: '2px',
                        fontFamily: "'Librefranklin-Regular' !important",
                        display: 'flex',
                      }}
                    >
                      <div>
                        {basketObj?.basket?.totalfees > 0 ? (
                          <>
                            <Typography
                              sx={{
                                fontSize: '14px',
                                fontFamily:
                                  "'Librefranklin-Regular' !important",
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                setShowMore(!showMore);
                              }}
                            >
                              Estimated Taxes and Fees
                              {showMore ? (
                                <ExpandLessIcon
                                  aira-label="Expand Less"
                                  onClick={() => {
                                    setShowMore(!showMore);
                                  }}
                                  style={{
                                    cursor: 'pointer',
                                    verticalAlign: 'bottom',
                                  }}
                                />
                              ) : (
                                <ExpandMoreIcon
                                  aira-label="Expand Less"
                                  onClick={() => {
                                    setShowMore(!showMore);
                                  }}
                                  style={{
                                    cursor: 'pointer',
                                    verticalAlign: 'bottom',
                                    color: 'secondary.main',
                                  }}
                                />
                              )}
                            </Typography>
                          </>
                        ) : (
                          <Typography
                            sx={{
                              fontSize: '14px',
                              fontFamily: 'Librefranklin-Regular !important',
                              fontWeight: '400',
                            }}
                          >
                            Estimated Taxes & Fees
                          </Typography>
                        )}
                      </div>
                    </Grid>
                    {!showMore && (
                      <Grid
                        item
                        xs={3}
                        sx={{
                          fontSize: '14px',
                          textAlign: 'right',
                          paddingBottom: '2px',
                          fontFamily: "'Librefranklin-Regular' !important",
                        }}
                        title={'$' + calculateTaxAndFee(basketObj?.basket)}
                      >
                        ${calculateTaxAndFee(basketObj?.basket)}
                      </Grid>
                    )}{' '}
                    {showMore && (
                      <Grid container className={'taxes'} spacing={1}>
                        <Grid item xs={9}>
                          <Typography
                            sx={{
                              lineHeight: '1.0',
                              fontFamily: "'Librefranklin-Regular' !important",
                              fontSize: '14px !important',
                              color: '#062C43 !important',
                              marginLeft: '22px !important',
                            }}
                          >
                            Sales Tax
                          </Typography>
                        </Grid>
                        <Grid item xs={3} justifyContent={'flex-end'}>
                          <Typography
                            sx={{
                              lineHeight: '1.0',
                              textAlign: 'right',
                              fontFamily: "'Librefranklin-Regular' !important",
                              color: '#062C43 !important',

                              fontSize: '14px !important',
                            }}
                            title={
                              '$' +
                              basketObj?.basket?.taxes
                                .reduce(
                                  (sum: number, tax: any) => sum + tax.tax,
                                  0,
                                )
                                .toFixed(2)
                            }
                          >
                            $
                            {basketObj?.basket?.taxes
                              .reduce(
                                (sum: number, tax: any) => sum + tax.tax,
                                0,
                              )
                              .toFixed(2)}
                          </Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <Typography
                            sx={{
                              lineHeight: '1.0',
                              fontFamily: "'Librefranklin-Regular' !important",
                              fontSize: '14px !important',
                              color: '#062C43',
                              marginBottom: '3px',
                              marginLeft: '22px !important',
                            }}
                          >
                            {basketObj?.basket?.fees?.length &&
                            basketObj?.basket.fees.filter(
                              (fee: any) =>
                                fee.description ===
                                'UCSD Living Wage Surcharge',
                            ).length > 0
                              ? 'UCSD Living Wage Surcharge:'
                              : 'Service Fee'}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            sx={{
                              lineHeight: '1.0',
                              textAlign: 'right',
                              fontFamily: "'Librefranklin-Regular' !important",
                              fontSize: '14px !important',
                              color: '#062C43',
                            }}
                            title={'$' + orderFees(basketObj?.basket)}
                          >
                            ${orderFees(basketObj?.basket)}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                    {basketObj?.basket?.customerhandoffcharge > 0 ? (
                      <>
                        <Grid
                          item
                          xs={9}
                          sx={{
                            color: 'secondary.main',
                            fontSize: '14px',
                            fontFamily: "'Librefranklin-Regular' !important",
                          }}
                          title="DELIVERY FEE"
                        >
                          Delivery Fee
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          sx={{
                            color: 'secondary.main',
                            fontSize: '14px',
                            textAlign: 'right',
                            fontFamily: "'Librefranklin-Regular' !important",
                          }}
                          title={
                            '$' +
                            basketObj?.basket?.customerhandoffcharge?.toFixed(2)
                          }
                        >
                          $
                          {basketObj?.basket?.customerhandoffcharge?.toFixed(2)}
                        </Grid>
                      </>
                    ) : null}
                    {basketObj?.basket?.tip > 0 ? (
                      <>
                        <Grid
                          item
                          xs={9}
                          sx={{
                            fontSize: '14px',
                            paddingBottom: '2px',
                            fontFamily: "'Librefranklin-Regular' !important",
                          }}
                          title="TIP"
                        >
                          Tip
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          sx={{
                            fontSize: '14px',
                            textAlign: 'right',
                            paddingBottom: '2px',
                            fontFamily: "'Librefranklin-Regular' !important",
                          }}
                        >
                          +$&nbsp;{basketObj?.basket?.tip?.toFixed(2)}
                        </Grid>
                      </>
                    ) : null}
                    <Grid item xs={12} sx={{ padding: '16px 0px' }}>
                      <hr
                        style={{
                          height: '1px' /* Set the height of the line */,
                          backgroundColor:
                            '#052c423a' /* Set the color of the line */,
                          border: 'none',
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={9}
                      sx={{
                        fontFamily: "'Librefranklin-Bold' !important",
                        fontSize: '20px',
                      }}
                      title="Total"
                    >
                      Total
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sx={{
                        fontFamily: "'Librefranklin-Bold' !important",
                        fontSize: '15px',
                        fontWeight: 'bold',
                        textAlign: 'right',
                      }}
                      title={'$' + basketObj?.basket?.total?.toFixed(2)}
                    >
                      ${basketObj?.basket?.total?.toFixed(2)}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
          {basketObj?.basket?.products?.length > 0 && (
            <Grid
              container
              spacing={0}
              sx={{
                boxShadow: '0px 0px 13px 0px rgba(161, 161, 161, 0.30)',
                padding: { xs: '24px 0px', md: '24px 0px 0px 0px' },
              }}
            >
              <Grid item xs={12} sx={{ justifyContent: 'center' }}>
                {
                  <Button
                    variant="contained"
                    disabled={checkoutBtnDisabled}
                    onClick={async () => {
                      try {
                        const response: any = await validateBasket(
                          basketObj?.basket?.id + '',
                        );
                        if (response) {
                          setCheckoutBtnDisabled(true);
                          setTimeout(() => {
                            showCart();
                            setCheckoutBtnDisabled(false);
                          }, 1500);
                          triggerFacebookEventOnCheckout();
                          fireViewCartEvent(2);
                          return false;
                        }
                      } catch (error: any) {
                        displayToast(
                          'ERROR',
                          error?.response?.data?.message
                            ? error.response.data.message
                            : 'ERROR! Please Try again later',
                        );
                      }
                    }}
                    sx={{
                      textTransform: 'uppercase',
                      backgroundColor: '#0A6FB8',
                      margin: 'auto',
                      width: '282px',
                      height: '44px',
                      borderRadius: '33px !important',
                      padding: '6px',
                      fontSize: { xs: '16px', md: '18px' },
                      fontFamily: 'GritSans-Bold !important',
                      boxShadow:
                        '0px 0px 13px 0px rgba(161, 161, 161, 0.30) !important',
                      fontWeight: 700,
                      display: 'flex',
                      marginBottom: { xs: '100px', sm: '25px' },
                      position: 'inherit',
                    }}
                    title="Checkout"
                    aria-label="Checkout"
                  >
                    CHECK OUT
                  </Button>
                }
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default Cart;
