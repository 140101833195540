import React, { useState, useEffect, useRef, useMemo } from 'react';
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  Skeleton,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import moment from 'moment';

import './index.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteBasketTimeWanted,
  getSingleRestaurantCalendar,
  updateBasketTimeWanted,
} from '../../redux/actions/basket/checkout';
import {
  createTimeWantedPayload,
  GetRestaurantHoursRange,
} from '../../helpers/checkout';
import { CalendarTypeEnum, HoursListing } from '../../helpers/hoursListing';
import { ResponseBasket } from '../../types/olo-api';
import { DeliveryModeEnum } from '../../types/olo-api/olo-api.enums';

import ArrowDropUpIconBlue from '../../assets/imgs/ArrowDropUpRoundedIconBlue.svg';
import ArrowDropDownIconBlue from '../../assets/imgs/ArrowDropDownRoundedIconBlue.svg';
import CircleCheckedIcon from '../../assets/imgs/circle-checked-time-slot.svg';
import CircleUncheckedIcon from '../../assets/imgs/circle-unchecked-time-slot.svg';
import DrawerCloseCross from '../../assets/imgs/cross-links-icon.svg';
import statusIcon from '../../assets/imgs/statusImg.svg';
import { finalizedTimeSlots } from '../../helpers/checkout';

import Drawer from '@mui/material/Drawer';
import _ from 'lodash';

const OrderTime = ({ orderType, setpickupTimeView, pickupTimeView }: any) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedDate, setSelectedDate] = React.useState<any>(new Date());
  const [selectedTime, setSelectedTime] = React.useState<any>('');
  const [selectedDay, setSelectedDay] = React.useState<any>('');
  const [restaurantHours, setRestaurantHours] =
    React.useState<HoursListing[]>();
  const [timeSlots, setTimeSlots] = React.useState<string[]>([]);
  const [open, setOpen] = React.useState<boolean>(false);
  const [basket, setBasket] = React.useState<ResponseBasket>();
  const [notAvailableSlots, setNotAvailableSlots] = useState(false);
  const [daysVisible, setDaysVisibile] = useState(true);
  const [showMoreTimes, setShowMoreTimes] = useState(false);
  const [showMoreDays, setShowMoreDays] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(true);
  const [selectedDayIndex, setSelectedDayIndex] = useState(-1);

  const basketObj = useSelector((state: any) => state.basketReducer);
  const time = basketObj?.basket;
  const containerRef = useRef<HTMLDivElement | null>(null);

  const scrollLeft = (index: any) => {
    const container = containerRef.current;
    if (container) {
      const lastItem = container?.lastElementChild;
      if (lastItem) {
        const containerRect = lastItem.getBoundingClientRect();
        container.scrollLeft = index * (containerRect.width + 14);
      }
    }
  };

  React.useEffect(() => {
    if (basketObj.basket) {
      if (basketObj.basket?.timewanted) {
        setSelectedDate(
          moment(basketObj?.basket?.timewanted, 'YYYYMMDD HH:mm'),
        );
        setSelectedTime(basketObj?.basket?.timewanted);
        setSelectedDay(basketObj?.basket?.timewanted);
      } else {
        const leadDate = moment().add(time?.leadtimeestimateminutes, 'minutes');
        setSelectedDate(leadDate);
        setSelectedDay(moment(leadDate).format('YYYYMMDD HH:mm'));
        setSelectedTime('');
      }
      setBasket(basketObj.basket);
    }
  }, [basketObj.basket]);

  React.useEffect(() => {
    if (basketObj.calendar && basketObj.calendar.data) {
      setRestaurantHours(
        GetRestaurantHoursRange(
          basketObj.calendar.data,
          CalendarTypeEnum.business,
        ),
      );
    }
  }, [basketObj.calendar]);

  const getCurrentAndOnwardWeekDays = (startDate: any) => {
    const daysOfWeek = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    let currentDate = moment();
    currentDate = currentDate.add(startDate, 'm');

    let onwardWeekDays = [];
    const currentDayIndex = currentDate.day(); // Get the index of the current day (0 for Sunday, 1 for Monday, etc.)
    const currentDay = daysOfWeek[currentDayIndex]; // Get the name of the current day
    for (let i = 0; i < 7; i++) {
      const dayIndex = (currentDayIndex + i) % 7; // Calculate the index, wrapping around to Sunday
      const dayName = daysOfWeek[dayIndex];
      const dayDate = moment(currentDate); // Create a new date object to avoid modifying the original date
      dayDate.add(i, 'day'); // Increment the date by i days
      onwardWeekDays.push({ day: dayName, date: dayDate });
    }
    return onwardWeekDays;
  };

  React.useEffect(() => {
    if (selectedDay != '') {
      dispatch(
        getSingleRestaurantCalendar(
          basketObj.basket.vendorid,
          moment(moment(selectedDay, "YYYYMMDD HH:mm")).format('YYYYMMDD'),
          moment(moment(selectedDay, "YYYYMMDD HH:mm")).format('YYYYMMDD'),
        ),
      );
      const selectedIndex = onwardWeekDays.findIndex((item: any) => {
        return moment(selectedDay, "YYYYMMDD HH:mm").format('YYYYMMDD') === moment(item.date, "YYYYMMDD HH:mm").format('YYYYMMDD')
      });
      scrollLeft(selectedIndex)
    }
  }, [selectedDay])

  React.useEffect(() => {
    if (restaurantHours && restaurantHours.length && selectedDayIndex > -1) {
      const {
        slots = [],
        dayIndex,
        selectedTime: time,
      } = finalizedTimeSlots({
        restaurantHours,
        orderType,
        selectedDayIndex,
        basket,
        selectedDate,
      });
      if (!slots?.length) {
        setNotAvailableSlots(true);
      } else {
        setTimeSlots(slots);
        setSelectedDayIndex(dayIndex);
        if (time) {
          setSelectedTime(time);
        }
        setNotAvailableSlots(false);
      }
    } else {
      setNotAvailableSlots(true);
    }
  }, [restaurantHours]);

  const onwardWeekDays = useMemo(() => {
    return getCurrentAndOnwardWeekDays(time?.leadtimeestimateminutes);
  }, [time?.leadtimeestimateminutes]);

  //If date and time is selected then scroll to respective index
  useEffect(() => {
    if (onwardWeekDays?.length) {
      let dayIndex = onwardWeekDays?.findIndex(
        (item: any) =>
          moment(item?.date).format('DD') ===
          moment(basketObj?.basket?.timewanted, 'YYYYMMDD HH:mm').format('DD'),
      );
      if (dayIndex > -1) {
        setSelectedDayIndex(dayIndex);
      } else {
        setSelectedDayIndex(0);
      }
    }
  }, [onwardWeekDays, basketObj?.basket?.timewanted]);

  React.useEffect(() => {
    if (onwardWeekDays?.length) {
      handleDateChange(moment(onwardWeekDays[0].date).format('YYYYMMDD HH:mm'));
    }
  }, [onwardWeekDays]);

  const onTimeSlotSelect = () => {
    const selectedValue = selectedTime;
    if (selectedValue && selectedValue !== '') {
      const payload = createTimeWantedPayload(selectedValue);
      if (basket) {
        dispatch(updateBasketTimeWanted(basket.id, payload));
      }
    }
  };

  const onTimeSelect = (event: any) => {
    const selectedValue = event;
    setSelectedTime(selectedValue);
    setShowMoreTimes(false);

    if (selectedValue && selectedValue !== '') {
      const payload = createTimeWantedPayload(selectedValue);
      if (basket) {
        dispatch(updateBasketTimeWanted(basket.id, payload));
      }
    }
  };

  const handleDateChangeToggle = (e: any, item: any, index?: any) => {
    setTimeSlots([]);
    setSelectedTime('');
    setSelectedDay(moment(item.date).format('YYYYMMDD HH:mm'));
    setSelectedDate(item.date);
    setSelectedDayIndex(index);
    setDaysVisibile(false);
  };

  const handleDateChange = (e: any) => {
    try {
      if (timer) clearInterval(timer);
      timer = setInterval(() => {
        if (!isDateFormatSet) {
          let dateNodes: any = document.querySelectorAll('.MuiPickersDay-root');
          dateNodes.forEach((item: any) => {
            const attributeValue = item.getAttribute('aria-label');
            if (attributeValue) {
              const date = new Date(attributeValue);
              item?.setAttribute(
                'aria-label',
                moment(date).format('dddd MMM. D, YYYY'),
              );
            }
          });
          isDateFormatSet = true;
        }
      }, 1000);
    } catch (e) {}

    const startDate = moment().toDate();
    const endDate = moment().add('days', 7).toDate();

    const range = moment(e).isBetween(startDate, endDate, 'days', '[]');
    setSelectedDate(e);
    const callAllow = range;
    if (
      callAllow &&
      basket &&
      moment(e).format('YYYYMMDD') !== moment(selectedDate).format('YYYYMMDD')
    ) {
      dispatch(
        getSingleRestaurantCalendar(
          basket.vendorid,
          moment(e).format('YYYYMMDD'),
          moment(e).format('YYYYMMDD'),
        ),
      );
    }
  };
  let timer: any = null;
  let isDateFormatSet = false;
  const setAttributesForDate = () => {
    setOpen(!open);

    document.getElementById('dateTime_check')?.addEventListener('click', () => {
      setTimeout(() => {
        let dateNodes: any = document.querySelectorAll('.MuiPickersDay-root');
        dateNodes.forEach((item: any) => {
          item.removeAttribute('tabIndex');
          const attributeValue = item.getAttribute('aria-label');
          if (attributeValue) {
            const date = new Date(attributeValue);
            item?.setAttribute(
              'aria-label',
              moment(date).format('dddd, MMMM D, YYYY'),
            );
          }
        });
      }, 500);
    });
  };

  const handleTime = (time: any) => {
    let localTime = moment(new Date());
    let earlyReadyTime = moment(time, 'YYYYMMDD HH:mm');

    const minutes = earlyReadyTime.diff(localTime, 'minutes');
    return minutes && minutes > 0 ? minutes : 0;
  };

  const hideRemoveAsap = () => {
    let currentTime = moment();

    if (restaurantHours && restaurantHours?.length) {
      let openAt = moment(restaurantHours[0]?.start, 'YYYYMMDD HH:mm');
      let closeAt = moment(restaurantHours[0]?.end, 'YYYYMMDD HH:mm');

      return currentTime.isBetween(openAt, closeAt);
    } else {
      return false;
    }
  };

  const checkOrderType = () => {
    if (orderType === 'dispatch') return 'Delivery';
    if (orderType === 'dinein') return 'Dine In At';
    if (orderType === 'pickup' || orderType === 'curbside') return 'Pick Up';
  };

  return (
    <Grid container xs={12} className="time-slot-parent-container">
      {pickupTimeView &&
        orderType !== DeliveryModeEnum.dinein &&
        (isMobile === false ? (
          <>
            {
              <Grid
                container
                style={{ background: 'white', marginTop: '16px', gap: '6px' }}
              >
                {onwardWeekDays.slice(0, 3).map((item: any, index: number) => (
                  <Grid item xs={3.85} key={item.date}>
                    <ToggleButton
                      onClick={(e: any) =>
                        handleDateChangeToggle(e, item, index)
                      }
                      value={item}
                      disabled={
                        moment(moment(selectedDay, 'YYYYMMDD HH:mm')).format(
                          'ddd',
                        ) == moment(item.date).format('ddd')
                      }
                      sx={{
                        borderRadius: '10px !important',
                        fontFamily: 'Librefranklin-Regular !important',
                        flexDirection: 'column',
                        display: 'flex',
                        width: '100%', // Set the desired height for each item
                        backgroundColor:
                          moment(moment(selectedDay, 'YYYYMMDD HH:mm')).format(
                            'ddd',
                          ) === moment(item.date).format('ddd')
                            ? '#006DB7 !important' // Change to your desired color for selected
                            : '#A7C2D13B !important', // Change to your desired color for unselected
                        textAlign: 'left',
                        height: '58px',
                        textTransform: 'capitalize !important',
                        alignItems: 'start',
                      }}
                      selected={
                        moment(moment(selectedDay, 'YYYYMMDD HH:mm')).format(
                          'ddd',
                        ) == moment(item.date).format('ddd')
                      }
                    >
                      <Typography
                        sx={{
                          fontSize: '16px',
                          color:
                            moment(
                              moment(selectedDay, 'YYYYMMDD HH:mm'),
                            ).format('ddd') === moment(item.date).format('ddd')
                              ? 'white !important'
                              : '#052C42 !important',
                          fontWeight: 600,
                          width: '100%',
                        }}
                      >
                        {(moment().isSame(item.date, 'day') && 'Today') ||
                          (moment(item.date).isSame(
                            moment().add(1, 'day'),
                            'day',
                          ) &&
                            'Tomorrow') ||
                          item.day}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '12px',
                          paddingTop: '2px',
                          color:
                            moment(
                              moment(selectedDay, 'YYYYMMDD HH:mm'),
                            ).format('ddd') === moment(item.date).format('ddd')
                              ? 'white !important'
                              : '#052C4280 !important',
                          fontWeight: 400,
                          width: '100%',
                        }}
                      >
                        {moment(item.date).format('MMMM DD')}
                      </Typography>
                    </ToggleButton>
                  </Grid>
                ))}
              </Grid>
            }
            {selectedDay &&
              !onwardWeekDays
                .slice(0, 3)
                .some(
                  (item) =>
                    item.day ===
                    moment(moment(selectedDay, 'YYYYMMDD HH:mm')).format(
                      'dddd',
                    ),
                ) && (
                <Grid item xs={3.85}>
                  <ToggleButton
                    onClick={(e: any) => handleDateChangeToggle(e, selectedDay)}
                    value={selectedDay}
                    disabled={true}
                    sx={{
                      '&:hover': {
                        color: 'white !important', // Change to your desired hover color
                        backgroundColor: '#006DB7',
                      },
                      marginTop: '8px',
                      borderRadius: '10px !important',
                      fontFamily: 'Librefranklin-Regular !important',
                      flexDirection: 'column',
                      display: 'flex',
                      width: '100%', // Set the desired height for each selectedDay
                      backgroundColor: '#006DB7 !important',
                      textAlign: 'left',
                      height: '58px',
                      textTransform: 'capitalize !important',
                      alignselectedDays: 'start',
                    }}
                    selected={true}
                  >
                    <Typography
                      sx={{
                        fontSize: '16px',
                        color: 'white',
                        fontWeight: 600,
                        width: '100%',
                      }}
                    >
                      {selectedDay &&
                        moment(moment(selectedDay, 'YYYYMMDD HH:mm')).format(
                          'dddd',
                        )}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '12px',
                        paddingTop: '2px',
                        color: 'white',
                        fontWeight: 400,
                        width: '100%',
                      }}
                    >
                      {selectedDay &&
                        moment(selectedDay, 'YYYYMMDD HH:mm').format('MMMM DD')}
                    </Typography>
                  </ToggleButton>
                </Grid>
              )}

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                marginBottom: '8px',
                marginTop: '8px',
              }}
            >
              <Typography
                onClick={() => {
                  setShowMoreDays(!showMoreDays);
                  setDaysVisibile(true);
                }}
                sx={{
                  color: '#006DB7',
                  fontFamily: "'GritSans-Bold' !important",
                  lineHeight: '17.5px',
                  fontWeight: 700,
                  fontSize: '14px',
                  cursor: 'pointer',
                }}
              >
                {showMoreDays ? 'Less Dates' : 'More Dates'}
              </Typography>
              <img
                aria-hidden="true"
                style={{
                  width: '24px',
                  height: '24px',
                  position: 'inherit',
                  cursor: 'pointer',
                }}
                alt=""
                onClick={() => {
                  setShowMoreDays(!showMoreDays);
                  setDaysVisibile(true);
                }}
                src={showMoreDays ? ArrowDropUpIconBlue : ArrowDropDownIconBlue}
              />
            </Box>

            {
              <div
                className="time-slot-list"
                style={{
                  overflowY: 'scroll',
                  maxHeight: '200px',
                  width: '100%',
                  borderRadius: '16px',
                  boxShadow: '0px 0px 13px 0px rgba(161, 161, 161, 0.30)',
                  marginBottom: '16px',
                  display: `${(showMoreDays === false && 'none') || 'inline'}`,
                }}
              >
                {daysVisible &&
                  onwardWeekDays.map((item: any, index: number) => (
                    <Grid item xs={12} key={item.date}>
                      <ToggleButton
                        onClick={(e: any) => {
                          handleDateChangeToggle(e, item, index);
                          setShowMoreDays(false);
                        }}
                        value={item}
                        disabled={
                          moment(moment(selectedDay, 'YYYYMMDD HH:mm')).format(
                            'ddd',
                          ) == moment(item.date).format('ddd')
                        }
                        sx={{
                          fontFamily: 'Librefranklin-Regular !important',
                          flexDirection: 'column',
                          display: 'flex',
                          width: '100%', // Set the desired height for each item
                          backgroundColor:
                            moment(
                              moment(selectedDay, 'YYYYMMDD HH:mm'),
                            ).format('ddd') === moment(item.date).format('ddd')
                              ? '#006DB7 !important' // Change to your desired color for selected
                              : 'white', // Change to your desired color for unselected
                          textAlign: 'left',
                          height: '34px',
                          gap: '8px',
                          border: 'none !important',
                          textTransform: 'capitalize !important',
                          alignItems: 'start',
                        }}
                        selected={
                          moment(moment(selectedDay, 'YYYYMMDD HH:mm')).format(
                            'ddd',
                          ) == moment(item.date).format('ddd')
                        }
                      >
                        <Typography
                          sx={{
                            fontSize: '12px',
                            fontFamily: 'Librefranklin-Regular !important',
                            marginLeft: '32px',
                            color:
                              moment(
                                moment(selectedDay, 'YYYYMMDD HH:mm'),
                              ).format('ddd') ===
                              moment(item.date).format('ddd')
                                ? 'white !important'
                                : '#052C42 !important',
                            fontWeight: 400,
                            width: '100%',
                          }}
                        >
                          {(moment().isSame(item.date, 'day') && 'Today') ||
                            (moment(item.date).isSame(
                              moment().add(1, 'day'),
                              'day',
                            ) &&
                              'Tomorrow') ||
                            item.day}
                          , {moment(item.date).format('MMMM DD')}
                        </Typography>
                      </ToggleButton>
                    </Grid>
                  ))}
              </div>
            }

            <Grid item xs={12} className="time-slot-wrapper">
              <Grid container>
                <FormControl>
                  <Grid container>
                    {notAvailableSlots && (
                      <Grid item xs={12}>
                        <Typography
                          variant="caption"
                          className="label"
                          style={{ color: 'red' }}
                        >
                          No available slots. Please try a different Date.
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                  <ToggleButtonGroup
                    value={selectedTime}
                    exclusive
                    sx={{
                      fontFamily: 'Librefranklin-Regular !important',
                      borderRadius: '15px !important',
                    }}
                    className="selected-btn-group"
                  >
                    {!notAvailableSlots &&
                      timeSlots.slice(0, 3).map((time) => {
                        const isAsapRow = time === 'ASAP';
                        return (
                          <ToggleButton
                            onClick={() => onTimeSelect(time)}
                            disabled={selectedTime === time ? true : false}
                            key={`button-${time}`}
                            value={time}
                            className="selected-btn"
                            id="selected-btn"
                            sx={{
                              fontFamily: 'Librefranklin-SemiBold !important',
                              fontSize: '1rem !important',
                              backgroundColor:
                                moment(
                                  moment(selectedDate, 'YYYYMMDD HH:mm'),
                                ).format('ddd') === moment(time).format('ddd')
                                  ? '#006DB7 !important'
                                  : '#A7C2D13B !important',
                              color:
                                moment(
                                  moment(selectedDate, 'YYYYMMDD HH:mm'),
                                ).format('ddd') === moment(time).format('ddd')
                                  ? 'white !important' // Change to your desired color for selected
                                  : '#052C42 !important',
                              fontWeight: 600,
                              letterSpacing: '0.15px !important',
                              height: '32px !important',
                            }}
                            selected={selectedTime === time ? true : false}
                          >
                            {isAsapRow
                              ? time
                              : moment(time, 'YYYYMMDD HH:mm').format('h:mm A')}
                          </ToggleButton>
                        );
                      })}
                  </ToggleButtonGroup>
                  {!notAvailableSlots &&
                    !timeSlots.slice(0, 3).includes(selectedTime) &&
                    selectedTime && (
                      <ToggleButton
                        key={`button-${time}`}
                        value={time}
                        className="selected-btn"
                        sx={{
                          fontFamily: 'Librefranklin-SemiBold !important',
                          borderRadius: '10px !important',
                          backgroundColor: '#062C43',
                          fontSize: '1rem !important',
                          width: '31.33% !important',
                          letterSpacing: '0.15px !important',
                          height: '32px !important',
                          fontWeight: '600 !important',
                          transform: 'translateY(8px)',
                        }}
                        selected={selectedTime === time ? true : true}
                      >
                        {moment(selectedTime, 'YYYYMMDD HH:mm').format(
                          'h:mm A',
                        )}
                      </ToggleButton>
                    )}
                </FormControl>
              </Grid>
            </Grid>
            {!notAvailableSlots && timeSlots.length > 3 ? (
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  className={`${
                    timeSlots.slice(3).includes(selectedTime)
                      ? 'time-slot-selected'
                      : 'time-slot'
                  }`}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    <Typography
                      onClick={() => setShowMoreTimes(!showMoreTimes)}
                      sx={{
                        color: '#006DB7',
                        fontFamily: "'GritSans-Bold' !important",
                        lineHeight: '17.5px',
                        fontWeight: 700,
                        fontSize: '14px',
                        padding: '16px 0px',
                        cursor: 'pointer',
                      }}
                    >
                      {showMoreTimes ? 'Less Times' : 'More Times'}
                    </Typography>
                    <img
                      aria-hidden="true"
                      style={{
                        width: '24px',
                        height: '24px',
                        position: 'inherit',
                        cursor: 'pointer',
                      }}
                      alt=""
                      onClick={() => setShowMoreTimes(!showMoreTimes)}
                      src={
                        showMoreTimes
                          ? ArrowDropUpIconBlue
                          : ArrowDropDownIconBlue
                      }
                    />
                  </Box>
                  <div
                    className="time-slot-list"
                    style={{
                      overflowY: 'scroll',
                      maxHeight: '200px',
                      borderRadius: '16px',
                      boxShadow: '0px 0px 13px 0px rgba(161, 161, 161, 0.30)',
                      marginBottom: '16px',
                      display: `${
                        (showMoreTimes === false && 'none') || 'inline'
                      }`,
                    }}
                  >
                    {showMoreTimes &&
                      timeSlots.slice(3).map((time, index) => {
                        const isAsapRow = time === 'ASAP';
                        return (
                          <Box
                            key={`menu-${time}`}
                            onClick={() => {
                              onTimeSelect(time);
                              setShowMoreTimes(false);
                            }}
                            sx={{
                              transition: 'background-color 0.3s',
                              marginBottom: '10px', // Add spacing between items
                              '&:hover': {
                                color: 'white !important', // Change to your desired hover color
                                backgroundColor: '#006DB7',
                              },
                              cursor: 'pointer',
                              padding: '10px 30px 10px 30px',
                            }}
                          >
                            <Typography
                              sx={{
                                cursor: 'pointer',
                                '&:hover': {
                                  color: 'white !important', // Change to your desired hover color
                                },
                              }}
                              className="time-slot-time-list"
                            >
                              {isAsapRow
                                ? time
                                : moment(time, 'YYYYMMDD HH:mm').format(
                                    'h:mm A',
                                  )}
                            </Typography>
                          </Box>
                        );
                      })}
                  </div>
                  <Divider
                    sx={{
                      borderBottomWidth: '1px solid rgba(5, 44, 66, 0.23)',
                      background: 'rgba(5, 44, 66, 0.23)',
                    }}
                  />
                </FormControl>
              </Grid>
            ) : null}
          </>
        ) : (
          <div>
            {
              <Drawer
                variant="permanent"
                anchor="bottom"
                open={openDrawer}
                sx={{
                  width: '100%',
                  height: 'calc(100vh)',
                  flexShrink: 0,
                  '& .MuiDrawer-paper': {
                    width: '100%',
                    height: 'calc(100vh)',
                    transition: 'height 0.7s',
                    borderRadius: '16px 16px 0 0',
                    background: 'white',
                  },
                }}
              >
                <div className="time-slot-mobile-drawer-container">
                  <div
                    style={{
                      position: 'sticky',
                      top: 0,
                      height: '220px',
                      background: 'white !important',
                      left: 0,
                      zIndex: '999999',
                    }}
                  >
                    <div
                      className="schedule-spacing"
                      onClick={() => {
                        setOpenDrawer(!openDrawer);
                        setpickupTimeView(false);
                      }}
                    >
                      <Typography className="schedule-delivery-text">
                        Schedule {checkOrderType()}
                      </Typography>
                      <Box
                        component="img"
                        src={DrawerCloseCross}
                        sx={{ cursor: 'pointer' }}
                      />
                    </div>
                    <div style={{ padding: '15px 0px', background: 'white' }}>
                      <div
                        ref={containerRef}
                        className="time-solt-scroll"
                        style={{
                          display: 'flex',
                          overflowX: 'scroll',
                          whiteSpace: 'nowrap',
                          background: 'white',
                          zIndex: 1,
                        }}
                      >
                        {onwardWeekDays.map((item: any, index: number) => (
                          <Box
                            key={item.date}
                            style={{ minWidth: '130px', margin: '8px' }}
                            onClick={() => {
                              scrollLeft(index);
                            }}
                          >
                            <ToggleButton
                              id="scroll-container"
                              onClick={(e: any) => {
                                handleDateChangeToggle(e, item, index);
                              }}
                              value={item}
                              disabled={
                                moment(
                                  moment(selectedDay, 'YYYYMMDD HH:mm'),
                                ).format('ddd') ==
                                moment(item.date).format('ddd')
                              }
                              sx={{
                                borderRadius: '15px !important',
                                fontFamily: 'Librefranklin-Regular !important',
                                flexDirection: 'column',
                                display: 'flex',
                                width: '100%', // Set the desired height for each item
                                backgroundColor:
                                  moment(
                                    moment(selectedDay, 'YYYYMMDD HH:mm'),
                                  ).format('ddd') ===
                                  moment(item.date).format('ddd')
                                    ? '#006DB7 !important' // Change to your desired color for selected
                                    : '#A7C2D13B !important', // Change to your desired color for unselected
                                textAlign: 'left',
                                height: '75px',
                                border: 'none !important',
                                textTransform: 'capitalize !important',
                                alignItems: 'start',
                                opacity: 1,
                              }}
                              selected={
                                moment(
                                  moment(selectedDay, 'YYYYMMDD HH:mm'),
                                ).format('ddd') ==
                                moment(item.date).format('ddd')
                              }
                            >
                              <Typography
                                sx={{
                                  fontSize: '16px',
                                  color:
                                    moment(
                                      moment(selectedDay, 'YYYYMMDD HH:mm'),
                                    ).format('ddd') ===
                                    moment(item.date).format('ddd')
                                      ? 'white !important'
                                      : '#052C42 !important',
                                  fontWeight: 600,
                                  width: '100%',
                                }}
                              >
                                {(moment().isSame(item.date, 'day') &&
                                  'Today') ||
                                  (moment(item.date).isSame(
                                    moment().add(1, 'day'),
                                    'day',
                                  ) &&
                                    'Tomorrow') ||
                                  item.day}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: '12px',
                                  paddingTop: '8px',
                                  color:
                                    moment(
                                      moment(selectedDay, 'YYYYMMDD HH:mm'),
                                    ).format('ddd') ===
                                    moment(item.date).format('ddd')
                                      ? 'white !important'
                                      : '#052C4280 !important',
                                  fontWeight: 400,
                                  width: '100%',
                                }}
                              >
                                {moment(item.date).format('MMMM DD')}
                              </Typography>
                            </ToggleButton>
                          </Box>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div
                    className="time-slot-intervals-list"
                    style={{
                      overflowY: 'scroll',
                      maxHeight: 'auto',
                      paddingTop: '8px',
                      marginBottom: '16px',
                      zIndex: 0,
                    }}
                  >
                    {!notAvailableSlots && timeSlots?.length ? (
                      timeSlots?.map((time, index) => {
                        const isAsapRow = time === 'ASAP';
                        const isSelected = selectedTime === time;
                        return (
                          <>
                            <Box
                              key={`menu-${time}`}
                              onClick={() => {
                                onTimeSelect(time);
                                setShowMoreTimes(false);
                              }}
                              sx={{
                                cursor: 'pointer',
                                padding: '0px 20px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              <Typography
                                sx={{
                                  cursor: 'pointer',
                                }}
                                className="time-slot-time-list-for-mobile"
                              >
                                {!isAsapRow
                                  ? moment(time, 'YYYYMMDD HH:mm').format(
                                      'h:mm A',
                                    )
                                  : `${time}`}
                              </Typography>
                              <Box
                                component="img"
                                src={
                                  isSelected
                                    ? CircleCheckedIcon
                                    : CircleUncheckedIcon
                                }
                              />
                            </Box>
                            <Divider
                              sx={{
                                margin: '15px 20px',
                                borderBottomWidth:
                                  '1px solid rgba(5, 44, 66, 0.23)',
                                background: 'rgba(5, 44, 66, 0.23)',
                              }}
                            />
                          </>
                        );
                      })
                    ) : (
                      <>
                        {notAvailableSlots === true && (
                          <Box
                            sx={{
                              display: 'flex',
                              overflowY: 'hidden',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              margin: 'auto',
                              width: '270px',
                              height: '600px',
                              borderRadius: '16px',
                            }}
                          >
                            <Box
                              component="img"
                              sx={{ height: '100px', width: '150px' }}
                              src={statusIcon}
                            />
                            <Typography
                              style={{ color: 'red', marginTop: '20px' }}
                            >
                              No more slots available for this day.
                            </Typography>
                          </Box>
                        )}
                        {notAvailableSlots === false && (
                          <>
                            <Skeleton
                              animation="wave"
                              height={40}
                              sx={{ translate: '20px 0px' }}
                              width="90%"
                            />
                            <Skeleton
                              animation="wave"
                              height={40}
                              sx={{ translate: '20px 0px' }}
                              width="90%"
                            />
                            <Skeleton
                              animation="wave"
                              height={40}
                              sx={{ translate: '20px 0px' }}
                              width="90%"
                            />
                            <Skeleton
                              animation="wave"
                              height={40}
                              sx={{ translate: '20px 0px' }}
                              width="90%"
                            />
                            <Skeleton
                              animation="wave"
                              height={40}
                              sx={{ translate: '20px 0px' }}
                              width="90%"
                            />
                            <Skeleton
                              animation="wave"
                              height={40}
                              sx={{ translate: '20px 0px' }}
                              width="90%"
                            />
                            <Skeleton
                              animation="wave"
                              height={40}
                              sx={{ translate: '20px 0px' }}
                              width="90%"
                            />
                            <Skeleton
                              animation="wave"
                              height={40}
                              sx={{ translate: '20px 0px' }}
                              width="90%"
                            />
                            <Skeleton
                              animation="wave"
                              height={40}
                              sx={{ translate: '20px 0px' }}
                              width="90%"
                            />
                          </>
                        )}
                      </>
                    )}
                    <Box
                      sx={{
                        bottom: '40px',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'fixed',
                      }}
                    >
                      <Button
                        onClick={() => {
                          onTimeSlotSelect();
                          setOpenDrawer(false);
                          setpickupTimeView(false);
                        }}
                        variant="contained"
                        className="done-btn-time-slots"
                      >
                        Done
                      </Button>
                    </Box>
                  </div>
                </div>
              </Drawer>
            }
          </div>
        ))}
    </Grid>
  );
};

export default OrderTime;
