import axiosInstance from '../axiosInceptor';
import { store } from '../../redux/store';

export const requestRewards = () => {
  try {
    const url = `${process.env.REACT_APP_PUNCHH_API}/api2/mobile/users/balance?client=${process.env.REACT_APP_PUNCHH_CLIENT_ID}`;
    return axiosInstance.get(url).then((response) => response.data);
  } catch (error) {
    throw error;
  }
};

export const requestRewardsNew = () => {
  try {
    const authentication_token =
      store.getState().providerReducer.providerToken.authentication_token;

    const url = `${process.env.REACT_APP_PUNCHH_API}/api/auth/checkins/balance?client=${process.env.REACT_APP_PUNCHH_CLIENT_ID}&authentication_token=${authentication_token}`;
    return axiosInstance.get(url).then((response) => response.data);
  } catch (error) {
    throw error;
  }
};
export const requestRewardsChallenges = () => {
  try {
    const authentication_token =
      store.getState().providerReducer.providerToken.authentication_token;

    const url = `${process.env.REACT_APP_PUNCHH_API}/api2/mobile/challenges?client=${process.env.REACT_APP_PUNCHH_CLIENT_ID}&authentication_token=${authentication_token}`;
    return axiosInstance.get(url).then((response) => response.data);
  } catch (error) {
    throw error;
  }
};
export const requestChallengesDetail = (challengeId: any) => {
  try {
    const access_token =
      store.getState().providerReducer.providerToken.access_token;

    const url = `${process.env.REACT_APP_PUNCHH_API}/api2/mobile/challenges/${challengeId}?client=${process.env.REACT_APP_PUNCHH_CLIENT_ID}&access_token=${access_token}`;
    return axiosInstance.get(url).then((response) => response.data);
  } catch (error) {
    throw error;
  }
};

export const requestRewardsLocked = () => {
  try {
    const url = `${process.env.REACT_APP_PUNCHH_API}/api2/mobile/meta.json?client=${process.env.REACT_APP_PUNCHH_CLIENT_ID}`;
    return axiosInstance.get(url).then((response) => response.data);
  } catch (error) {
    throw error;
  }
};

export const testingRedemption = (id: string, points: any) => {
  const body = {
    client: process.env.REACT_APP_PUNCHH_CLIENT_ID,
    reward_id: id,
    redeemed_points: points,
  };
  try {
    const authentication_token =
      store.getState().providerReducer.providerToken.authentication_token;

    const url = `${process.env.REACT_APP_PUNCHH_API}/api/auth/redemptions?authentication_token=${authentication_token}`;
    return axiosInstance
      .post(url, body)
      .then((response) => response.data)
      .catch((error) => {
        throw error.response;
      });
  } catch (error) {
    throw error;
  }
};
