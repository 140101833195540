import axiosInstance from "../axiosInceptor";

export const sendOTP = (mobile: string) => {
  try {
    const url = process.env.REACT_APP_PROXY_BASE_URL + '/user/send-otp' || '';
    const obj = {
      phone: '+1' + mobile,
    };
    return axiosInstance
      .post(url, obj)
      .then(response => response)
      .catch(error => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};

export const verifyPhoneNumber = (mobile: string) => {
  try {
    const url = process.env.REACT_APP_PROXY_BASE_URL + `/user/verify-number/${mobile}` || '';
    return axiosInstance
      .post(url)
      .then(response => response)
      .catch(error => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};

export const verifyOTP = (mobile: string, code: string) => {
  try {
    const url = process.env.REACT_APP_PROXY_BASE_URL + '/user/verify-otp' || '';
    const obj = {
      phone: '+1' + mobile,
      code: code,
    };
    return axiosInstance
      .post(url, obj)
      .then(response => response)
      .catch(error => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};

export const updateUseronOurDB = (
  mobile: string,
  email: string,
  punchhId?: string,
  oloId?: string,
) => {
  try {
    const url = process.env.REACT_APP_PROXY_BASE_URL + '/user/user' || '';
    const obj = {
      phone: '+1' + mobile,
      email: email,
      punchhId: '',
      oloId: '',
    };
    return axiosInstance
      .patch(url, obj)
      .then(response => response)
      .catch(error => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};
