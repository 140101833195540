// import { ResponseRestaurant } from '../types/olo-api/ResponseRestaurant';
import { getGeocode } from 'use-places-autocomplete';
import { US_STATES } from './usStates';
import { getAddress } from './common';

export function removeTestingStores(restaurants: any) {
  const restaurantIds: any =
    `${process.env.REACT_APP_TESTING_RESTAURANTS_IDS}` || '';
  if (restaurantIds === 'undefined') {
    return restaurants;
  }
  let filterRestaurants: any = [];
  const testingStores: any = JSON.parse(restaurantIds) || [];

  filterRestaurants =
    restaurants &&
    restaurants?.filter((rest: any) => {
      return (
        !testingStores?.includes(rest?.id.toString()) &&
        rest?.isavailable === true
      );
    });
  return filterRestaurants;
}

export function getOrderTypeRestaurants(restaurants: any, type: string | null) {
  let filteredRestaurants = restaurants;
  if (type && type !== '') {
    if (type === 'pickup') {
      filteredRestaurants = restaurants?.filter(
        (x: any) => x?.canpickup === true,
      );
    } else if (type === 'curbside') {
      filteredRestaurants = restaurants?.filter(
        (x: any) => x?.supportscurbside === true,
      );
    } else if (type === 'dispatch') {
      filteredRestaurants = restaurants?.filter(
        (x: any) => x?.supportsdispatch === true,
      );
    }
  }
  filteredRestaurants = removeTestingStores(filteredRestaurants);
  return filteredRestaurants;
}

export function addStateFullNameRestaurant(data: any) {
  if (data?.restaurants?.length) {
    let updatedRestaurants = data?.restaurants.map((restaurant: any) => {
      const newRestaurant = {
        ...restaurant,
      };
      if (restaurant?.state && US_STATES[restaurant?.state]) {
        newRestaurant['stateName'] = US_STATES[restaurant?.state];
      }
      return newRestaurant;
    });
    return {
      restaurants: updatedRestaurants,
    };
  } else {
    return data;
  }
}
export function removeNumberFromRestaurant(
  restaurantName: string,
  maxLength: number = 30,
) {
  // Remove numbers and lowercase the string
  const cleanedName = (restaurantName || '')
    .replace(/\s-\s#\d+/, '')
    .toLowerCase();

  // Check if the length is greater than 30
  if (cleanedName.length > maxLength + 3) {
    // Truncate and add '...'
    return cleanedName.substring(0, maxLength) + '...';
  }

  return cleanedName;
}

export function removeNumberFromRestaurantName(restaurantName: string) {
  return restaurantName?.replace(/\s-\s#\d+/, '').toLowerCase();
}
export function removeNumberFromRestaurantNameUppercase(
  restaurantName: string,
) {
  return restaurantName?.replace(/\s-\s#\d+/, '').toUpperCase();
}

export function removeNumberFromRestaurantNameCapitalize(
  restaurantName: string,
) {
  const cleanedName = restaurantName?.replace(/\s-\s#\d+/, '');
  return cleanedName?.replace(/\w\S*/g, function (txt: any) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function startOrderText(saveLinkAddress: string, basket: any): string {
  if (saveLinkAddress === 'menu') {
    return 'RETURN TO MAIN MENU';
  } else if (saveLinkAddress === 'cart') {
    return 'RETURN TO BAG';
  } else if (saveLinkAddress === 'checkout' && basket?.products?.length > 0) {
    return 'RETURN TO CHECKOUT';
  } else if (
    saveLinkAddress === 'checkout' &&
    (!basket || !basket.products || basket.products.length === 0)
  ) {
    return 'RETURN TO MAIN MENU';
  } else {
    return 'START ORDER';
  }
}

export const getGeoCode = async (args: google.maps.GeocoderRequest) => {
  const geoCodeResult = await getGeocode(args);
  return geoCodeResult.map((result) => ({
    ...result,
    location: result.geometry.location.toJSON(),
  }));
};

export const getAdressFromGeocode = async (
  args: google.maps.GeocoderRequest,
) => {
  const geoCodeResult = await getGeoCode(args);
  if (geoCodeResult.length) {
    const address = getAddress(geoCodeResult[0]);
    if (address.address1 !== '') {
      return { address, location: geoCodeResult[0].location };
    }
  }
  throw new Error('Please enter your full delivery address.');
};
